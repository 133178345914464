import React from 'react';

const QuestionToggleIcon = ({ height, width, cancelled = false }) => (
  <svg
    viewBox="0 0 64 80"
    xmlns="http://www.w3.org/2000/svg"
    width={width ?? '30'}
    height={height ?? '30'}
  >
    <path
      d="M 31.616 7.295 C 7.429 7.295 -7.688 33.478 4.406 54.425 C 16.499 75.371 46.733 75.371 58.827 54.425 C 61.585 49.648 63.036 44.23 63.036 38.715 C 63.014 21.371 48.96 7.317 31.616 7.295 Z M 31.616 66.635 C 10.123 66.635 -3.31 43.368 7.437 24.755 C 18.183 6.141 45.049 6.141 55.796 24.755 C 58.246 28.999 59.536 33.814 59.536 38.715 C 59.492 54.116 47.018 66.591 31.616 66.635 Z"
      style={{
        'stroke-width': '3px',
        fill: cancelled ? 'rgb(255, 0, 0)' : 'rgb(9, 119, 248)',
      }}
      transform="matrix(1, 0, 0, 1, 8.881784197001252e-16, 0)"
    />
    <path
      d="M 43.106 23.365 C 40.616 19.045 36.696 16.885 30.616 16.545 L 30.436 16.545 L 29.616 16.645 C 28.878 16.697 28.144 16.791 27.416 16.925 C 24.782 17.387 22.346 18.623 20.416 20.475 C 18.153 22.677 18.017 26.267 20.106 28.635 C 21.896 30.635 24.636 30.755 27.256 28.865 L 27.686 28.545 C 28.753 27.74 30.09 27.382 31.416 27.545 C 32.476 27.645 33.016 27.995 33.276 28.735 C 33.416 29.155 33.466 29.605 32.496 30.485 C 32.142 30.787 31.771 31.071 31.386 31.335 L 30.946 31.645 C 27.114 34.302 25.358 39.077 26.556 43.585 C 27.496 47.015 29.466 47.525 30.556 47.525 C 31.646 47.525 33.606 47.025 34.506 43.685 C 34.872 42.206 35.702 40.885 36.876 39.915 C 37.456 39.435 38.096 38.975 38.706 38.525 C 39.316 38.075 39.916 37.645 40.506 37.165 C 44.808 33.927 45.935 27.946 43.106 23.365 Z M 36.326 27.655 C 35.627 25.687 33.793 24.349 31.706 24.285 C 31.396 24.285 31.086 24.235 30.776 24.235 C 28.935 24.225 27.144 24.831 25.686 25.955 L 25.316 26.225 C 23.466 27.555 22.756 26.745 22.516 26.475 C 21.566 25.424 21.61 23.812 22.616 22.815 C 24.093 21.405 25.955 20.465 27.966 20.115 C 28.586 20.005 29.246 19.935 30.016 19.865 L 30.586 19.805 C 35.316 20.085 38.386 21.725 40.236 24.975 C 42.204 28.183 41.402 32.363 38.386 34.615 C 37.846 35.055 37.256 35.485 36.696 35.895 C 36.136 36.305 35.396 36.845 34.746 37.375 C 33.045 38.779 31.833 40.687 31.286 42.825 C 30.986 43.945 30.636 44.245 30.486 44.245 C 30.336 44.245 29.916 43.765 29.626 42.705 C 28.802 39.494 30.078 36.112 32.816 34.245 L 33.276 33.915 C 33.742 33.612 34.186 33.278 34.606 32.915 C 36.386 31.305 36.956 29.495 36.326 27.655 L 36.326 27.655 Z"
      style={{
        'stroke-width': '2px',
        fill: cancelled ? 'rgb(255, 0, 0)' : 'rgb(9, 119, 248)',
      }}
      transform="matrix(1, 0, 0, 1, 8.881784197001252e-16, 0)"
    />
    <path
      d="M 31.236 48.845 C 29.622 48.794 28.053 49.38 26.866 50.475 C 25.73 51.612 25.115 53.168 25.166 54.775 C 25.148 56.421 25.785 58.007 26.936 59.185 C 28.057 60.29 29.572 60.902 31.146 60.885 C 32.815 60.916 34.426 60.275 35.616 59.105 C 36.746 57.95 37.362 56.389 37.326 54.775 C 37.465 51.38 34.626 48.616 31.236 48.845 Z M 31.236 52.105 C 32.812 51.909 34.169 53.212 34.036 54.795 C 34.059 55.536 33.785 56.255 33.276 56.795 C 32.698 57.337 31.929 57.628 31.136 57.605 C 30.427 57.622 29.739 57.355 29.226 56.865 C 28.692 56.294 28.401 55.537 28.416 54.755 C 28.361 54.02 28.615 53.295 29.116 52.755 C 29.703 52.256 30.458 52.002 31.226 52.045 M 31.156 60.345 Z"
      style={{
        fill: cancelled ? 'rgb(255, 0, 0)' : 'rgb(9, 119, 248)',
      }}
      transform="matrix(1, 0, 0, 1, 8.881784197001252e-16, 0)"
    />
    <rect
      x="21.583"
      y="19.686"
      width="17.074"
      height="7.674"
      style={{
        fill: cancelled ? 'rgb(255, 0, 0)' : 'rgb(9, 119, 248)',
        stroke: 'rgba(0, 0, 0, 0)',
      }}
      transform="matrix(1, 0, 0, 1, 8.881784197001252e-16, 0)"
    />
    <rect
      x="30.491"
      y="20.213"
      width="22.771"
      height="6.427"
      style={{
        'transform-origin': '41.8765px 23.4265px',
        fill: cancelled ? 'rgb(255, 0, 0)' : 'rgb(9, 119, 248)',
        stroke: 'rgba(0, 0, 0, 0)',
      }}
      transform="matrix(-0.507351100445, 0.861739993095, -0.863496124744, -0.504367053509, -6.363696719097, 10.938356495787)"
    />
    <ellipse
      style={{
        'transform-origin': '41.8765px 23.4265px',
        fill: cancelled ? 'rgb(255, 0, 0)' : 'rgb(9, 119, 248)',
        stroke: cancelled ? 'rgb(255, 0, 0)' : 'rgb(9, 119, 248)',
      }}
      cx="31.271"
      cy="54.457"
      rx="3.261"
      ry="3.406"
      transform="matrix(1, 0, 0, 1, 8.881784197001252e-16, 0)"
    />
    {/* <rect
      x="12.627"
      y="15.289"
      width="7.831"
      height="65.464"
      style={{
        'transform-origin': '16.5425px 48.021px',
        fill: 'rgb(255, 0, 0)',
      }}
      transform="matrix(0.711506962776, 0.702678799629, -0.702678799629, 0.711506962776, 15.460750117186, -9.06806119999)"
    /> */}
  </svg>
);

export default QuestionToggleIcon;

import { AnswerTypeEnum } from '../../constants/enums';
import { generateHours } from '../../constants/hours';
import useCreateScreening from '../../hooks/useCreateScreening';
import { t } from '../../i18n/i18n';
import AddQuestions from '../AddQuestions/AddQuestions';
import DropdownSelect from '../DropdownSelect/DropdownSelect';
import TestGroupsItem from '../TestGroupsItem/TestGroupsItem';
import { getMinimunScreeningTimeInSeconds } from '../../utils/time';

const CreateEvaluationThirdStep = () => {
  const {
    newScreening,
    incompleteQuestion,
    setEditData,
    handleOrder,
    handleDelete,
    handleCustomScreeningsTime,
  } = useCreateScreening();

  const minCustomScreeningsTimeInSeconds = getMinimunScreeningTimeInSeconds(newScreening);

  const minCustomScreeningsTimeInHours = minCustomScreeningsTimeInSeconds
    ? minCustomScreeningsTimeInSeconds / 3600
    : 0;

  const hours = generateHours(5, 2.05, minCustomScreeningsTimeInHours ?? null);

  return (
    <div data-testid="create-evaluation-third-step" className="d-flex flex-column">
      <span
        className="font-weight-600 font-montserrat create-evaluation-step-title text-light-black mb-3"
        data-testid="create-evaluation-ts-title-span"
      >
        {t('CREATE_EVALUATION_THIRD_STEP_TITLE')}
      </span>
      <div>
        <span
          className="font-montserrat font-weight-600 text-base text-light-black"
          data-testid="create-evaluation-ts-custom-test-title-span"
        >
          {t('CREATE_EVALUATION_THIRD_STEP_CUSTOM_TEST_TITLE')}
        </span>
        <span
          className="font-montserrat font-weight-400 font-italic text-xs text-muted ml-2"
          data-testid="create-evaluation-ts-custom-comment-span"
        >
          {t('CREATE_EVALUATION_THIRD_STEP_CUSTOM_TEST_TITLE_COMMENT')}
        </span>
      </div>
      <span
        className="text-light-black font-weight-400 text-base my-2 font-montserrat"
        data-testid="create-evaluation-ts-custom-test-subtitle-span"
      >
        {t('CREATE_EVALUATION_THIRD_STEP_CUSTOM_TEST_SUBTITLE')}
      </span>
      <div className="d-flex justify-content-center my-2">
        <AddQuestions incompleteQuestion={incompleteQuestion} />
      </div>
      {Boolean(newScreening?.customTestGroup?.length) && (
        <div className="my-2 d-flex align-items-center">
          <div className="my-1 my-sm-0 mr-0 mr-sm-2 pr-md-5 mr-md-5">
            <span
              className="font-montserrat font-weight-600 text-muted font-italic text-base"
              data-testid="create-evaluation-ts-your-questions-count-span"
            >
              {t('CREATE_SCREENING_YOUR_QUESTIONS_ADDED_QUESTIONS')}
            </span>
            <span
              className="total-number-text font-italic font-weight-bold font-montserrat text-sm"
              data-testid="create-evaluation-ts-your-questions-count-number-span"
            >
              {newScreening?.customTestGroup?.length}
            </span>
          </div>
        </div>
      )}
      <div className="mt-2">
        {Boolean(newScreening.customTestGroup?.length) &&
          newScreening.customTestGroup
            .sort((a, b) => a.order - b.order)
            .map((item, index) => (
              <TestGroupsItem
                test={item}
                key={item.id}
                disableOrderUp={index === 0}
                handleEdit={() => setEditData(item)}
                disableOrderDown={index === newScreening.customTestGroup.length - 1}
                handleOrder={handleOrder}
                handleDelete={() => handleDelete(item.deleteId)}
                isComplementaryQuestion
              />
            ))}
      </div>
      {Boolean(newScreening.customTestGroup?.length) && (
        <div className="my-1 my-sm-0 ml-0 d-flex justify-content-end align-items-center">
          <span
            className="font-montserrat text-light-black font-weight-500 text-base"
            data-testid="create-evaluation-ts-set-time-span"
          >
            {t('CREATE_EVALUATION_THIRD_STEP_CUSTOM_SET_TIME')}
          </span>
          <DropdownSelect
            options={hours}
            selected={newScreening?.customScreeningTGDuration ?? '00:00'}
            selectedPlaceholder
            setState={handleCustomScreeningsTime}
            className="time-picker-button time-picker-button-small ml-3"
            placeholderClassname="ml-4"
          />
        </div>
      )}
    </div>
  );
};

export default CreateEvaluationThirdStep;

import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Progress, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { t } from '../../i18n/i18n';
import {
  getScreeningByInstanceId,
  getSubmissionAnswers,
  getTestGroups,
  getTestGroupsDetailsBatch,
} from '../../API/services/screeningService';
import { setInvitedProfileTestGroups } from '../../redux/actions/profilesActions';
import { oneDecimal } from '../../utils/numbersUtils';
import { setSubmissionSelected } from '../../redux/actions/screeningActions';
import InvitedProfileComments from '../InvitedProfileComments/InvitedProfileComments';
import Collapsed from '../Collapsed/Collapsed';
import LanguageCollapsed from './LanguageCollapsed/LanguageCollapsed';
import PortfolioCollapsed from './PortfolioCollapsed/PortfolioCollapsed';
import FormationCollapsed from './FormationCollapsed/FormationCollapsed';
import './InvitedProfileStages.scss';
import EmptyComponent from '../EmptyComponent/EmptyComponent';
import { TrackEvent } from '../../utils/filters/segmentUtils';
import {
  getCandidatePdfExport,
  getCandidateQualitativePdfExport,
  getProfileSkills,
} from '../../API/services/alkymersService';
import HorizontalLoader from '../HorizontalLoader/HorizontalLoader';
import InvitedProfileCommunications from '../InvitedProfileCommunications/InvitedProfileCommunications';
import CloudDownload from '../../assets/dashboard/CloudDownload';
import { isNullOrUndefined } from '../../utils/typesUtils';
import { TestGroupTypes } from '../../constants/enums';
import QualitativeCollapsed from './QualitativeCollapsed/QualitativeCollapsed';
import Spinner from '../spinner/Spinner';
import { capitalizeString } from '../../utils/stringUtils';
import ApplicationMethod from './ApplicationMethod/ApplicationMethod';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const InvitedProfileStages = ({
  stages,
  setShow,
  profile,
  isInvitedProfile,
  score,
  progressTechs,
  setGroupAcceleration,
}) => {
  const [selectedStage] = useState(0);
  const [collapsedSelected, setCollapsedSelected] = useState([]);
  const [loadingDetail, setLoadingDetail] = useState(undefined);
  const [loadingTestGroups, setLoadingTestGroups] = useState(isInvitedProfile);
  const { partner } = useSelector((state) => state.auth.user);
  const [screeningInstanceId, setScreeningInstanceId] = useState('');
  const [groupsProportion, setGroupsProportion] = useState({});
  const { invitedProfileTestGroups: testGroups, invitedProfile } = useSelector(
    (state) => state.profiles
  );
  const dispatch = useDispatch();
  const screeningName = invitedProfile
    ? invitedProfile?.stages[0]?.invitation?.screeningName
    : undefined;
  const invitationData = isInvitedProfile ? stages[selectedStage]?.invitation : null;
  const visibleTechnologies = profile?.technologies?.filter((tech) => tech?.technology?.visible);
  const [qualitativeResultType, setQualitativeResultType] = useState('');

  if (isInvitedProfile) {
    useEffect(async () => {
      setLoadingTestGroups(true);
      const testGroups = await getTestGroups(
        stages.map((stage) => stage.invitation.screeningInstanceId).filter((id) => Boolean(id))
      );
      if (testGroups.length) {
        if (testGroups[0].testGroups.some((tg) => tg.type === TestGroupTypes.QUALITATIVE)) {
          const qualitativeTestGroupIds = testGroups[0].testGroups
            .filter((tg) => tg.type === TestGroupTypes.QUALITATIVE)
            .map((tg) => tg.id);

          const qualitativeTestGroupsData = await getTestGroupsDetailsBatch(
            qualitativeTestGroupIds
          );
          qualitativeTestGroupsData.forEach((test) => {
            const instanceTestGroups = testGroups.find(
              (tg) => tg.id === stages[selectedStage]?.invitation?.screeningInstanceId
            ).testGroups;
            const testGroup = instanceTestGroups.find((tg) => tg.id === test.id);
            testGroup.completeData = test;
          });
        }

        const formated = testGroups?.reduce(
          (object, instance) => ({ ...object, [instance.id]: instance.testGroups }),
          {}
        );

        const proportions = {};

        Object.entries(formated).forEach(([id, testGroup]) => {
          const totalWeight = testGroup.reduce((sum, test) => sum + test.weight, 0);

          proportions[id] = 100 / totalWeight;
        });
        setLoadingTestGroups(false);
        setGroupsProportion(proportions);
        dispatch(setInvitedProfileTestGroups(formated));
      }

      return () => setInvitedProfileTestGroups({});
    }, []);
  }
  if (isInvitedProfile) {
    useEffect(() => {
      setScreeningInstanceId(stages[selectedStage]?.invitation?.screeningInstanceId);
    }, [selectedStage]);
  }

  useEffect(() => {
    if (
      isInvitedProfile &&
      testGroups[screeningInstanceId]?.filter(
        (testgroup) => testgroup.type === TestGroupTypes.QUALITATIVE
      ).length
    ) {
      setCollapsedSelected([...collapsedSelected, 0, 5]);
    } else {
      setCollapsedSelected([...collapsedSelected, 4]);
    }
  }, [testGroups]);

  const getDataSubmission = async (screeningInstanceId, testGroup, index) => {
    setLoadingDetail(index);
    await getSubmissionAnswers(screeningInstanceId, testGroup?.id).then((res) => {
      dispatch(setSubmissionSelected({ testGroup, submissions: res }));
      setLoadingDetail(undefined);
      setShow(true);
    });
  };

  const optionsCollapsed = [
    {
      title: t('PROFILE_QUALITITAVE_RESULTS'),
      content: (
        <>
          {testGroups[screeningInstanceId]?.some(
            (testgroup) => testgroup.type === TestGroupTypes.QUALITATIVE
          ) ? (
            <QualitativeCollapsed
              testgroups={testGroups[screeningInstanceId]?.filter(
                (testgroup) => testgroup.type === TestGroupTypes.QUALITATIVE
              )}
              recruitSessionId={stages[0]?.recruitSessionStage?.recruitSessionId}
              profile={profile}
              qualitativeResultType={qualitativeResultType}
              setQualitativeResultType={setQualitativeResultType}
            />
          ) : (
            <EmptyComponent
              title={t('INVITED_PROFILE_EMPTY_TITLE_SECTION')}
              className="my-3 align-items-center py-3"
            />
          )}
        </>
      ),
      isVisible: testGroups[screeningInstanceId]?.some(
        (testgroup) => testgroup.type === TestGroupTypes.QUALITATIVE
      ),
    },
    {
      title: t('SEARCH_FORMATION_TITLE'),
      content: (
        <>
          {profile?.formations?.length ? (
            profile?.formations?.map((formation, index) => (
              <FormationCollapsed formation={formation} index={index} />
            ))
          ) : (
            <EmptyComponent
              title={t('INVITED_PROFILE_EMPTY_TITLE_SECTION')}
              className="my-3 align-items-center py-3"
            />
          )}
        </>
      ),
      isVisible: true,
    },
    {
      title: t('SEARCH_LANGUAGE_TITLE'),
      content: (
        <>
          {profile?.languages?.length ? (
            profile?.languages?.map((language, index) => (
              <LanguageCollapsed language={language} key={index} />
            ))
          ) : (
            <EmptyComponent
              title={t('INVITED_PROFILE_EMPTY_TITLE_SECTION')}
              className="my-3 align-items-center py-3"
            />
          )}
        </>
      ),
      isVisible: true,
    },
    {
      title: `${t('SEARCH_PROJECT_OF')} ${capitalizeString(profile?.firstName)}`,
      content: (
        <div className="d-flex flex-row flex-wrap my-1 portfolio-gap-custom">
          {profile?.portfolios?.length &&
          profile?.portfolios?.filter((item) => item.url.length).length ? (
            profile?.portfolios
              ?.filter((item) => item.url.length)
              .map((portfolio, index) => <PortfolioCollapsed portfolio={portfolio} key={index} />)
          ) : (
            <EmptyComponent
              title={t('INVITED_PROFILE_EMPTY_TITLE_SECTION')}
              className="my-3 align-items-center py-3"
            />
          )}
        </div>
      ),
      isVisible: true,
    },
    {
      title: t('SEARCH_COMMUNICATION_HISTORY_TITLE'),
      content: <InvitedProfileCommunications isInvitedProfile={isInvitedProfile} />,
      isVisible: isInvitedProfile,
    },
    {
      title: t('PROFILE_METHOD_APPLIED'),
      content: (
        <>
          <ApplicationMethod recruitSessionStage={invitationData} />
        </>
      ),
      isVisible: true,
    },
  ];

  const setProfileNotInvited = async (progressTech, index) => {
    setGroupAcceleration({});
    dispatch(setSubmissionSelected({}));
    setLoadingDetail(index);
    if (!progressTech?.screeningInstanceId) {
      await getProfileSkills(progressTech?.id).then((res) => {
        setGroupAcceleration({ ...progressTech, skills: res.skills });
        setLoadingDetail(undefined);
        setShow(true);
      });
    } else {
      await getScreeningByInstanceId(progressTech?.screeningInstanceId).then((res) => {
        getDataSubmission(progressTech?.screeningInstanceId, res.testGroups[0], index);
      });
    }
  };

  const [reportLoading, setReportLoading] = useState(false);

  const generateUserReport = async () => {
    const newVersionOfQualitative =
      screeningInstanceId &&
      testGroups[screeningInstanceId][0]?.completeData?.qualitativeConfigResults;
    setReportLoading(true);
    getCandidatePdfExport(
      stages[0]?.recruitSessionStage?.recruitSessionId,
      encodeURI(profile?.id)
    ).then((res) => {
      const blob = window.URL.createObjectURL(res);
      const fileLink = document.createElement('a');
      fileLink.href = blob;
      fileLink.setAttribute(
        'download',
        `${profile.firstName.toLowerCase()}-${profile.lastName.toLowerCase()}.pdf`
      );
      fileLink.setAttribute('target', '_blank');
      document.body.appendChild(fileLink);
      fileLink.click();
      !newVersionOfQualitative && setReportLoading(false);
    });
    if (newVersionOfQualitative) {
      getCandidateQualitativePdfExport(
        stages[0]?.recruitSessionStage?.recruitSessionId,
        encodeURI(profile?.id),
        qualitativeResultType
      ).then((res) => {
        const blob = window.URL.createObjectURL(res);
        const fileLink = document.createElement('a');
        fileLink.href = blob;
        fileLink.setAttribute(
          'download',
          `${profile.firstName.toLowerCase()}-${profile.lastName.toLowerCase()}-qualitative.pdf`
        );
        fileLink.setAttribute('target', '_blank');
        document.body.appendChild(fileLink);
        fileLink.click();
        setReportLoading(false);
      });
    }
  };
  return (
    <div
      data-testid="invited-profile-stages-container"
      className="px-4 pb-4 pt-0 invited-profile-stages-card flex-grow-1 h-100"
    >
      <div
        className={`card-profile description-container position-relative h-100 bg-white b-radius-8 ${
          progressTechs?.length === 0 && 'd-none'
        }`}
      >
        <div className="d-flex flex-column invited-profile-stages-container p-4">
          <div className="d-flex justify-content-between">
            <span className="font-montserrat font-weight-600 text-md">
              {isInvitedProfile
                ? t('PROFILE_QUANTITAVE_RESULTS')
                : t('MARKETPLACE_PROFILE_RESULTS_TITLE')}
            </span>
            {isInvitedProfile ? (
              <>
                {invitationData && isNullOrUndefined(invitationData.score) ? (
                  <button
                    type="button"
                    className="cursor-not-allowed report-btn-disabled-text px-3 py-1 text-xs cursor-pointer rounded-pill d-flex align-items-center font-weight-700 bg-white report-btn-disabled-button"
                  >
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip>{t('REPORT_DISABLED_EVALUATION_NOT_COMPLETED_YET')}</Tooltip>
                      }
                    >
                      <span className="report-btn-disabled-text mr-2 font-montserrat">
                        {t('CANDIDATE_DOWNLOAD_REPORT')}
                      </span>
                      <CloudDownload fill="#959595" />
                    </OverlayTrigger>
                  </button>
                ) : (
                  <button
                    onClick={generateUserReport}
                    type="button"
                    className="text-maya-blue px-3 py-1 text-xs border-maya-blue cursor-pointer rounded-pill d-flex align-items-center font-weight-700  bg-white border-button"
                  >
                    {reportLoading ? (
                      <HorizontalLoader className="loader-report" />
                    ) : (
                      <>
                        <span className="text-maya-blue mr-2 font-montserrat">
                          {t('CANDIDATE_DOWNLOAD_REPORT')}
                        </span>
                        <CloudDownload fill="#58C1F5" />
                      </>
                    )}
                  </button>
                )}
              </>
            ) : null}
          </div>
          {!isInvitedProfile || invitationData?.score || invitationData?.score === 0 ? (
            <div className="d-flex mt-3 px-3 flex-column flex-sm-row">
              {testGroups[screeningInstanceId]?.some(
                (testgroup) => testgroup.type === TestGroupTypes.QUANTITATIVE
              ) && (
                <div className="pr-sm-5 text-center d-flex flex-column">
                  <Progress
                    type="circle"
                    strokeWidth={4}
                    strokeColor={{
                      '30%': '#58C1F5',
                      '100%': '#9BF558',
                    }}
                    className="mb-3 mb-lg-0"
                    width={140}
                    percent={
                      isInvitedProfile
                        ? stages[selectedStage]?.invitation?.score ?? 0
                        : Math.round(
                            progressTechs?.find((technology) => technology?.isPrimary)?.bestScore
                          )
                    }
                    format={() => (
                      <div className="d-flex flex-column">
                        <span className="font-weight-bold font-montserrat mb-2 invited-profile-stage-percent">
                          {isInvitedProfile ? (
                            `${
                              Number.isNaN(oneDecimal(stages[selectedStage]?.invitation?.score))
                                ? '-'
                                : `${oneDecimal(stages[selectedStage]?.invitation?.score)}%`
                            }`
                          ) : Number.isNaN(oneDecimal(Math.round(score?.totalEngagement))) ? (
                            <HorizontalLoader className="loader-classname" />
                          ) : (
                            `${oneDecimal(Math.round(score?.totalEngagement))}
                              %`
                          )}
                        </span>
                      </div>
                    )}
                  />
                  {process.env.RAZZLE_RUNTIME_IS_PROCTORING_ENABLED === 'true' &&
                    isInvitedProfile &&
                    stages[0]?.recruitSessionStage?.proctoringEnabled &&
                    screeningInstanceId !== undefined &&
                    stages[selectedStage]?.invitation?.endDate && (
                      <div className="d-flex flex-column mt-3">
                        {Boolean(stages[selectedStage]?.invitation?.totalEvents) && (
                          <span className="text-nowrap font-montserrat text-black text-xs font-weight-600 mb-1">
                            {t('ASSESSMENT_INVITATION_TOTAL_EVENTS').replace(
                              '{totalEvents}',
                              stages[selectedStage]?.invitation?.totalEvents
                            )}
                          </span>
                        )}
                        <Link
                          to={`/search/${stages[0]?.recruitSessionStage?.recruitSessionId}/profiles/${profile?.id}/proctoringDetail`}
                          className="font-montserrat text-purple text-xs text-nowrap font-weight-600"
                        >
                          {t('VIEW_PROCTORING_ANALYSIS')}
                        </Link>
                      </div>
                    )}

                  <span className="font-montserrat font-weight-600 text-sm mt-2">
                    {
                      progressTechs?.find((technology) => technology?.isPrimary)?.technology
                        ?.displayName
                    }
                  </span>
                </div>
              )}
              <div className="section-container">
                <div className="d-flex flex-column">
                  {isInvitedProfile ? (
                    <div className="d-flex flex-column justify-content-between mb-2">
                      <div className="d-flex flex-column">
                        <span
                          data-testid="invited-profile-stages-screening-name"
                          className="font-montserrat text-sm font-weight-bold text-capitalize"
                        >
                          {screeningName}
                        </span>
                      </div>
                      {loadingTestGroups ? (
                        <Spinner show />
                      ) : testGroups[screeningInstanceId]?.some(
                          (testgroup) => testgroup.type === TestGroupTypes.QUANTITATIVE
                        ) ? (
                        <div data-testid="quantitative-tests-container">
                          {testGroups[screeningInstanceId]
                            ?.filter((testgroup) => testgroup.type === TestGroupTypes.QUANTITATIVE)
                            ?.map((testGroup, index) => (
                              <>
                                <div className="d-flex flex-column mt-3">
                                  <div className="d-flex flex-row align-items-center bg-white">
                                    <span className="font-montserrat text-xs font-weight-bold text-capitalize container-name-mw">
                                      {testGroup.name}
                                    </span>
                                    <button
                                      className="border-0 bg-transparent cursor-pointer text-decoration-none d-flex button-detail-mw"
                                      onClick={() =>
                                        getDataSubmission(screeningInstanceId, testGroup, index)
                                      }
                                      type="button"
                                    >
                                      {!(loadingDetail === index) ? (
                                        <span className="font-montserrat font-weight-600 span-see-detail-modal text-xs ml-2">
                                          {t('DEV_PROFILE_TESTGROUP_VIEW_DETAIL')}
                                        </span>
                                      ) : (
                                        <Spin
                                          indicator={
                                            <LoadingOutlined
                                              style={{
                                                fontSize: 18,
                                              }}
                                              spin
                                            />
                                          }
                                          className="ml-2"
                                        />
                                      )}
                                    </button>
                                  </div>
                                  <Progress
                                    strokeWidth={8}
                                    className="invited-profile-stages-progress"
                                    strokeColor={{
                                      '30%': '#58C1F5',
                                      '100%': '#9BF558',
                                    }}
                                    percent={testGroup.score ?? 0}
                                    format={() => (
                                      <span className="font-montserrat font-weight-bold invited-profile-skill-title pl-4 text-xs points-text">
                                        {isInvitedProfile
                                          ? `pts ${Math.round(
                                              (testGroup.score ?? 0) /
                                                (100 /
                                                  (testGroup.weight *
                                                    groupsProportion[screeningInstanceId] ?? 0))
                                            )}/${Math.round(
                                              testGroup.weight *
                                                groupsProportion[screeningInstanceId] ?? 0
                                            )}`
                                          : ''}
                                      </span>
                                    )}
                                  />
                                </div>
                              </>
                            ))}
                        </div>
                      ) : (
                        <span className="font-montserrat text-sm font-weight-400 mt-2">
                          {t('QUANTITATIVE_TEST_EMPTY_STATE')}
                        </span>
                      )}
                    </div>
                  ) : (
                    <div className="d-flex flex-column justify-content-between mb-2">
                      <div className="">
                        {progressTechs?.map((progressTech, index) => (
                          <>
                            <div className="d-flex flex-column mt-3">
                              <div className="invited-profile-stages-progress d-flex flex-row align-items-center justify-content-between">
                                <span className="font-montserrat text-xs font-weight-bold">
                                  {progressTech?.technology?.displayName}
                                </span>
                                <div className="d-flex align-items-center justify-content-center">
                                  <button
                                    className="border-0 bg-transparent cursor-pointer text-decoration-none d-flex"
                                    onClick={() => {
                                      setProfileNotInvited(progressTech, index);
                                      TrackEvent('Profile-details-challenge-details', {
                                        profile,
                                        partner,
                                      });
                                    }}
                                    type="button"
                                  >
                                    {loadingDetail !== index ? (
                                      <span className="font-montserrat font-weight-600 span-see-detail-modal text-xs ml-2">
                                        {t('DEV_PROFILE_TESTGROUP_VIEW_DETAIL')}
                                      </span>
                                    ) : (
                                      <Spin
                                        indicator={
                                          <LoadingOutlined
                                            style={{
                                              fontSize: 18,
                                            }}
                                            spin
                                          />
                                        }
                                        className="ml-2"
                                      />
                                    )}
                                  </button>
                                </div>
                              </div>
                              <Progress
                                strokeWidth={8}
                                className="invited-profile-stages-progress"
                                format={() => {}}
                                strokeColor={{
                                  '30%': '#58C1F5',
                                  '100%': '#9BF558',
                                }}
                                percent={Math.round(progressTech?.bestScore)}
                              />
                            </div>
                          </>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="d-flex mt-3 p-3 flex-column flex-sm-row">
              <div className="pr-sm-3 text-center d-flex flex-column mb-3">
                <div className="rounded-circle circle-no-data d-flex flex-column justify-content-center">
                  <p className="font-montserrat text-base font-weight-400 text-center mb-0">
                    {t('CANDIDATE_DETAIL_NO_DATA')}
                  </p>
                </div>
              </div>
              <div className="section-container">
                <div className="d-flex flex-column">
                  <span className="font-montserrat text-sm font-weight-bold text-capitalize">
                    {screeningName}
                  </span>
                  <p className="font-montserrat text-sm mt-3">
                    {t('DASHBOARD_SEARCH_HEADERS_SCREENINGS')}
                    {': '}
                    {invitationData?.endDate || !isNullOrUndefined(invitationData?.score)
                      ? t('SCREENINGS_INVITATIONS_PROCESSING')
                      : t(`SCREENINGS_INVITATIONS_${invitationData?.status}`)}
                  </p>
                  <p className="font-montserrat text-sm">
                    {t('CANDIDATE_DETAIL_EMPTY_STATE_TEXT')}
                  </p>
                </div>
              </div>
            </div>
          )}
          {!isInvitedProfile && (
            <>
              {Boolean(visibleTechnologies?.length) && (
                <div className="mt-5 d-flex">
                  <span className="font-montserrat font-weight-600 text-sm technologies-text">
                    {t('PROFILE_TECHNOLOGIES_USER_KNOWS')}
                    <span className="font-montserrat font-weight-500 text-sm mx-1 mb-1 technology-text-value">
                      {visibleTechnologies
                        ?.filter((tech) => tech.technologyType === 'KNOW')
                        ?.map(
                          (tech, i) =>
                            ` ${tech?.technology?.displayName}${
                              i ===
                              visibleTechnologies.filter(
                                (visibleTech) => visibleTech.technologyType === 'KNOW'
                              )?.length -
                                1
                                ? ''
                                : ','
                            } `
                        )}
                    </span>
                  </span>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {optionsCollapsed
        ?.filter((optionsCollapsed) => optionsCollapsed.isVisible)
        .map((options, index) => (
          <Collapsed
            collapsedSelected={collapsedSelected}
            setCollapsedSelected={setCollapsedSelected}
            index={index}
            options={options}
          />
        ))}
      <InvitedProfileComments
        isInvitedProfile={isInvitedProfile}
        className="pt-0 mt-3 flex-grow-1"
      />
    </div>
  );
};

export default InvitedProfileStages;

import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useMissingData } from '../../hooks/useMissingData';
import Progress from '../Progress/Progress';
import { t } from '../../i18n/i18n';
import emptyUserPhoto from '../../assets/header/profile.svg';
import happyFaceSvg from '../../assets/homeProfile/faces/happyFace.svg';
import mediumFaceSvg from '../../assets/homeProfile/faces/mediumFace.svg';
import badFaceSvg from '../../assets/homeProfile/faces/badFace.svg';
import noCreditsFaceSvg from '../../assets/homeProfile/faces/noCreditsFace.svg';
import clockSvg from '../../assets/homeProfile/faces/clock.svg';
import { PLANS_CONSUMPTIONS } from '../../constants/routes';
import './HomeProfile.scss';

const HomeProfile = ({ workspacePage, isCredits = false }) => {
  const history = useHistory();
  const percentage = useMissingData();

  const { user, plan } = useSelector((state) => state.auth);

  const percentageUsedPlan = Object.values(plan).length
    ? plan?.screeningsUsed > 0
      ? (plan?.screeningsUsed / plan?.screeningsAmount) * 100
      : 0
    : 100;

  const nextRenewal = moment(plan?.subscription?.nextRenewal);
  const subscriptionDuration = plan?.subscription?.renewalFrequency; // Duration in days of the subscription
  const daysLeft = moment(nextRenewal).diff(new Date(), 'days'); // Days left of de subscription
  const percentDaysLeft = Math.round((daysLeft * 100) / subscriptionDuration); // Percent of days used

  const calculateImg = (percentage) => {
    if (percentage >= 86) return badFaceSvg;
    if (percentage >= 61 && percentage <= 85) return mediumFaceSvg;
    if (percentage <= 60 && percentage >= 0) return happyFaceSvg;
    return noCreditsFaceSvg;
  };

  const calculatePlanDaysLeft = (daysLeft, percentDaysLeft, planUsage) => {
    let content = '';

    if (planUsage < 100 && planUsage > 0) {
      if (percentDaysLeft > 20) {
        content = (
          <>
            {t('HOME_PROFILE_YOU_HAVE_LEFT')}
            <b> {daysLeft} </b>
            {t('HOME_PROFILE_DAYS_FOR_USED')}
          </>
        );
      } else {
        content = (
          <>
            <img src={clockSvg} alt="clock-svg" width="21.71" height="24" className="mr-2" />¡
            {t('HOME_PROFILE_YOU_HAVE_LEFT')}
            <b> {daysLeft} </b>
            {t('HOME_PROFILE_DAYS_FOR_USED')}!
          </>
        );
      }
    } else {
      content = (
        <>
          {plan?.subscription?.subscriptionPending?.isRenewable === true && (
            <>
              {t('HOME_PROFILE_THE')}&nbsp;
              <b>
                {moment(plan?.subscription?.subscriptionPending?.startedDate).format('D')}{' '}
                {t('HOME_PROFILE_OF')}&nbsp;
                {t(
                  `SUBSCRIPTION_MONTH_${moment(
                    plan?.subscription?.subscriptionPending?.startedDate
                  ).format('M')}`
                )}
              </b>
              &nbsp;
              {plan?.subscription?.subscriptionPending?.planId === 1
                ? t('HOME_PROFILE_PLAN_RENEWAL_FREE')
                : t('HOME_PROFILE_PLAN_RENEWAL_PAY').replace(
                    'x',
                    plan?.subscription?.subscriptionPending?.screeningsAmount
                  )}
            </>
          )}
        </>
      );
    }

    return content;
  };

  const ScreeningsAmountLeftText = ({ plan }) => {
    const { screeningsAmount, screeningsUsed, assessmentInvitationPendingCredits } = plan;
    const screeningsRemaining = screeningsAmount - screeningsUsed;
    return (
      <>
        {assessmentInvitationPendingCredits === 0 || screeningsRemaining > 0 ? (
          <>
            {`${t('HOME_PROFILE_YOU_HAVE_LEFT')} `}
            <b>{screeningsRemaining}</b>
            {` ${t('FEATURE_SCREENINGS')}`}
          </>
        ) : (
          <>
            {`${t('HOME_PROFILE_ASSESSMENT_INVITATION_PENDING_CREDITS')} `}
            <b>{assessmentInvitationPendingCredits}</b>
            {` ${t('FEATURE_SCREENINGS_PENDING_CREDITS')}`}
            <a
              className="font-montserrat text-xs font-weight-500 text-link"
              href={process.env.RAZZLE_RUNTIME_SALES_CALENDAR_URL}
            >
              link
            </a>
          </>
        )}
      </>
    );
  };

  return (
    <div
      data-testid="home-profile"
      className={`bg-white ${workspacePage ? 'b-radius-8' : 'home-profile'} mx-auto`}
    >
      <div
        className={`d-flex flex-row ${
          workspacePage ? 'workspace-profile-content' : 'home-profile-content'
        }`}
      >
        <div className="d-flex align-items-center">
          <img
            data-testid="profile-img"
            className={`${workspacePage ? 'size-profile-img' : 'home-profile-img'}`}
            src={
              isCredits
                ? calculateImg(percentageUsedPlan)
                : user?.picture
                ? user?.picture
                : emptyUserPhoto
            }
            alt={t('PROFILE_PICTURE_OF_PROFILE')}
          />
        </div>
        <div className={`home-profile-texts ${workspacePage ? 'container-flex-profile p-0' : ''}`}>
          <span
            className={`font-montserrat font-weight-700 text-xl ${
              workspacePage ? 'position-profile-name' : ''
            } ${isCredits ? 'mt-3' : ''}`}
          >
            {isCredits ? (
              `${t('HOME_PROFILE_PLAN')} ${plan?.planName}`
            ) : (
              <>
                ¡{t('HOME_HELLO')}, {user.firstName}!
              </>
            )}
          </span>
          <div className={`${workspacePage ? 'position-progress-bar' : ''}`}>
            <Progress
              percent={isCredits ? percentageUsedPlan : percentage}
              className={`home-progress ${isCredits ? 'custom-margin-home-progress' : ''}`}
              isCredits={isCredits}
            />
            {isCredits && (
              <span
                className={`font-montserrat text-xs font-weight-500 ${
                  daysLeft > 0 && percentDaysLeft <= 20
                    ? 'completed-percentage-text-red'
                    : 'completed-percentage-text'
                }`}
              >
                <ScreeningsAmountLeftText plan={plan} />
                <br />
              </span>
            )}
            <span
              className={`font-montserrat text-xs font-weight-500 ${
                isCredits && daysLeft > 0 && percentDaysLeft <= 20
                  ? 'completed-percentage-text-red'
                  : 'completed-percentage-text'
              }`}
            >
              {isCredits
                ? calculatePlanDaysLeft(daysLeft, percentDaysLeft, 100 - percentageUsedPlan)
                : percentage < 100
                ? `${t('YOUR_PROFILE_IS_AT_PERCENT')} ${percentage}%`
                : t('HOME_YOUR_PROFILE_IS_COMPLETED')}
            </span>
          </div>
        </div>
      </div>
      <div className="d-flex home-profile-content justify-content-end">
        <button
          type="button"
          data-testid="redirect-profile-button"
          onClick={() => (isCredits ? history.push(PLANS_CONSUMPTIONS) : history.push('/profile'))}
          className={`font-weight-700 font-montserrat text-xs font-bold complete-profile-button bg-white mr-0 ${
            isCredits && percentageUsedPlan === 100
              ? 'font-weight-bold border-0 outline-none bg-maya-blue text-white'
              : ''
          } ${workspacePage ? 'position-profile-button' : ''}`}
        >
          {isCredits
            ? percentageUsedPlan > 0
              ? t('HOME_PROFILE_SEE_PLANS')
              : t('HOME_PROFILE_GET_MORE_PLAN')
            : percentage !== 100
            ? t('HOME_COMPLETE_YOUR_PROFILE')
            : t('HOME_EDIT_YOUR_PROFILE')}
        </button>
        {isCredits && (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip className="font-montserrat">{t('RECOMMEND_WIN_DESCRIPTION')}</Tooltip>
            }
          >
            <button
              type="button"
              data-testid="redirect-profile-button-recommended"
              onClick={() =>
                window.open(process.env.RAZZLE_RUNTIME_MARKETING_LANDING_RECOMMEND, '_blank')
              }
              className="font-weight-700 font-montserrat text-xs font-bold complete-profile-button-recommended ml-2 mr-0 font-weight-bold border-0 outline-none"
            >
              {t('RECOMMEND_WIN')}
            </button>
          </OverlayTrigger>
        )}
      </div>
    </div>
  );
};

export default HomeProfile;

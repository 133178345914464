import React, { useState } from 'react';
import useTranslations from '../../hooks/useTranslations';
import { getCategoryNameTranslate } from '../../utils/translate';

export const CategoryBadge = ({ label, categories = [] }) => {
  const [hover, setHover] = useState(false);
  const { key } = useTranslations();
  return (
    <div
      onFocus={() => setHover(true)}
      onMouseOver={() => setHover(true)}
      onBlur={() => setHover(false)}
      onMouseOut={() => setHover(false)}
      onClick={() => setHover(!hover)}
      style={{ cursor: 'pointer' }}
    >
      <div
        style={{
          backgroundColor: 'rgb(244, 176, 67)',
          borderRadius: '50%',
          width: '25px',
          height: '25px',
          textAlign: 'center',
          fontWeight: 'bold',
          color: 'white',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <span>{label}</span>
      </div>
      {hover && (
        <div
          style={{
            backgroundColor: 'rgb(244, 176, 67)',
            position: 'absolute',
            display: 'flex',
            fontWeight: 'bold',
            flexDirection: 'column',
            top: 20,
            left: 20,
            borderRadius: '10px',
            borderTopLeftRadius: 0,
            padding: '20px',
          }}
        >
          {categories?.map((item) => (
            <span className="font-montserrat text-white p-1 mb-2 text-sm">
              {getCategoryNameTranslate(item, key)}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getCustomSearchStageInvitationsData } from '../../API/services/screeningService';
import BackgroundTop from '../../components/BackgroundTop/BackgroundTop';
import HorizontalLoader from '../../components/HorizontalLoader/HorizontalLoader';
import ScreeningForm from '../../components/ScreeningForm/ScreeningForm';
import useCreateSearch from '../../hooks/useCreateSearch';
import { t } from '../../i18n/i18n';
import { setSearchInvitationsData } from '../../redux/actions/screeningActions';
import { breadcrumbAlkymetricsEvaluation } from '../../constants/breadcrumb';

const NewSearchSend = () => {
  const history = useHistory();
  const { newSearch, selectedSearch } = useSelector((state) => state.screenings);
  const dispatch = useDispatch();
  const { loading } = useCreateSearch();

  useEffect(() => {
    if (!newSearch?.stages?.length) {
      history.replace('/screening/searches');
      return;
    }

    const stage = newSearch?.stages[0] ?? selectedSearch?.stages[0];
    getCustomSearchStageInvitationsData(stage?.id).then((res) => {
      dispatch(setSearchInvitationsData(res));
    });
  }, []);

  return (
    <div className="w-100 mx-auto h-100">
      <div>
        <BackgroundTop
          routes={[
            ...breadcrumbAlkymetricsEvaluation,
            {
              text: history.location?.state?.fromName,
              clickable: true,
              route: history.location?.state?.from,
            },
          ]}
          title={t('INVITE_FROM_EMAIL')}
          className="position-relative"
          titleClassname="text-xl"
        />
      </div>
      <div className="px-2">
        <div className="position-relative description-container new-search-container d-flex flex-column p-4 bg-white rounded mx-auto mb-5">
          <h3 className="font-montserrat font-weight-600 text-base mb-3">
            {t('CREATE_SEARCH_SEND_TITLE')}
          </h3>
          <h3 className="font-montserrat font-weight-500 text-sm mt-2 gray-subtitle">
            {t('CREATE_SEARCH_SEND_SUBTITLE')}
          </h3>
          <div className="position-relative pl-3 pr-3">
            <div className="m-auto">
              <ScreeningForm viewSubtitle={false} />
            </div>
          </div>
          {loading && (
            <div className="w-100 d-flex justify-content-end">
              <HorizontalLoader />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewSearchSend;

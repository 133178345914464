import React, { useEffect } from 'react';
import AssignedWeightIcon from '../../assets/evaluation/AssignedWeightIcon';
import UnassignedWeightIcon from '../../assets/evaluation/UnassignedWeightIcon';
import WeightsEmptyState from '../../assets/evaluation/WeightsEmptyState';
import useCreateScreening from '../../hooks/useCreateScreening';
import { t } from '../../i18n/i18n';
import { formatMinutesToHours } from '../../utils/formatMinutesToHours';
import { inHtmlTag } from '../../utils/inHtmlTag';
import TestGroupsListItem from '../TestGroupsListItem/TestGroupsListItem';
import { AnswerTypeEnum, PlanFeaturesIds, TestGroupTypes } from '../../constants/enums';
import QuickConfigurationsItem from '../QuickConfigurationsItem/QuickConfigurationsItem';
import GenericToggle from '../GenericToggle/GenericToggle';
import DropdownSelect from '../DropdownSelect/DropdownSelect';
import CalendarDatePicker from '../CalendarDatePicker/CalendarDatePicker';
import { generateHours } from '../../constants/hours';
import DisabledFeature from '../DisabledFeature/DisabledFeature';

const CreateEvaluationFourthStep = ({
  disableCustomTestGroupWeight,
  handleChangeStep,
  missingTestgroupWeight,
  evaluation,
  handleSetEvaluationData,
}) => {
  const {
    newScreening,
    allTests,
    testsDuration,
    customScreeningTotalTime,
    availableWeight,
    handleOrder,
    handleDeleteCustomTestGroup,
    handleTests,
    modifyTestWeight,
    handleEditTestGroupItem,
    handleEditCustomTestGroupItem,
  } = useCreateScreening();
  const hours = generateHours();
  const calculateWeight = () => {
    if (allTests?.length === 1) {
      modifyTestWeight(allTests[0].id, 100);
      return;
    }
    const customTestAmount = allTests?.reduce((count, testGroup) => {
      if (
        testGroup.type === TestGroupTypes.CUSTOM &&
        testGroup.tests?.some((testGroup) =>
          [AnswerTypeEnum.CHECK, AnswerTypeEnum.TEXT_AI, AnswerTypeEnum.WRITE].includes(
            testGroup.answerType
          )
        )
      ) {
        return count + 1;
      }
      return count;
    }, 0);

    const allTestsQuantitatives = allTests?.filter((x) => x?.type === TestGroupTypes.QUANTITATIVE);
    const allRemainingTests = allTests?.filter((x) => x?.type !== TestGroupTypes.QUANTITATIVE);

    const testAmount = allTestsQuantitatives?.length;
    let tgWeight = 100;
    let customTgWeight = 0;

    if (customTestAmount > 0) {
      tgWeight = testAmount > 0 ? Math.floor(99 / testAmount) : 0;
      customTgWeight = 100 - tgWeight * testAmount;
    } else {
      tgWeight = testAmount > 0 ? Math.floor(100 / testAmount) : 0;
    }

    allTestsQuantitatives?.forEach((testGroup, index) => {
      const isLastElement = index === allTestsQuantitatives.length - 1;
      if (isLastElement && customTestAmount === 0 && tgWeight * testAmount < 100) {
        // si la cuenta no da exacta, se suma punto restante
        const lastWeight = 100 - tgWeight * (testAmount - 1);
        modifyTestWeight(testGroup.id, lastWeight);
      } else {
        modifyTestWeight(testGroup.id, tgWeight);
      }
    });

    allRemainingTests.forEach((testGroup) => {
      if (
        testGroup.type === TestGroupTypes.CUSTOM &&
        testGroup.tests?.some((testGroup) =>
          [AnswerTypeEnum.CHECK, AnswerTypeEnum.TEXT_AI, AnswerTypeEnum.VIDEO].includes(
            testGroup.answerType
          )
        )
      ) {
        modifyTestWeight(testGroup.id, customTgWeight);
      }
    });
  };

  const determineAvailableWeightText = () => {
    let text;
    if (availableWeight === 0) {
      if (missingTestgroupWeight) {
        text = t('UNASSIGNED_SOME_TESTS_WEIGHT_DISCLAIMER');
      } else {
        text = t('ALL_WEIGHT_ASSIGNED_DISCLAIMER');
      }
    }
    if (availableWeight > 0)
      text = t('UNASSIGNED_WEIGHT_DISCLAIMER').replace('{weight}', availableWeight);
    if (availableWeight < 0)
      text = t('ASSIGNED_WEIGHT_EXCEDED_LIMIT').replace('{weight}', Math.abs(availableWeight));
    return text;
  };
  const handleEnableDates = () => {
    handleSetEvaluationData({
      ...evaluation,
      emptyDates: !evaluation.emptyDates,
      ...(!evaluation.emptyDates
        ? {
            startDate: {
              day: new Date().toISOString(),
              hours: '00:00',
            },
            endDate: {
              day: new Date('9999-01-01').toISOString(),
              hours: '00:00',
            },
          }
        : {
            startDate: {
              day: new Date().toISOString(),
              hours: '00:00',
            },
            endDate: {
              day: new Date().toISOString(),
              hours: '01:00',
            },
          }),
    });
  };

  const handleToggleDownloadCertificate = () => {
    handleSetEvaluationData({
      ...evaluation,
      allowCertificateDownload: !evaluation.allowCertificateDownload,
    });
  };

  const handleEnableProctoring = () => {
    handleSetEvaluationData({
      ...evaluation,
      proctoringEnabled: !evaluation.proctoringEnabled,
    });
  };

  const handleEnableRequireCompletedProfile = () => {
    handleSetEvaluationData({
      ...evaluation,
      requireCompletedProfile: !evaluation.requireCompletedProfile,
    });
  };

  const handleEnableExclusiveUsers = () => {
    handleSetEvaluationData({
      ...evaluation,
      exclusiveUsers: !evaluation.exclusiveUsers,
    });
  };

  const handleSetStartDate = (e) => {
    handleSetEvaluationData({
      ...evaluation,
      startDate: {
        ...evaluation?.startDate,
        hours: e?.name,
      },
    });
  };

  const handleSetEndDate = (e) => {
    handleSetEvaluationData({
      ...evaluation,
      endDate: {
        ...evaluation?.endDate,
        hours: e?.name,
      },
    });
  };

  useEffect(() => {
    calculateWeight();
  }, []);

  const handleQuestionButton = ({ newItem }) => {
    const itemFound = newScreening?.testGroups?.find((item) => item?.id === newItem?.id);
    if (itemFound) {
      handleEditTestGroupItem({ newTestGroupItem: newItem });
    } else {
      handleEditCustomTestGroupItem({ newCustomTestGroupItem: newItem });
    }
  };

  return (
    <div data-testid="create-evaluation-fourth-step" className="d-flex flex-column">
      <span
        className="font-weight-600 font-montserrat create-evaluation-step-title text-light-black mb-3"
        data-testid="create-evaluation-fs-title-span"
      >
        {t('CREATE_EVALUATION_FOURTH_STEP_TITLE')}
      </span>
      <span
        className="font-montserrat font-weight-600 text-base text-light-black"
        data-testid="create-evaluation-fs-test-title-span"
      >
        {t('CREATE_EVALUATION_FOURTH_STEP_TESTS_TITLE')}
      </span>
      <span
        className="text-light-black font-weight-400 text-base my-2 font-montserrat"
        data-testid="create-evaluation-fs-test-subtitle-span"
      >
        {t('CREATE_EVALUATION_FOURTH_STEP_TESTS_SUBTITLE')}
      </span>
      {allTests.length ? (
        <div className="mt-2 d-flex flex-column">
          <div className="d-flex my-2">
            {[
              {
                title: t('CREATE_EVALUATION_FOURTH_STEP_TOTAL_EXAMS'),
                value: newScreening?.testGroups?.length,
                id: '3aa9d972-cf7c-451f-9e91-ca2e8c4ee4cc',
              },
              {
                title: t('CREATE_EVALUATION_FOURTH_STEP_TOTAL_QUESTIONS'),
                value: newScreening?.customTestGroup.length,
                id: 'e5bb4170-0530-4b53-8981-1cf321287d41',
              },
              {
                title: t('CREATE_SCREENING_TOTAL_TIME'),
                value: formatMinutesToHours(customScreeningTotalTime + testsDuration),
                id: 'acb9a004-00f4-45b7-9d5a-be7832c8445e',
              },
            ].map((item, index) => (
              <div key={item.id} className="mr-4">
                <span
                  className="font-montserrat font-italic text-muted text-base font-weight-600"
                  data-testid={`create-evaluation-fs-title-${index}-span`}
                >
                  {item.title}
                </span>
                <span
                  className="total-number-text font-italic font-montserrat font-weight-bold"
                  data-testid={`create-evaluation-fs-value-${index}-span`}
                >
                  {item.value}
                </span>
              </div>
            ))}
          </div>
          {allTests
            ?.sort((a, b) => a.order - b.order)
            ?.map((item, index) => (
              <TestGroupsListItem
                key={item.id}
                testGroup={item}
                disableCustomWeight={item?.type === 'custom' && disableCustomTestGroupWeight}
                disableOrderUp={index === 0}
                disableOrderDown={index === newScreening.allTests.length - 1}
                handleOrder={handleOrder}
                handleQuestionButton={handleQuestionButton}
                handleDelete={() =>
                  item.type === 'custom' ? handleDeleteCustomTestGroup() : handleTests(item)
                }
              />
            ))}
          <span
            data-testid="fourth-step-available-weight"
            className={`ml-auto available-weight-custom-height mt-2 d-flex align-items-center mr-5 pr-5 text-xs font-weight-700 font-montserrat ${
              availableWeight === 0 && !missingTestgroupWeight
                ? 'all-weight-assigned-text'
                : 'unassigned-weight-assigned-text'
            }`}
          >
            {availableWeight === 0 && !missingTestgroupWeight ? (
              <AssignedWeightIcon classNames="mr-2" />
            ) : (
              <UnassignedWeightIcon classNames="mr-2" />
            )}
            {determineAvailableWeightText()}
          </span>
        </div>
      ) : (
        <div className="mt-4 w-66 mx-auto d-flex">
          <WeightsEmptyState testid="fourth-step-empty-state-icon" />
          <div className="d-flex flex-column ml-5">
            <span
              className="font-montserrat text-base font-weight-bold text-xl mb-2"
              data-testid="create-evaluation-fs-empty-state-title-span"
            >
              {t('CREATE_EVALUATION_FOURTH_STEP_EMPTY_STATE_TITLE')}
            </span>
            <span
              className="font-montserrat text-base font-weight-500"
              dangerouslySetInnerHTML={{
                __html: t('CREATE_EVALUATION_FOURTH_STEP_EMPTY_STATE_SUBTITLE').replace(
                  '{bold}',
                  inHtmlTag({
                    tag: 'b',
                    className: 'font-montserrat',
                    content: t('CREATE_EVALUATION_FOURTH_STEP_EMPTY_STATE_SUBTITLE_BOLD'),
                  })
                ),
              }}
              data-testid="create-evaluation-fs-empty-state-subtitle-span"
            />
            <div className="ml-auto mt-3">
              {[
                {
                  text: t('CREATE_EVALUATION_FOURTH_STEP_ADD_TEST_BUTTON'),
                  onClick: () => handleChangeStep(1),
                  id: '50f11e3f-2c0f-4585-bc50-6c0d3ef96708',
                },
                {
                  text: t('CREATE_EVALUATION_FOURTH_STEP_ADD_QUESTION_BUTTON'),
                  onClick: () => handleChangeStep(2),
                  classNames: 'ml-2',
                  id: '88ff2089-1bd4-4c7e-a293-a7988b52258d',
                },
              ].map((item, index) => (
                <button
                  key={item.id}
                  onClick={item.onClick}
                  className={`create-evaluation-secondary-button font-montserrat font-weight-700 bg-transparent py-2 px-4 text-xs ${item.classNames}`}
                  type="button"
                  data-testid={`create-evaluation-fs-button-option-${index}-btn`}
                >
                  {item.text}
                </button>
              ))}
            </div>
          </div>
        </div>
      )}

      <div>
        <span className="font-montserrat font-weight-600 text-base text-light-black mb-2">
          {t('CREATE_EVALUATION_OPTIONAL_CONFIGURATION')}
        </span>
        <DisabledFeature
          className="d-flex w-100 align-items-center"
          childrenClassname="w-100"
          iconClassName="fourth-step-premium-functions"
          featureId={PlanFeaturesIds.TEXT_AI}
        >
          <QuickConfigurationsItem
            title={t('CREATE_EVALUATION_PLAN_DATES')}
            configurationBody={
              <>
                <span
                  className="font-montserrat font-weight-600 text-base py-2 text-light-black"
                  data-testid="create-evaluation-fs-date-title-span"
                >
                  {t('CREATE_EVALUATION_FIRST_STEP_TEST_DATE_TITLE')}
                </span>
                <span
                  className="text-light-black font-weight-400 text-base my-2 font-montserrat w-100"
                  dangerouslySetInnerHTML={{
                    __html: t('CREATE_EVALUATION_FIRST_STEP_TEST_DATE_SUBTITLE').replace(
                      '{bold}',
                      inHtmlTag({
                        tag: 'b',
                        className: 'font-montserrat',
                        content: t('CREATE_EVALUATION_FIRST_STEP_TEST_DATE_SUBTITLE_BOLD'),
                      })
                    ),
                  }}
                  data-testid="create-evaluation-fs-date-subtitle-span"
                />
                <div className="d-flex align-items-center">
                  <div>
                    <div className="d-flex mt-2 align-items-center">
                      <span className="font-montserrat font-weight-600 text-light-black text-base">
                        {t('CREATE_EVALUATION_QUICK_CONFIG_DATES_ENABLE')}
                      </span>
                      <GenericToggle
                        data-testid="toggle"
                        defaultActive={!evaluation.emptyDates}
                        actionFunction={handleEnableDates}
                      />
                    </div>
                  </div>
                  {!evaluation.emptyDates && (
                    <div className="ml-4">
                      <div className="d-flex justify-content-start">
                        <div className="w-10">
                          <CalendarDatePicker
                            stageData={evaluation}
                            dateType="startDate"
                            buttonClassNames="text-nowrap create-evaluation-datepickers"
                            placeholder={t('CREATE_EVALUATION_START_DATE_DATEPICKER_PLACEHOLDER')}
                            setStageData={handleSetEvaluationData}
                          />
                        </div>
                        <DropdownSelect
                          searchable
                          options={hours}
                          selected={evaluation?.startDate?.hours}
                          selectedPlaceholder
                          setState={handleSetStartDate}
                          className="time-picker-button"
                          placeholderClassname="ml-4"
                        />
                      </div>
                      <div className="d-flex justify-content-start">
                        <div className="w-10">
                          <CalendarDatePicker
                            stageData={evaluation}
                            dateType="endDate"
                            iconClassNames="ml-4 pl-2"
                            buttonClassNames="text-nowrap create-evaluation-datepickers"
                            placeholder={t('CREATE_EVALUATION_END_DATE_DATEPICKER_PLACEHOLDER')}
                            setStageData={handleSetEvaluationData}
                          />
                        </div>
                        <DropdownSelect
                          options={hours}
                          selected={evaluation?.endDate?.hours || '00:00'}
                          selectedPlaceholder
                          setState={handleSetEndDate}
                          className="time-picker-button"
                          placeholderClassname="ml-4"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </>
            }
          />
        </DisabledFeature>
        <DisabledFeature
          className="d-flex w-100 align-items-center"
          childrenClassname="w-100"
          iconClassName="fourth-step-premium-functions"
          featureId={PlanFeaturesIds.PROCTORING}
        >
          <QuickConfigurationsItem
            title={t('CREATE_EVALUATION_QUICK_CONFIG_ENABLE_PROCTORING')}
            configurationBody={
              <div className="my-1">
                <div className="d-flex align-items-center">
                  <span
                    className="font-montserrat font-weight-600 text-base py-2 text-light-black"
                    data-testid="create-evaluation-fs-download-certificate-span"
                  >
                    {t('CREATE_EVALUATION_ENABLE_PROCTORING')}
                  </span>
                  <span
                    className="font-montserrat text-xs ml-2 italic py-2 text-muted"
                    data-testid="create-evaluation-fs-download-certificate-span-optional"
                  >
                    {t('CREATE_EVALUATION_ALLOW_DOWNLOAD_CERTIFICATES_OPTIONAL')}
                  </span>
                </div>
                <div className="d-flex mt-2 align-items-center">
                  <span className="font-montserrat font-weight-600 text-light-black text-base">
                    {t('CREATE_EVALUATION_ALLOW_DOWNLOAD_CERTIFICATES_BUTTON')}
                  </span>
                  <GenericToggle
                    data-testid="toggle"
                    defaultActive={evaluation.proctoringEnabled}
                    actionFunction={handleEnableProctoring}
                  />
                </div>
              </div>
            }
          />
        </DisabledFeature>
        <DisabledFeature
          className="d-flex w-100 align-items-center"
          childrenClassname="w-100"
          iconClassName="fourth-step-premium-functions"
          featureId={PlanFeaturesIds.EXCLUSIVE_TEMP_USERS}
          hideOnDisabled
        >
          <QuickConfigurationsItem
            title={t('CREATE_EVALUATION_QUICK_CONFIG_ENABLE_EXCLUSIVE_TEMP_USERS')}
            configurationBody={
              <div className="my-1">
                <div className="d-flex align-items-center">
                  <span
                    className="font-montserrat font-weight-600 text-base py-2 text-light-black"
                    data-testid="create-evaluation-fs-exclusive-users-span"
                  >
                    {t('CREATE_EVALUATION_ENABLE_EXCLUSIVE_TEMP_USERS')}
                  </span>
                  <span
                    className="font-montserrat text-xs ml-2 italic py-2 text-muted"
                    data-testid="create-evaluation-fs-exclusive-users-span-optional"
                  >
                    {t('CREATE_EVALUATION_ALLOW_DOWNLOAD_CERTIFICATES_OPTIONAL')}
                  </span>
                </div>
                <span
                  className="text-light-black font-weight-400 text-base my-2 font-montserrat"
                  data-testid="create-evaluation-fs-exclusive-users-description"
                >
                  {t('CREATE_EVALUATION_ENABLE_EXCLUSIVE_TEMP_USERS_DESCRIPTION')}
                </span>
                <div className="d-flex mt-2 align-items-center">
                  <span className="font-montserrat font-weight-600 text-light-black text-base">
                    {t('CREATE_EVALUATION_ENABLE_EXCLUSIVE_TEMP_USERS_BUTTON')}
                  </span>
                  <GenericToggle
                    data-testid="toggle"
                    defaultActive={evaluation.exclusiveUsers}
                    actionFunction={handleEnableExclusiveUsers}
                  />
                </div>
              </div>
            }
          />
        </DisabledFeature>
        <QuickConfigurationsItem
          title={t('CREATE_EVALUATION_QUICK_CONFIG_ALLOW_DOWNLOADS')}
          configurationBody={
            <div className="my-1">
              <div className="d-flex align-items-center">
                <span
                  className="font-montserrat font-weight-600 text-base py-2 text-light-black"
                  data-testid="create-evaluation-fs-download-certificate-span"
                >
                  {t('CREATE_EVALUATION_ALLOW_DOWNLOAD_CERTIFICATES')}
                </span>
                <span
                  className="font-montserrat text-xs ml-2 italic py-2 text-muted"
                  data-testid="create-evaluation-fs-download-certificate-span-optional"
                >
                  {t('CREATE_EVALUATION_ALLOW_DOWNLOAD_CERTIFICATES_OPTIONAL')}
                </span>
              </div>
              <span
                className="text-light-black font-weight-400 text-base my-2 font-montserrat"
                data-testid="create-evaluation-fs-download-certificate-description"
              >
                {t('CREATE_EVALUATION_ALLOW_DOWNLOAD_CERTIFICATES_DESCRIPTION')}
              </span>
              <div className="d-flex mt-2 align-items-center">
                <span className="font-montserrat font-weight-600 text-light-black text-base">
                  {t('CREATE_EVALUATION_ALLOW_DOWNLOAD_CERTIFICATES_BUTTON')}
                </span>
                <GenericToggle
                  data-testid="toggle"
                  defaultActive={evaluation.allowCertificateDownload}
                  actionFunction={handleToggleDownloadCertificate}
                />
              </div>
            </div>
          }
        />
        <QuickConfigurationsItem
          title={t('ENABLE_COMPLETE_PROFILE_DROPDOWN_TITLE')}
          configurationBody={
            <div className="my-1">
              <div className="d-flex align-items-center">
                <span
                  className="font-montserrat font-weight-600 text-base py-2 text-light-black"
                  data-testid="create-evaluation-fs-download-certificate-span"
                >
                  {t('ENABLE_COMPLETE_PROFILE_DROPDOWN_DESCRIPTION')}
                </span>
                <span
                  className="font-montserrat text-xs ml-2 italic py-2 text-muted"
                  data-testid="create-evaluation-fs-download-certificate-span-optional"
                >
                  {t('CREATE_EVALUATION_ALLOW_DOWNLOAD_CERTIFICATES_OPTIONAL')}
                </span>
              </div>
              <span
                className="text-light-black font-weight-400 text-base my-2 font-montserrat"
                data-testid="create-evaluation-fs-download-certificate-description"
              >
                {t('ENABLE_COMPLETE_PROFILE_CERTIFICATES_DESCRIPTION')}
              </span>
              <div className="d-flex mt-2 align-items-center">
                <span className="font-montserrat font-weight-600 text-light-black text-base">
                  {t('CREATE_EVALUATION_ALLOW_DOWNLOAD_CERTIFICATES_BUTTON')}
                </span>
                <GenericToggle
                  data-testid="toggle"
                  defaultActive={evaluation.requireCompletedProfile}
                  actionFunction={handleEnableRequireCompletedProfile}
                />
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default CreateEvaluationFourthStep;

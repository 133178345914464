const Close = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.702437 19.8676C0.311915 20.2581 0.311916 20.8913 0.702437 21.2818L2.71806 23.2975C3.10858 23.688 3.74174 23.688 4.13226 23.2975L11.9995 15.4301L19.8668 23.2975C20.2573 23.688 20.8905 23.688 21.281 23.2975L23.2966 21.2819C23.6872 20.8913 23.6872 20.2582 23.2966 19.8676L15.4294 12.0002L23.297 4.13244C23.6875 3.74192 23.6875 3.10875 23.297 2.71822L21.2814 0.702563C20.8909 0.312036 20.2577 0.312036 19.8672 0.702564L11.9995 8.57034L4.1319 0.702577C3.74137 0.31205 3.10821 0.31205 2.71769 0.702578L0.70207 2.71823C0.311549 3.10876 0.311549 3.74193 0.702071 4.13246L8.56971 12.0002L0.702437 19.8676Z"
      fill="#58C1F5"
    />
  </svg>
);

export default Close;

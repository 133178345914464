import { useState } from 'react';
import { notification } from 'antd';
import { t } from '../i18n/i18n';
import {
  getCompaniesPaginated,
  getCompanySearches,
  getSearchInvitations,
} from '../API/services/companyService';
import { getRestartScreeningData, postRestartTestgroup } from '../API/services/screeningService';
import processQueryString from '../utils/processQueryString';

const useResetTestgroup = () => {
  const [step, setStep] = useState(1);
  const [companies, setCompanies] = useState([]);
  const [companySearches, setCompanySearches] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState();
  const [selectedSearch, setSelectedSearch] = useState();
  const [screeningData, setScreeningData] = useState();
  const [selectedTestgroups, setSelectedTestgroups] = useState([]);
  const [invitations, setInvitations] = useState([]);
  const [selectedInvitations, setSelectedInvitations] = useState([]);
  const [loadingInvitations, setLoadingInvitations] = useState(false);
  const [selectedInstances, setSelectedInstances] = useState([]);
  const [loading, setLoading] = useState(true);

  const handlePostData = async () => {
    const dataToPost = {
      testGroupId: selectedTestgroups[0],
      instanceIds: selectedInstances,
    };
    await postRestartTestgroup(dataToPost).then(() => {
      notification.open({
        message: t('POST_RESET_TESTGROUP_NOTIFICATION'),
        type: 'info',
      });
      setStep(5);
    });
  };
  const fetchCompanies = async (search = '') => {
    setLoading(true);
    await getCompaniesPaginated(search).then((res) => {
      setCompanies(res);
      setLoading(false);
    });
  };

  const fetchCompanySearches = async ({ companyId, search }) => {
    setLoading(true);
    await getCompanySearches({ companyId, search }).then((res) => {
      setCompanySearches(res);
      setLoading(false);
    });
  };

  const fetchSearchInvitations = async ({ search }) => {
    setLoading(true);
    const recruitSessionStage = selectedSearch.stages[0];
    await getSearchInvitations({ search, stage: recruitSessionStage?.id }).then((res) => {
      setInvitations(res);
      setLoading(false);
    });
  };

  const fetchSearchInvitationsNextPage = async () => {
    setLoadingInvitations(true);
    await getSearchInvitations(processQueryString(invitations?.pagination?.nextPage)).then(
      (res) => {
        setInvitations({
          collection: [...invitations.collection, ...res.collection],
          pagination: res.pagination,
        });
        setLoadingInvitations(false);
      }
    );
  };

  const fetchSearchScreening = async () => {
    setLoading(true);
    const recruitSessionStage = selectedSearch.stages[0];
    await getRestartScreeningData({
      levelId: recruitSessionStage.levelId,
      companyId: recruitSessionStage.companyId,
    }).then((res) => {
      setScreeningData(res);
      setLoading(false);
    });
  };

  const handleSelectTestgroup = (id) => {
    const { testGroups } = screeningData;
    const selectedTestGroupIndex = testGroups.findIndex((item) => item.id === id);
    const testGroupsSelected = testGroups
      .slice(selectedTestGroupIndex, testGroups.length)
      ?.map((testgroup) => testgroup.id);
    setSelectedTestgroups(testGroupsSelected);
  };

  const handleSelectInstance = (invitation) => {
    if (selectedInstances.includes(invitation.screeningInstanceId)) {
      setSelectedInstances(
        selectedInstances.filter((item) => item !== invitation.screeningInstanceId)
      );
      setSelectedInvitations(
        selectedInvitations.filter(
          (item) => item.screeningInstanceId !== invitation.screeningInstanceId
        )
      );
    } else {
      setSelectedInstances([...selectedInstances, invitation.screeningInstanceId]);
      setSelectedInvitations([...selectedInvitations, invitation]);
    }
  };

  const handleSelectCompany = (id) => {
    setSelectedCompany(id);
  };

  const handleSelectSearch = (id) => {
    setSelectedSearch(id);
  };

  const handleNextStep = () => {
    if (step < 4) {
      setStep(step + 1);
    } else {
      handlePostData();
    }
  };

  const handlePreviousStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const clearSelectedInvitations = () => {
    setSelectedInvitations([]);
    setSelectedInstances([]);
  };

  const clearSelectedTestgroups = () => {
    setSelectedTestgroups([]);
  };

  return {
    step,
    companies,
    companySearches,
    loading,
    selectedCompany,
    selectedSearch,
    screeningData,
    testgroups: screeningData?.testGroups ?? [],
    selectedTestgroups,
    selectedInstances,
    invitations,
    selectedInvitations,
    loadingInvitations,
    fetchSearchInvitationsNextPage,
    fetchSearchScreening,
    fetchCompanies,
    fetchCompanySearches,
    fetchSearchInvitations,
    handleSelectTestgroup,
    handleSelectCompany,
    handleSelectSearch,
    handleSelectInstance,
    handlePreviousStep,
    handleNextStep,
    clearSelectedInvitations,
    clearSelectedTestgroups,
  };
};

export default useResetTestgroup;

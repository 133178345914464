import { useState } from 'react';
import useCreateScreening from '../../hooks/useCreateScreening';
import { formatMinutesToHours } from '../../utils/formatMinutesToHours';
import { t } from '../../i18n/i18n';
import TestGroupsListItem from '../TestGroupsListItem/TestGroupsListItem';
import EditPen from '../../assets/screening/EditPen';

const CreateScreeningStep4 = () => {
  const [showEdit, setShowEdit] = useState(false);
  const {
    handleNextStep,
    handlePreviousStep,
    handleTests,
    handleDeleteCustomTestGroup,
    handleOrder,
    handleScreeningName,
    newScreening,
    allTests,
    testsDuration,
    customScreeningTotalTime,
    availableWeight,
  } = useCreateScreening();
  const customTestGroup = allTests.find((item) => item.type === 'custom');
  const disableCustomTestGroupWeight =
    customTestGroup && !customTestGroup?.tests?.some((test) => test.answerType === 'CHECK');
  const disableNextButton = Boolean(
    (disableCustomTestGroupWeight
      ? newScreening?.allTests
          ?.filter((item) => item?.type !== 'custom')
          .some((testGroup) => typeof testGroup.weight === 'undefined' || testGroup.weight === 0)
      : newScreening?.allTests.some(
          (testGroup) => typeof testGroup.weight === 'undefined' || testGroup.weight === 0
        )) ||
      availableWeight !== 0 ||
      !newScreening.name.length
  );

  return (
    <div className="d-flex flex-column">
      <span className="font-montserrat font-weight-bold text-base mt-2">
        {t('CREATE_SCREENING_GENERAL_SUMMARY')}
      </span>
      <span className="font-montserrat text-muted text-sm mt-3 font-italic">
        {t('CREATE_SCREENING_GENERAL_SUMMARY_SUBTITLE')}
      </span>
      <div className="d-flex flex-column w-50">
        <span className="font-montserrat font-weight-bold text-base mt-3">
          {t('SCREENING_NAME_TITLE')}
          {!showEdit && (
            <button
              className="bg-transparent border-0 outline-none ml-2"
              onClick={() => setShowEdit(true)}
              type="button"
            >
              <EditPen />
            </button>
          )}
        </span>
        <div className="w-100 d-flex flex-nowrap">
          <input
            disabled={!showEdit}
            onChange={handleScreeningName}
            type="text"
            value={newScreening.name}
            className={`font-montserrat py-2 rounded w-100 text-muted text-sm mt-1 transition-2-ms font-italic outline-none bg-transparent ${
              showEdit ? 'px-2 maya-blue-thin-border' : 'border-0'
            } ${newScreening.name?.length ? 'selected-border' : 'unselected-border'}`}
          />
          {showEdit && (
            <button
              disabled={!newScreening.name?.length}
              className="bg-transparent border-0 outline-none ml-2"
              onClick={() => setShowEdit(false)}
              type="button"
            >
              <EditPen disabled={!newScreening.name?.length} />
            </button>
          )}
        </div>
      </div>
      <span className="font-montserrat font-weight-bold text-base mt-3">
        {t('CREATE_SCREENING_GENERAL_INCLUDED_TESTS')}
      </span>
      <span className="font-montserrat text-muted text-sm mt-3 font-italic">
        {t('CREATE_SCREENING_GENERAL_WEIGHT_TESTS')}
      </span>
      <div className="my-4 d-flex align-items-center">
        <div className="d-flex flex-wrap">
          <div className="my-1 my-sm-0 mr-0 mr-sm-2 mr-md-5">
            <span className="font-montserrat font-italic text-muted text-sm font-weight-bold">
              {t('CREATE_SCREENING_SELECTED_TESTS')}
            </span>
            <span className="total-number-text font-italic font-montserrat font-weight-bold">
              {newScreening?.allTests?.length}
            </span>
          </div>
          <div className="my-1 my-sm-0 ml-0 ml-sm-2 ml-md-5">
            <span className="font-montserrat font-italic text-muted text-sm font-weight-bold">
              {t('CREATE_SCREENING_TOTAL_TIME')}
            </span>
            <span className="total-number-text font-italic font-montserrat font-weight-bold">
              {formatMinutesToHours(customScreeningTotalTime + testsDuration)}
            </span>
          </div>
          <div className="my-1 my-sm-0 ml-0 ml-sm-2 ml-md-5">
            <span className="font-montserrat font-italic text-muted text-sm font-weight-bold">
              {t('CREATE_SCREENING_GENERAL_REMAINING_WEIGHT')}
            </span>
            <span
              className={`font-italic font-montserrat ml-2 font-weight-bold ${
                availableWeight > 0
                  ? 'total-number-text'
                  : availableWeight === 0
                  ? 'text-success'
                  : 'text-danger'
              }`}
            >
              {availableWeight?.toFixed(2)}%
            </span>
          </div>
        </div>
      </div>
      <div className="mt-2">
        {allTests
          ?.sort((a, b) => a.order - b.order)
          ?.map((item, index) => (
            <TestGroupsListItem
              testGroup={item}
              disableCustomWeight={item?.type === 'custom' && disableCustomTestGroupWeight}
              disableOrderUp={index === 0}
              disableOrderDown={index === newScreening.allTests.length - 1}
              handleOrder={handleOrder}
              handleDelete={() =>
                item.type === 'custom' ? handleDeleteCustomTestGroup() : handleTests(item)
              }
            />
          ))}
      </div>
      <div className="d-flex justify-content-between">
        <button
          onClick={handlePreviousStep}
          className="mt-4 align-self-end px-3 py-1 rounded-pill font-roboto text-white text-sm font-weight-bold border-0 outline-none bg-maya-blue"
          type="button"
        >
          {t('CREATE_SCREENING_BACK')}
        </button>
        <button
          disabled={disableNextButton}
          onClick={handleNextStep}
          className={`mt-4 align-self-end px-3 py-1 rounded-pill font-roboto text-white text-sm font-weight-bold border-0 outline-none ${
            disableNextButton ? 'disabled-button' : 'bg-maya-blue'
          }`}
          type="button"
        >
          {t('CREATE_SCREENING_CONTINUE')}
        </button>
      </div>
    </div>
  );
};
export default CreateScreeningStep4;

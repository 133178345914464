export const maxRecordTimes = [
  {
    name: '30 s',
    id: 30,
  },
  {
    name: '1 m',
    id: 60,
  },
  {
    name: '2 m',
    id: 120,
  },
  {
    name: '3 m',
    id: 180,
  },
  {
    name: '4 m',
    id: 240,
  },
  {
    name: '5 m',
    id: 300,
  },
];
export const maxPreparationTimes = [
  {
    name: '5 s',
    id: 5,
  },
  {
    name: '15 s',
    id: 15,
  },
  {
    name: '30 s',
    id: 30,
  },
  {
    name: '1 m',
    id: 60,
  },
];
export const maxAttemptsOptions = [
  {
    name: '0',
    id: 0,
  },
  {
    name: '1',
    id: 1,
  },
  {
    name: '2',
    id: 2,
  },
  {
    name: '3',
    id: 3,
  },
  {
    name: '4',
    id: 4,
  },
];

import React, { useEffect, useState } from 'react';
import useCreateScreening from '../../hooks/useCreateScreening';
import { t } from '../../i18n/i18n';
import { inHtmlTag } from '../../utils/inHtmlTag';
import ModalSubmission from '../ModalSubmission/ModalSubmission';
import AlkemyTests from './AlkemyTests/AlkemyTests';
import FavoriteTests from './FavoriteTests/FavoriteTests';
import RoleTests from './RoleTests/RoleTests';
import SelectedTests from './SelectedTests/SelectedTests';
import { TrackEvent } from '../../utils/filters/segmentUtils';
import SearchIcon from '../../assets/screening/SearchIcon';
import MultipleSelect from '../MultipleSelect/MultipleSelect';
import TreeMultiSelect from '../TreeMultiSelect/TreeMultiSelect';

const CreateEvaluationSecondStep = ({
  fetchTests,
  loadingTests,
  isTestsPage = false,
  evaluation,
}) => {
  const [buttonSelected, setButtonSelected] = useState(isTestsPage ? 'TEST_ALKEMY' : 'TEST_ALKEMY');
  const {
    newScreening,
    testGroupLevelsFilterOptions,
    testGroupRolesFilterOptions,
    fetchLevels,
    fetchCategories,
    fetchRolesWithTestGroups,
    deselectTg,
    newScreeningFilters,
    testGroupCategoriesFilterOptions,
    handleInput,
    handleSubmit,
    handleSelectFilterOption,
    handleSelectFilterOptionMultiple,
  } = useCreateScreening();
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (!testGroupLevelsFilterOptions?.length) {
      fetchLevels();
    }

    fetchCategories(true);

    if (!testGroupRolesFilterOptions?.length) {
      fetchRolesWithTestGroups();
    }
  }, []);

  const options = isTestsPage
    ? ['TEST_ALKEMY', 'TEST_BY_ROLE', 'MY_FAVORITES']
    : ['TEST_ALKEMY', 'MY_FAVORITES', 'SELECTED_TESTS'];

  useEffect(() => {
    if (buttonSelected !== 3 && isTestsPage) {
      deselectTg();
    }
  }, [buttonSelected]);

  return (
    <div data-testid="create-evaluation-second-step" className="d-flex flex-column">
      <ModalSubmission show={show} setShow={setShow} isInvitedProfile isTestgroupListDetail />
      <span
        className="font-weight-600 font-montserrat create-evaluation-step-title text-light-black mb-3"
        data-testid="create-evaluation-ss-title-span"
      >
        {t('CREATE_EVALUATION_SECOND_STEP_TITLE')}
      </span>
      <div className="d-flex">
        <span
          className="font-montserrat font-weight-600 text-base text-light-black justify-content-start"
          data-testid="create-evaluation-ss-test-title-span"
        >
          {t('CREATE_EVALUATION_SECOND_STEP_TESTS_TITLE')}
        </span>
        <div className="flex-fill" />
        <a
          className="font-montserrat font-weight-500 font-montserrat text-sm text-muted create-evaluation-gray-underline justify-content-end"
          onClick={() => {
            TrackEvent('suggest-test-link');
          }}
          href={process.env.RAZZLE_RUNTIME_TEST_SUGGESTIONS_LINK}
          rel="noopener noreferrer"
          target="_blank"
          data-testid="create-evaluation-ss-link-a"
        >
          {t('CREATE_EVALUATION_NEED_ANOTHER_TEST')}
        </a>
      </div>

      {!isTestsPage ? (
        <span
          className="text-light-black font-weight-400 text-base my-2 font-montserrat"
          dangerouslySetInnerHTML={{
            __html: t('CREATE_EVALUATION_SECOND_STEP_TESTS_SUBTITLE_NOT_CATALOG'),
          }}
          data-testid="create-evaluation-ss-test-subtitle-span"
        />
      ) : (
        <span
          className="text-light-black font-weight-400 text-base my-2 font-montserrat"
          dangerouslySetInnerHTML={{
            __html: t('CREATE_EVALUATION_SECOND_STEP_TESTS_SUBTITLE')
              .replace(
                '{firstBold}',
                inHtmlTag({
                  tag: 'b',
                  className: 'font-montserrat',
                  content: t('CREATE_EVALUATION_SECOND_STEP_SUBTITLE_FIRST_BOLD'),
                })
              )
              .replace(
                '{secondBold}',
                inHtmlTag({
                  tag: 'b',
                  className: 'font-montserrat',
                  content: t('CREATE_EVALUATION_SECOND_STEP_SUBTITLE_SECOND_BOLD'),
                })
              ),
          }}
          data-testid="create-evaluation-ss-test-subtitle-span"
        />
      )}

      <div
        className="my-2 d-flex justify-content-between align-items-center"
        style={{ flexDirection: 'row', columnGap: '1rem' }}
      >
        <div className="d-flex align-items-center" style={{ height: 'auto', width: '30rem' }}>
          {options?.map((text) => (
            <button
              key={text}
              type="button"
              data-testid="create-evaluation-second-step-button"
              className={`bg-transparent p-2 d-flex mr-2 justify-content-center align-items-center button-filter ${
                text === buttonSelected ? 'test-button-filters-selected' : 'test-button-filters'
              }`}
              onClick={() => {
                if (text === 'TEST_ALKEMY') {
                  TrackEvent('create-evaluation-search-by-test');
                }
                setButtonSelected(text);
              }}
            >
              <span
                className={`font-montserrat test-text-filters ${
                  text === buttonSelected ? 'test-text-filters-selected' : 'test-text-filters'
                }`}
                data-testid="create-evaluation-ss-filters-selected-span"
              >
                {t(text)} {text === 'SELECTED_TESTS' && `(${newScreening?.testGroups?.length})`}
              </span>
            </button>
          ))}
        </div>
        <div className="d-flex align-items-center" style={{ flex: '1', height: 'auto' }}>
          <div
            className="d-flex align-items-center justify-content-start pr-4"
            style={{ flex: '1' }}
          >
            <form
              onBlur={(e) => handleSubmit(e, true)}
              onSubmit={(e) => handleSubmit(e, true)}
              className={`my-1 my-sm-0 rounded transition-2-ms p-2 d-flex align-items-center ${
                newScreeningFilters?.name?.length
                  ? 'tests-search-input-full bg-white'
                  : 'tests-search-input-empty bg-gray'
              }`}
              style={{ flex: '1' }}
            >
              <SearchIcon
                testid="alkemy-tests-search-icon"
                color={newScreeningFilters?.name?.length ? '#1aacf0' : '#424242'}
              />
              <input
                onChange={handleInput}
                value={newScreeningFilters?.name}
                className="font-montserrat text-xs w-100 bg-transparent border-0 outline-none ml-2"
                style={{ flex: '1' }}
                placeholder={t('CREATE_SCREENING_SEARCH_PLACEHOLDER')}
                type="text"
                data-testid="alkemy-tests-search-input"
              />
            </form>
          </div>
          <div
            className="d-flex align-items-center justify-content-end"
            style={{ height: 'auto', width: '16rem' }}
          >
            <TreeMultiSelect
              options={testGroupCategoriesFilterOptions.filter(
                (category) => category?.id !== process.env.RAZZLE_RUNTIME_CATEGORY_ID
              )}
              value={newScreeningFilters.category}
              onChange={async (value) => {
                await handleSelectFilterOptionMultiple(value, 'category', false);
              }}
              buttonClassNames={`${newScreeningFilters?.category?.length && 'border-maya-blue'}`}
              className="select-color drops-filter flex-row mr-2"
              label={t('MULTIPLE_SELECT_CATEGORIES_PLACEHOLDER')}
              labelPosition={{ top: 8 }}
            />
            <MultipleSelect
              title={t('MULTIPLE_SELECT_LEVELS_PLACEHOLDER')}
              classNames="select-color drops-filter flex-row"
              buttonClassNames={`${newScreeningFilters?.levelId?.length && 'border-maya-blue'}`}
              onSelect={(e) => handleSelectFilterOption(e.id, 'levelId', false)}
              selectedList={newScreeningFilters.levelId}
              options={testGroupLevelsFilterOptions}
            />
          </div>
        </div>
      </div>
      {buttonSelected === 'TEST_BY_ROLE' && (
        <RoleTests
          options={testGroupRolesFilterOptions}
          setShow={setShow}
          loadingTests={loadingTests}
          fetchTests={fetchTests}
          isCheck={!isTestsPage}
        />
      )}
      {buttonSelected === 'TEST_ALKEMY' && (
        <AlkemyTests
          loadingTests={loadingTests}
          setShow={setShow}
          evaluation={evaluation}
          fetchTests={fetchTests}
          isCheck={!isTestsPage}
        />
      )}
      {buttonSelected === 'MY_FAVORITES' && (
        <FavoriteTests
          fetchTests={fetchTests}
          loadingTests={loadingTests}
          setShow={setShow}
          isCheck={!isTestsPage}
        />
      )}
      {buttonSelected === 'SELECTED_TESTS' && !isTestsPage && (
        <SelectedTests setShow={setShow} setButtonSelected={setButtonSelected} />
      )}
    </div>
  );
};

export default CreateEvaluationSecondStep;

import { t } from '../../i18n/i18n';
import './newStyleSelect.scss';

const NewStyleSelect = ({ options, name, title, required, className, value, onChange }) => (
  <div className={`d-flex flex-column position-relative ${className}`}>
    <span className="d-flex align-items-center font-light mb-1 italic text-sm text-truncate custom-input-label-active font-montserrat ml-2">
      {title}
      {required && <span className="text-danger ml-1">*</span>}
    </span>
    <div className="outline-none d-flex justify-content-between w-100 align-items-center px-2 font-semibold border-1 rounded-lg">
      <select
        className="styled-select bg-transparent outline-none font-montserrat text-truncate text-xs py-1 pr-2 px-2 flex-grow-1 font-semibold border-gray-300"
        name={name}
        value={value}
        onChange={onChange}
      >
        <option className="d-none" value="" label="-" />
        {options &&
          options?.map((option, index) => (
            <option
              className={`font-montserrat font-semibold w-100 ${option.disabled && 'd-none'}`}
              value={option.id}
              key={index}
            >
              {t(option.name) ?? option.name}
            </option>
          ))}
      </select>
    </div>
  </div>
);

export default NewStyleSelect;

export default function SelectableButton({ onClick, className, text, selected, disabled = false }) {
  return (
    <button
      type="button"
      disabled={disabled}
      className={`bg-transparent p-2 d-flex justify-content-center align-items-center 
        ${selected ? 'test-button-filters-selected' : 'test-button-filters'}
      ${className}`}
      onClick={onClick}
      data-testid={text}
    >
      <span
        className={`font-montserrat test-text-filters  ${
          selected ? 'test-text-filters-selected' : 'test-text-filters'
        }`}
      >
        {text}
      </span>
    </button>
  );
}

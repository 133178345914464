import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Star from '../../assets/tests/Star';
import Clock from '../../assets/tests/Clock';
import Check from '../../assets/tests/Check';
import Checkbox from '../Checkbox/Checkbox';
import Ticket from '../Ticket/Ticket';
import { t } from '../../i18n/i18n';
import { formatMinutesToHours } from '../../utils/formatMinutesToHours';
import {
  addTestGroupFavorite,
  getTestGroup,
  removeTestGroupFavorite,
} from '../../API/services/screeningService';
import { setSubmissionSelected, setTestGroupsData } from '../../redux/actions/screeningActions';
import './TestItem.scss';
import { questionsAmount } from '../../utils/tests';
import DisabledFeatureMinTier from '../DisabledFeatureMinTier/DisabledFeatureMinTier';
import { determineDisabledMinimumTier } from '../../utils/determineDisabled';
import useTranslations from '../../hooks/useTranslations';
import { CategoryBadge } from '../TreeMultiSelect/CategoryBadge';
import { getCategoryNameTranslate } from '../../utils/translate';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
const TestItem = ({
  test,
  setShow,
  isTestsPage,
  isStar,
  isFavorite,
  testArray,
  isCheck = true,
  isSelectTests = false,
  handleTests,
  newScreening,
}) => {
  const { user } = useSelector((state) => state.auth);
  const { key } = useTranslations();
  const dispatch = useDispatch();
  const getTestGroupId = async (testGroupId) => {
    await getTestGroup(testGroupId).then((res) => {
      dispatch(
        setSubmissionSelected({ testGroup: res, submissions: res?.tests?.map((r) => r.test) })
      );
      setShow(true);
    });
  };

  const [isFav, setIsFav] = useState(isFavorite);
  const addFavorite = (testGroupId) => {
    addTestGroupFavorite({ testGroupId, userId: user.id }).then(() => {
      setIsFav(true);
      dispatch(
        setTestGroupsData({
          pagination: testArray?.pagination,
          collection: testArray?.collection?.map((test) => {
            if (test.id === testGroupId) {
              return { ...test, favorite: true };
            }
            return test;
          }),
        })
      );
    });
  };

  const removeFavorite = (testGroupId) => {
    removeTestGroupFavorite(testGroupId).then(() => {
      setIsFav(false);
    });
  };

  const normalizedDescription = (description = '') => {
    if (description?.length <= 185) {
      return description;
    }
    return `${description?.substring(0, 185)}...`;
  };

  return (
    <div className="position-relative">
      <DisabledFeatureMinTier
        className="position-relative flex-1 w-auto py-0 d-flex flex-column align-items-end "
        childrenClassname="position-relative w-100"
        iconClassName="w-min-content absolute-top-right position-absolute m-3"
        isDisabledFeature={() => determineDisabledMinimumTier(test?.minimumTier)}
        tooltipLabel={t('AVAILABLE_IN_HIGHER_PLANS_CONTACT_SALES_TEAM')}
      >
        <div className="d-flex flex-column test-item-container bg-white  w-100 pt-1">
          <div className="my-2 d-flex align-items-center justify-content-between test-item-header px-3 flex-shrink-0">
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center justify-content-center font-montserrat position-relative">
                {test?.category?.iconUrl && (
                  <img
                    src={test?.category?.iconUrl}
                    alt="category-icon"
                    className="category-icon pr-2"
                  />
                )}
                <span className="font-montserrat text-soft-maya-blue-dark font-weight-600 category-name">
                  {getCategoryNameTranslate(test?.category, key)}
                </span>
                {(test?.categories?.length ?? -1) > 0 && (
                  <div style={{ position: 'absolute', top: -18, left: -18 }}>
                    <CategoryBadge
                      label={`+${test?.categories?.length ?? -1}`}
                      categories={test?.categories}
                    />
                  </div>
                )}
              </div>
              {Boolean(test.tags?.length) && (
                <Ticket
                  className="ticket-test-level-text font-weight-bold text-nowrap font-roboto ml-2 text-uppercase text-xs"
                  text={test.tags[0]?.displayName}
                />
              )}
            </div>
            <div className="d-flex align-items-center">
              {isCheck && (
                <Checkbox
                  checked={
                    newScreening.testGroups.find((item) => item.id === test.id)?.isSelected ?? false
                  }
                  onClick={() => handleTests(test, isSelectTests)}
                  className={`test-checkbox align-self-start mt-1 ${
                    isTestsPage ? 'd-none' : 'd-flex align-items-center justify-content-between'
                  } ${isStar ? 'ml-2' : ''}`}
                />
              )}
            </div>
          </div>
          <div className="px-2 flex-shrink-1 flex-grow-1">
            <OverlayTrigger placement="top" overlay={<Tooltip>{test.name} </Tooltip>}>
              <p
                className="font-montserrat text-title font-weight-bold mr-2 test-title-container"
                data-testid="test-item-name-span"
              >
                {test.name}
              </p>
            </OverlayTrigger>
            <span
              className="mt-2 font-montserrat test-description-container overflow-hidden font-weight-500"
              data-testid="test-item-description-span"
            >
              <span className=" item-description mr-1">
                {normalizedDescription(
                  test?.languages?.length
                    ? test?.summary[key] ?? test?.summary[test?.languages[0]]
                    : test.description
                )}
              </span>
              <button
                className="text-decoration-none bg-transparent border-0"
                type="button"
                onClick={() => getTestGroupId(test?.id)}
                data-testid="test-item-see-more-tg-btn"
              >
                <span
                  className="font-montserrat text-xs text-soft-maya-blue-dark"
                  data-testid="test-item-see-more-tg-span"
                >
                  [{t('SEE_MORE')}]
                </span>
              </button>
            </span>
          </div>
          <div
            className={`mt-2 d-flex align-items-center justify-content-between test-item-footer px-3 flex-shrink-0 ${
              test.recommended && 'test-item-footer-recommended'
            }`}
          >
            <div className="d-flex align-items-center justify-content-center font-montserrat">
              <Check />
              <span className="font-montserrat footer-text ml-2">
                <strong>{questionsAmount(test)}</strong>
                {` ${t('TEST_ITEM_QUESTION')}${questionsAmount(test) > 1 ? 's' : ''}`}
              </span>
            </div>
            <div className="d-flex align-items-center justify-content-center font-montserrat">
              <Clock />
              <span
                data-testid="test-item-duration-span"
                className="font-montserrat footer-text ml-2"
              >
                <strong>{`${formatMinutesToHours(test.duration)}`}</strong>
              </span>
            </div>
            <button
              type="button"
              className="bg-transparent border-0"
              onClick={() => (!isFav ? addFavorite(test?.id) : removeFavorite(test?.id))}
              data-testid={`test-item-${!isFav ? 'add' : 'remove'}-favorite-btn`}
            >
              <Star selected={isStar && isFav} />
            </button>
          </div>
        </div>
      </DisabledFeatureMinTier>
    </div>
  );
};

export default TestItem;

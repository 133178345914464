export const formatMinutesToHours = (n, onlyNumbers = false) => {
  const hours = Math.trunc(n / 60)
    .toString()
    .padStart(2, '0');
  const minutes = Math.round(n % 60)
    .toString()
    .padStart(2, '0');
  if (onlyNumbers) {
    return `${hours}:${minutes}`;
  }
  return `${hours}:${minutes} hs`;
};

import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setNewScreeningData } from '../../redux/actions/screeningActions';
import BackgroundTop from '../../components/BackgroundTop/BackgroundTop';
import BlockRedirection from '../../components/BlockRedirection/BlockRedirection';
import CreateScreeningStep1 from '../../components/CreateScreeningStep1/CreateScreeningStep1';
import CreateScreeningStep2 from '../../components/CreateScreeningStep2/CreateScreeningStep2';
import CreateScreeningStep3 from '../../components/CreateScreeningStep3/CreateScreeningStep3';
import CreateScreeningStep4 from '../../components/CreateScreeningStep4/CreateScreeningStep4';
import { CREATE_SCREENING } from '../../constants/routes';
import useCreateScreening from '../../hooks/useCreateScreening';
import { t } from '../../i18n/i18n';
import './CreateScreening.scss';
import CreateScreeningStepSuccess from '../../components/CreateScreeningStepSuccess/CreateScreeningStepSuccess';
import { setKeepTestsFilters } from '../../redux/actions/uxActions';

// This file aparently was used before a change so it might not have real action

const CreateScreening = () => {
  const { step, preventReloadCondition, handleClearFilters } = useCreateScreening();
  const dispatch = useDispatch();
  const [loadingTests, setLoadingTests] = useState(true);
  useEffect(() => {
    dispatch(
      setNewScreeningData({
        step: 1,
        name: '',
        testGroups: [],
        customTestGroup: [],
        customTestGroupDuration: {
          hours: undefined,
          minutes: undefined,
          seconds: undefined,
        },
        customScreeningTGDuration: '00:00',
        availableWeight: 100,
        isEdit: false,
        currentTypeQuestion: 'CHECK',
        currentQuestionData: undefined,
        allTests: [],
      })
    );
    return () => {
      dispatch(
        setNewScreeningData({
          step: 1,
          name: '',
          testGroups: [],
          customTestGroup: [],
          customTestGroupDuration: {
            hours: undefined,
            minutes: undefined,
            seconds: undefined,
          },
          availableWeight: 100,
          isEdit: false,
          currentTypeQuestion: 'CHECK',
          currentQuestionData: undefined,
          allTests: [],
        })
      );
      handleClearFilters();

      dispatch(setKeepTestsFilters({}));
    };
  }, []);

  return (
    <div className="w-100 mx-auto h-100">
      <BlockRedirection
        conditionToBlock={preventReloadCondition && step <= 4}
        route={CREATE_SCREENING}
        message={t('CREATE_SCREENING_LEAVE_SECTION_ALERT')}
      >
        <div>
          <BackgroundTop
            height="5rem"
            title={t('CREATE_SCREENING_SECTION_TITLE')}
            className="position-relative"
            titleClassname="text-xl"
          />
        </div>
        <div className="px-2">
          <div className="create-screening-container w-100 bg-white position-relative mx-auto p-4 rounded d-flex flex-column">
            {step <= 4 && (
              <span className="text-sm font-montserrat text-muted">
                {t('PLACED_STEP').replace('{step}', step)}
              </span>
            )}
            {step === 1 && <CreateScreeningStep1 />}
            {step === 2 && (
              <CreateScreeningStep2 loadingTests={loadingTests} setLoadingTests={setLoadingTests} />
            )}
            {step === 3 && <CreateScreeningStep3 />}
            {step === 4 && <CreateScreeningStep4 />}
            {step === 5 && <CreateScreeningStepSuccess />}
          </div>
        </div>
      </BlockRedirection>
    </div>
  );
};

export default CreateScreening;

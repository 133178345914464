import { useEffect, useRef, useState } from 'react';
import { Modal } from 'antd';
import { t } from '../../i18n/i18n';
import useCreateScreening from '../../hooks/useCreateScreening';
import RowAnswer from '../RowAnswer/RowAnswer';
import parseClip from '../../utils/parseClip';
import GenericToggle from '../GenericToggle/GenericToggle';
import UploadTestFiles from '../UploadTestFiles/UploadTestFiles';
import { TestFileType } from '../../constants/enums';

const QuestionMultipleChoice = ({ testGroupABM, eachScore, setEachScore }) => {
  const {
    currentQuestionData,
    defaultCheckState,
    handleAddAnswers,
    handleAddQuestionText,
    resetCheckWeight,
    handleAddTestFile,
    handleRemoveTestFile,
    handleUpdateTestFile,
    handleAddAnswerFile,
    handleRemoveAnswerFile,
    handleUpdateAnswerFile,
  } = useCreateScreening();

  const textAreaRef = useRef();

  const [rowAnswer, setRowAnswer] = useState(defaultCheckState);
  const [rowAnswerCustom, setRowAnswerCustom] = useState([]);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    setRowAnswer(currentQuestionData?.options ?? defaultCheckState);
  }, [currentQuestionData?.options]);

  const handleChangeCustom = ({ target: { value } }) => {
    const newArray = [...rowAnswer?.filter((row) => !!row.text)];
    parseClip(value)?.map((parse) =>
      parse?.map((option) => newArray.push({ text: option, correct: false }))
    );
    setRowAnswerCustom(newArray);
  };

  const handleOnChange = (index, name, value) => {
    const copyRows = [...rowAnswer];
    copyRows[index] = {
      ...copyRows[index],
      [name]: value,
    };
    setRowAnswer(copyRows);
    handleAddAnswers(copyRows);
  };

  const handleAddAswer = () => {
    const emptyNewOption = {
      text: '',
      files: [],
      ...((testGroupABM && !eachScore) || !testGroupABM ? { correct: false } : {}),
    };
    setRowAnswer(rowAnswer.concat(emptyNewOption));
    handleAddAnswers(rowAnswer.concat(emptyNewOption));
  };

  const handleOnRemove = (index) => {
    const copyRows = [...rowAnswer];
    copyRows.splice(index, 1);
    handleAddAnswers(copyRows);
  };

  return (
    <div data-testid="question-multiple-choice-container">
      <Modal
        title={t('ADD_OPTIONS_TO_EXCEL')}
        open={modal}
        onOk={() => {
          setModal(false);
          setRowAnswer(rowAnswerCustom);
          handleAddAnswers(rowAnswerCustom);
          setRowAnswerCustom([]);
          textAreaRef.current.value = '';
        }}
        onCancel={() => {
          setModal(false);
          setRowAnswerCustom([]);
          textAreaRef.current.value = '';
        }}
        okText={t('CREATE_SEARCH_MODAL_BUTTON_ADD')}
        cancelText={t('CANCEL')}
      >
        <textarea
          ref={textAreaRef}
          rows="5"
          className="w-100 py-1 px-2"
          placeholder={t('PASTE_THE_ROWS_FROM_EXCEL')}
          onChange={handleChangeCustom}
          data-testid="question-mc-paste-from-excel-textarea"
        />
      </Modal>
      <textarea
        type="text"
        onChange={handleAddQuestionText}
        name="text"
        value={currentQuestionData?.text ?? ''}
        className={`w-100 mt-3 py-2 px-3 font-montserrat maya-blue-thin-border outline-none text-xs min-height-add-question ${
          currentQuestionData?.text?.length ? 'selected-border' : 'unselected-border'
        }`}
        rows="3"
        maxLength={5000}
        placeholder={t('CREATE_SCREENING_YOUR_QUESTIONS_MULTIPLE_CHOICE_PLACEHOLDER')}
        data-testid="question-mc-add-question-textarea"
      />
      {testGroupABM && (
        <>
          <UploadTestFiles
            fileTypes={[TestFileType.IMAGE, TestFileType.AUDIO]}
            handleAddFile={handleAddTestFile}
            handleRemoveFile={handleRemoveTestFile}
            handleUpdateFile={handleUpdateTestFile}
            testFiles={currentQuestionData?.testFiles ?? []}
            id="test"
          />
          <GenericToggle
            rightText={t('TOGGLE_EACH_SCORE')}
            defaultActive={eachScore}
            actionFunction={() => {
              resetCheckWeight();
              setEachScore(!eachScore);
            }}
          />
        </>
      )}
      {rowAnswer.map((row, index) => (
        <RowAnswer
          {...row}
          key={index}
          index={index}
          onChange={(name, value) => handleOnChange(index, name, value)}
          eachScore={eachScore}
          onRemove={() => handleOnRemove(index)}
          testGroupABM={testGroupABM}
          addFile={handleAddAnswerFile}
          removeFile={handleRemoveAnswerFile}
          updateFile={handleUpdateAnswerFile}
        />
      ))}
      <button
        type="button"
        className="font-montserrat bg-transparent border-0 text-xs text-maya-blue font-weight-600 mt-3"
        onClick={handleAddAswer}
        data-testid="question-mc-create-option-btn"
      >
        {t('CREATE_QUESTION_MULTIPLE_CHOICE_ADD_OPTION')}
      </button>
      <button
        type="button"
        className="font-montserrat bg-transparent border-0 text-xs text-maya-blue font-weight-600 mt-3 ml-3"
        onClick={() => setModal(true)}
        data-testid="question-mc-add-option-excel-btn"
      >
        {t('ADD_OPTIONS_TO_EXCEL')}
      </button>
    </div>
  );
};

export default QuestionMultipleChoice;

import React from 'react';

const Arrow = ({ className }) => (
  <svg
    className={className}
    width="22"
    height="12"
    viewBox="0 0 22 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1.13428L10.8299 10.0809L20.5146 1.00018"
      stroke="#41479B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Arrow;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import BackgroundTop from '../../components/BackgroundTop/BackgroundTop';
import { t } from '../../i18n/i18n';
import './CreateSearch.scss';
import useCreateSearch from '../../hooks/useCreateSearch';
import SearchStage from '../../components/SearchStage/SearchStage';
import {
  setNewSearchData,
  setScreenings,
  setSearchWasAlreadyCreated,
} from '../../redux/actions/screeningActions';
import { getScreenings } from '../../API/services/screeningService';
import ParagraphWithTitle from '../../components/ParagraphWithTitle/ParagraphWithTitle';

const CreateSearch = () => {
  const {
    customSearchData,
    fullData,
    handleBackStage,
    handleNextStage,
    selectedStage,
    setStageData,
    step,
    setSelectedStage,
    formattedScreenings,
    displayProctoringMeasuresScreen,
    setDisplayProctoringMeasuresScreen,
  } = useCreateSearch();
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector((state) => state?.auth);
  const { newSearch } = useSelector((state) => state.screenings);
  const [errorName, setErrorName] = useState(false);
  const isBack = history.action === 'PUSH';

  useEffect(() => {
    dispatch(setSearchWasAlreadyCreated(false));
  }, []);

  useEffect(() => {
    if (!formattedScreenings?.length) {
      getScreenings(user?.workspaces[0]?.workspaceId).then((screenings) => {
        dispatch(setScreenings(screenings));
      });
    }
    if (step === 4 || isBack) {
      dispatch(
        setNewSearchData({
          ...newSearch,
          name: '',
          stagesQuantity: 1,
          stages: [],
          step: 1,
        })
      );
      setSelectedStage(1);
    }
  }, []);

  const handleName = (e) => {
    if (e?.target?.value?.length > 50) {
      setErrorName(true);
    } else {
      setErrorName(false);
    }
    dispatch(
      setNewSearchData({
        ...customSearchData,
        name: e?.target?.value,
      })
    );
  };

  const proctoringMeasures = [
    {
      title: t('PROCTORING_MEASURES_WEBCAM_ENABLED_TITLE'),
      subtitle: t('PROCTORING_MEASURES_WEBCAM_ENABLED_SUBTITLE'),
    },
    {
      title: t('PROCTORING_MEASURES_FULLSCREEN_TITLE'),
      subtitle: t('PROCTORING_MEASURES_FULLSCREEN_SUBTITLE'),
    },
    {
      title: t('PROCTORING_MEASURES_MOUSE_ON_WINDOW_TITLE'),
      subtitle: t('PROCTORING_MEASURES_MOUSE_ON_WINDOW_SUBTITLE'),
    },
    {
      title: t('PROCTORING_MEASURES_COPY_PASTE_TITLE'),
      subtitle: t('PROCTORING_MEASURES_COPY_PASTE_SUBTITLE'),
    },
  ];

  return (
    <div className="w-100 mx-auto h-100">
      <div>
        <BackgroundTop
          title={
            displayProctoringMeasuresScreen
              ? t('PROCTORING_MEASURES_BANNER_TITLE')
              : t('CREATE_SEARCH_TITLE')
          }
          className="position-relative"
          titleClassname="text-xl"
        />
      </div>
      <div className="px-2">
        <div
          className={`position-relative description-container ${
            displayProctoringMeasuresScreen ? '' : 'new-search-container'
          }  p-4 bg-white rounded mx-auto mb-5`}
        >
          {displayProctoringMeasuresScreen ? (
            <>
              <div className="my-3 d-flex flex-column">
                <div className="d-flex align-items-center">
                  <p className="font-montserrat font-weight-600 line-height-20 letter-spacing-05">
                    {t('PROCTORING_MEASURES_DETAILS_TITLE')}
                  </p>
                </div>
                <p className="font-montserrat font-weight-500 line-height-17 letter-spacing-05 gray-subtitle text-sm">
                  {t('PROCTORING_MEASURES_DETAILS_SUBTITLE')}
                </p>
                {proctoringMeasures.map((item) => (
                  <ParagraphWithTitle
                    key={item.title}
                    titleText={item.title}
                    subtitleText={item.subtitle}
                    containerClassname="mt-2"
                  />
                ))}
              </div>
              <button
                className="mt-4 align-self-end px-5 py-2 rounded-pill font-montserrat text-xs text-white font-weight-bold border-0 outline-none bg-maya-blue"
                type="button"
                onClick={() => setDisplayProctoringMeasuresScreen(false)}
              >
                {t('PROCTORING_MEASURES_GO_BACK')}
              </button>
            </>
          ) : (
            <>
              <div className="d-flex justify-content-between align-items-start">
                {step <= 3 && (
                  <span className="text-sm font-montserrat text-muted">
                    {t('CREATE_SEARCH_PLACED_STEP').replace('{step}', step)}
                  </span>
                )}
              </div>
              <div className="my-3 d-flex flex-column">
                <div className="d-flex align-items-center mb-2">
                  <p className="font-montserrat font-weight-600 line-height-20 letter-spacing-05">
                    {t('CREATE_SEARCH_QUESTION')}
                  </p>
                </div>
                <input
                  className={`maya-blue-thin-border outline-none font-montserrat search-name-input text-sm w-60 ${
                    customSearchData?.name?.length ? 'selected-border' : 'unselected-border'
                  }`}
                  placeholder={t('CREATE_SEARCH_NAME_PLACEHOLDER_EXAMPLE')}
                  value={customSearchData?.name}
                  onChange={handleName}
                  type="text"
                />
                {errorName && (
                  <p className="danger-text font-montserrat text-sm m-0 mt-1 p-0">
                    {t('NAME_MUST_50_CHARACTERS')}
                  </p>
                )}
              </div>
              <div className="d-flex flex-column">
                <p className="font-montserrat font-weight-600 line-height-20 letter-spacing-05 mt-2">
                  {t('CREATE_SEARCH_NAME')}
                </p>
                <p className="font-montserrat font-weight-500 line-height-17 letter-spacing-05 mt-1 gray-subtitle text-sm mb-1">
                  {t('CREATE_SEARCH_NAME_SUBTITLE')}
                </p>
                {formattedScreenings.length === 0 && (
                  <p className="font-montserrat font-weight-500 line-height-17 letter-spacing-05 gray-subtitle text-sm my-1">
                    {t('CREATE_SEARCH_SUBTITLE_REMINDER')}
                  </p>
                )}
              </div>
              <SearchStage
                toggleDisplayProctoringMeasuresScreen={setDisplayProctoringMeasuresScreen}
                stage={selectedStage}
                lastStage={selectedStage === customSearchData.stagesQuantity}
                setStagesData={setStageData}
                stagesData={
                  customSearchData?.stages?.find((item) => item.stage === selectedStage) ?? {
                    stage: selectedStage,
                    startDate: {
                      hours: '00:00',
                    },
                    endDate: {
                      hours: '00:00',
                    },
                  }
                }
                searchData={customSearchData}
                backStage={handleBackStage}
                nextStage={handleNextStage}
                fullData={fullData}
                searchCompletedData={Boolean(customSearchData?.name && !errorName)}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateSearch;

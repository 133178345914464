import {
  GET_SCREENINGS,
  SET_SCREENINGS,
  SET_SCREENING_INVITATIONS,
  SET_SCREENING_INVITATIONS_FILTERS,
  SET_NEW_SEARCH_DATA,
  SET_CONFIRMED,
  SET_SELECTED,
  SET_SEARCHES,
  SET_TEMPLATE,
  SET_SELECTED_SEARCH,
  SET_STAGE_CANDIDATES,
  SET_EMAILS_DATA,
  SET_NEW_SCREENING_DATA,
  SET_NEW_SCREENING_FILTERS,
  SET_NEW_SCREENING_LEVELS_FILTERS_OPTIONS,
  SET_NEW_SCREENING_CATEGORIES_FILTERS_OPTIONS,
  SET_TEST_GROUPS_DATA,
  SET_SUBJECT_EMAIL,
  SET_SUBMISSIONS_SELECTED,
  SET_SEARCH_WAS_ALREADY_CREATED,
  SET_SEARCH_INVITATIONS_LEFT,
  SET_SEND_EMAIL_FROM,
  SET_CREATE_SCREENING_FROM_SEARCH,
  SET_AMOUNT_OF_FILTERS,
  SET_NEW_SCREENING_ROLES_FILTERS_OPTIONS,
  SET_KEEP_SEARCH_DATA,
  SET_INVITATION_ID_TO_SCROLL,
  SET_SCREENING_ID_TO_KEEP,
  SET_DISPLAY_TEST_GROUP,
  SET_CUSTOM_TESTGROUP_INCOMPLETE_QUESTION,
  SET_SEARCH_TABLE_PERSONALIZATION,
  SET_STAGE_SCREENING,
  SET_NEW_SCREENING_QUICK_ROLES_FILTERS_OPTIONS,
  SET_NEW_SCREENING_TAGS_FILTERS_OPTIONS,
  SET_ENDORSEMENT_CREATOR_OPTIONS,
  SET_ENDORSEMENT_COMPANY_OPTIONS,
} from '../actionTypes';

export const getScreenings = ({ nextUrl }) => ({
  type: GET_SCREENINGS,
  payload: { nextUrl },
});

export const setScreenings = (screenings) => ({
  type: SET_SCREENINGS,
  payload: { screenings },
});

export const setScreeningInvitations = (screeningsInvitations) => ({
  type: SET_SCREENING_INVITATIONS,
  payload: { screeningsInvitations },
});

export const setScreeningInvitationsFilters = (screeningInvitationsFilters) => ({
  type: SET_SCREENING_INVITATIONS_FILTERS,
  payload: { screeningInvitationsFilters },
});

export const setNewSearchData = (newSearch) => ({
  type: SET_NEW_SEARCH_DATA,
  payload: { newSearch },
});
export const setSelected = (selected) => ({
  type: SET_SELECTED,
  payload: { selected },
});
export const setConfirmed = (confirmed) => ({
  type: SET_CONFIRMED,
  payload: { confirmed },
});

export const setSearches = (searches) => ({
  type: SET_SEARCHES,
  payload: { searches },
});

export const setTemplate = (template) => ({
  type: SET_TEMPLATE,
  payload: { template },
});

export const setSubjectEmail = (subjectEmail) => ({
  type: SET_SUBJECT_EMAIL,
  payload: { subjectEmail },
});

export const setSelectedSearch = (selectedSearch) => ({
  type: SET_SELECTED_SEARCH,
  payload: { selectedSearch },
});

export const setSelectedSearchFilters = (selectedSearchFilters) => ({
  type: SET_SCREENING_INVITATIONS_FILTERS,
  payload: { selectedSearchFilters },
});

export const setStageCandidates = (stageCandidates) => ({
  type: SET_STAGE_CANDIDATES,
  payload: { stageCandidates },
});

export const setEmailsData = (emailsData) => ({
  type: SET_EMAILS_DATA,
  payload: { emailsData },
});

export const setNewScreeningData = (newScreening) => ({
  type: SET_NEW_SCREENING_DATA,
  payload: { newScreening },
});

export const setNewScreeningFilters = (newScreeningFilters) => ({
  type: SET_NEW_SCREENING_FILTERS,
  payload: { newScreeningFilters },
});

export const setNewScreeningLevelsFiltersOptions = (testGroupLevelsFilterOptions) => ({
  type: SET_NEW_SCREENING_LEVELS_FILTERS_OPTIONS,
  payload: { testGroupLevelsFilterOptions },
});

export const setNewScreeningCategoriesFiltersOptions = (testGroupCategoriesFilterOptions) => ({
  type: SET_NEW_SCREENING_CATEGORIES_FILTERS_OPTIONS,
  payload: { testGroupCategoriesFilterOptions },
});

export const setNewScreeningRolesFiltersOptions = (testGroupRolesFilterOptions) => ({
  type: SET_NEW_SCREENING_ROLES_FILTERS_OPTIONS,
  payload: { testGroupRolesFilterOptions },
});

export const setNewScreeningTagsFiltersOptions = (testGroupTagsFilterOptions) => ({
  type: SET_NEW_SCREENING_TAGS_FILTERS_OPTIONS,
  payload: { testGroupTagsFilterOptions },
});

export const setEndorsementCreatorsOptions = (endorsementCreatorsOptions) => ({
  type: SET_ENDORSEMENT_CREATOR_OPTIONS,
  payload: { endorsementCreatorsOptions },
});
export const setEndorsementCompaniesOptions = (endorsementCompaniesOptions) => ({
  type: SET_ENDORSEMENT_COMPANY_OPTIONS,
  payload: { endorsementCompaniesOptions },
});

export const setNewScreeningQuickRolesOptions = (testGroupQuickRolesOptions) => ({
  type: SET_NEW_SCREENING_QUICK_ROLES_FILTERS_OPTIONS,
  payload: { testGroupQuickRolesOptions },
});

export const setTestGroupsData = (testGroups) => ({
  type: SET_TEST_GROUPS_DATA,
  payload: { testGroups },
});

export const setSubmissionSelected = (submissionSelected) => ({
  type: SET_SUBMISSIONS_SELECTED,
  payload: { submissionSelected },
});

export const setSearchWasAlreadyCreated = (value) => ({
  type: SET_SEARCH_WAS_ALREADY_CREATED,
  payload: value,
});

export const setSearchInvitationsData = (value) => ({
  type: SET_SEARCH_INVITATIONS_LEFT,
  payload: value,
});

export const setSendEmailFrom = (value) => ({
  type: SET_SEND_EMAIL_FROM,
  payload: value,
});

export const setCreateScreeningFromSearches = (createScreeningFromSearches) => ({
  type: SET_CREATE_SCREENING_FROM_SEARCH,
  payload: { createScreeningFromSearches },
});

export const setAmountOfFilters = (amountOfFilters) => ({
  type: SET_AMOUNT_OF_FILTERS,
  payload: { amountOfFilters },
});

export const setKeepSearchData = (keepSearchData) => ({
  type: SET_KEEP_SEARCH_DATA,
  payload: { keepSearchData },
});

export const setInvitationIdToScroll = (invitationIdToScroll) => ({
  type: SET_INVITATION_ID_TO_SCROLL,
  payload: { invitationIdToScroll },
});

export const setScreeningIdToKeep = (screeningIdToKeep) => ({
  type: SET_SCREENING_ID_TO_KEEP,
  payload: { screeningIdToKeep },
});

export const setDisplayTestGroup = (displayTestgroups) => ({
  type: SET_DISPLAY_TEST_GROUP,
  payload: { displayTestgroups },
});

export const setCustomTestGroupIncompleteQuestion = (customTestGroupIncompleteQuestion) => ({
  type: SET_CUSTOM_TESTGROUP_INCOMPLETE_QUESTION,
  payload: { customTestGroupIncompleteQuestion },
});

export const setSearchTablePersonalization = (searchTablePersonalization) => ({
  type: SET_SEARCH_TABLE_PERSONALIZATION,
  payload: { searchTablePersonalization },
});

export const setStageScreening = (stageScreening) => ({
  type: SET_STAGE_SCREENING,
  payload: { stageScreening },
});

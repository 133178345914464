import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { notification, Select, Radio } from 'antd';
import { FaVideoSlash } from 'react-icons/fa';
import { t } from '../../i18n/i18n';
import Ticket from '../Ticket/Ticket';
import FailedIcon from '../../assets/modal/Failed';
import Arrow from '../../assets/modal/Arrow';
import CheckIcon from '../../assets/modal/Check';
import { formatMinutesSecondsToHours } from '../../utils/formatMinutesSecondToHours';
import timerIcon from '../../assets/profile/timer.svg';
import CloseIcon from '../../assets/modal/Close';
import CloudIcon from '../../assets/modal/Cloud';
import QuestionIcon from '../../assets/modal/QuestionIcon';
import MinusWarning from '../../assets/modal/MinusWarning';
import Accordion from '../Accordion/Accordion';
import OptionMultipleComponent from './OptionMultipleComponent/OptionMultipleComponent';
import { optionsMatch } from '../../utils/modal/optionsToMatch';
import OptionText from './OptionTextComponent/OptionText';
import TrainingSkills from '../TrainingSkills/TrainingSkills';
import { setSubmissionSelected } from '../../redux/actions/screeningActions';
import { AnswerTypeEnum, DifficultyTag } from '../../constants/enums';
import ModalTestFile from '../ModalTestFile/ModalTestFile';
import './modalSubmission.scss';
import OptionCode from './OptionCodeComponent/OptionCodeComponent';
import { convertSecondsToTimeFormat } from '../../utils/time';
import useTranslations from '../../hooks/useTranslations';
import { isNullOrUndefined } from '../../utils/typesUtils';
import CountryFlag from '../CountryFlag/CountryFlag';
import { languagesList } from '../DropdownLanguage/DropdownLanguage';
import InfoTooltip from '../InfoTooltip/InfoTooltip';
import { getGroupSliderTags } from '../../utils/scoreTags';

const ModalSubmission = ({
  show,
  setShow,
  showResult = false,
  isInvitedProfile,
  groupAcceleration,
  isTestgroupListDetail,
}) => {
  const [itemsToBeOpened, setItemsToBeOpened] = useState([]);
  const [submissionsPrint, setSubmissionsPrint] = useState([]);
  const [scoreTagsFormatted, setScoreTagsFormatted] = useState(null);
  const videoRefs = useRef([]);
  const { changeLanguageKey, key } = useTranslations();
  const { profile, alkymerAdvance } = useSelector((state) => state.profiles);
  const { currentWorkspace } = useSelector((state) => state.auth);
  const submission = useSelector((state) => state.screenings.submissionSelected);
  const { testGroup, submissions } = submission;
  const dispatch = useDispatch();

  const possibleResults = [
    {
      text: t('SUBMISSION_MODAL_CORRECT_ANSWER'),
      icon: <CheckIcon />,
    },
    {
      text: t('SUBMISSION_MODAL_PARTIAL_CORRECT_ANSWER'),
      icon: <MinusWarning />,
    },
    {
      text: t('SUBMISSION_MODAL_INCORRECT_ANSWER'),
      icon: <FailedIcon />,
    },
    {
      text: t('SUBMISSION_MODAL_WITHOUT_ANSWER'),
      icon: <QuestionIcon />,
    },
  ];

  useEffect(() => {
    if (show) {
      document.querySelector('body').classList.add('overflow-hidden');
    } else {
      document.querySelector('body').classList.remove('overflow-hidden');
    }
  }, [show]);
  useEffect(() => {
    if (testGroup?.scoreTags) {
      const scoreTags = getGroupSliderTags(testGroup?.scoreTags?.tags);
      setScoreTagsFormatted(scoreTags);
    }
  }, [testGroup]);

  const submittedZeroPoints = (acceptedOptions, submittedOptions) => {
    const weightZero = acceptedOptions
      ?.filter((acceptedOption) => acceptedOption.weight === 0)
      ?.map((acceptedOption) => acceptedOption.options)
      ?.flat();

    return weightZero.includes(submittedOptions[0]);
  };

  useEffect(() => {
    if (show) {
      const submissionsModify = [];

      const maxScores = submissions.map((submission) => submission?.maxScore);
      const maxScore = Math.max(...maxScores);

      submissions.sort((a, b) => {
        const dateA = new Date(a?.submittedAt);
        const dateB = new Date(b?.submittedAt);

        const isSubmissionA = a.isSubmission !== undefined ? a.isSubmission : false;
        const isSubmissionB = b.isSubmission !== undefined ? b.isSubmission : false;

        if (!isSubmissionA && isSubmissionB) {
          return 1;
        }
        if (isSubmissionA && !isSubmissionB) {
          return -1;
        }

        if (dateA < dateB) {
          return -1;
        }
        if (dateA > dateB) {
          return 1;
        }
        return 0;
      });

      submissions?.forEach((submission, index) => {
        let difficultyTag = '';
        if (!testGroup?.isComplementary) {
          if (Number(currentWorkspace) === 895 && maxScore === 2) {
            // Logica Despegar para Pseudo y ED de Tags
            switch (submission?.maxScore) {
              case 1:
                difficultyTag = DifficultyTag.EASY;
                break;
              case 2:
                difficultyTag = DifficultyTag.INTERMEDIATE;
                break;
              default:
                difficultyTag = '';
            }
          } else if (Number(currentWorkspace) === 895 && maxScore === 5) {
            // Logica Despegar para POO de Tags
            if (index <= 9) {
              difficultyTag = DifficultyTag.EASY;
            } else if (index <= 16) {
              difficultyTag = DifficultyTag.INTERMEDIATE;
            } else {
              difficultyTag = DifficultyTag.HARD;
            }
          } else if (Number(currentWorkspace) === 895 && maxScore === 6) {
            // Logica Despegar para LP de Tags
            if (index <= 8) {
              difficultyTag = DifficultyTag.EASY;
            } else if (index <= 15) {
              difficultyTag = DifficultyTag.INTERMEDIATE;
            } else {
              difficultyTag = DifficultyTag.HARD;
            }
          } else {
            switch (submission?.maxScore) {
              case 1:
                difficultyTag = DifficultyTag.VERY_EASY;
                break;
              case 2:
                difficultyTag = DifficultyTag.EASY;
                break;
              case 3:
                difficultyTag = DifficultyTag.INTERMEDIATE;
                break;
              case 4:
                difficultyTag = DifficultyTag.HARD;
                break;
              case 5:
                difficultyTag = DifficultyTag.VERY_HARD;
                break;
              case 6:
                difficultyTag = DifficultyTag.ADVANCED;
                break;
              default:
                difficultyTag = '';
            }
          }
        }
        switch (submission?.answerType) {
          case 'CHECK':
          case 'RADIO':
            submissionsModify.push({
              ...submission,
              titleCollapsed:
                submission.type === 'TECHNICAL'
                  ? t('MODAL_QUESTION_OPTION_CODE')
                  : t('MODAL_QUESTION_OPTION_MULTIPLE'),
              componentResult: (
                <OptionMultipleComponent
                  submission={submission}
                  showResult={showResult}
                  isTestgroupListDetail={isTestgroupListDetail}
                />
              ),
              iconResult:
                !isTestgroupListDetail &&
                (!submission?.isSubmission ||
                !submission.submittedOptions.length ||
                (submission?.answerType === AnswerTypeEnum.RADIO &&
                  !submission?.hasGlobalScore &&
                  submittedZeroPoints(
                    submission?.acceptedOptions ?? [],
                    submission?.submittedOptions ?? []
                  )) ? (
                  <QuestionIcon />
                ) : (
                  submission?.acceptedOptions.length >= 1 &&
                  (optionsMatch(
                    submission?.acceptedOptions.filter((x) => x.weight > 0) ?? [],
                    submission?.submittedOptions
                  ) ? (
                    optionsMatch(
                      [submission?.acceptedOptions.sort((a, b) => b.weight - a.weight)[0]],
                      submission?.submittedOptions
                    ) ? (
                      <CheckIcon />
                    ) : (
                      <MinusWarning />
                    )
                  ) : (
                    <FailedIcon />
                  ))
                )),
              difficultTag: difficultyTag,
            });
            break;
          case 'FILE':
            submissionsModify.push({
              ...submission,
              titleCollapsed: t('MODAL_QUESTION_FILE_ATTACH'),
              ...(showResult &&
                submission?.submittedFile && {
                  componentResult: (
                    <>
                      <span className="font-montserrat font-weight-600 text-xs text-color-item-test-group mt-2">
                        {t('MODAL_ANSWER')}
                      </span>
                      <a
                        href={submission?.submittedFile}
                        target="_blank"
                        rel="noreferrer"
                        className="font-montserrat text-xs download-cv-text mx-3 mt-3"
                      >
                        {t('MODAL_FILE_ATTACH')}
                      </a>
                    </>
                  ),
                }),
              iconResult: submission?.submittedFile ? (
                <a href={submission?.submittedFile} target="_blank" rel="noreferrer">
                  <CloudIcon />
                </a>
              ) : (
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{t('MODAL_FILE_NOT_FOUND')}</Tooltip>}
                >
                  <Button variant="bg-transparent p-0 m-0">
                    <CloudIcon />
                  </Button>
                </OverlayTrigger>
              ),
            });
            break;
          case 'VIDEO':
            submissionsModify.push({
              ...submission,
              titleCollapsed: t('CREATE_SCREENING_YOUR_QUESTIONS_VIDEO'),
              ...(showResult && {
                componentResult: (
                  <>
                    {submission?.submittedVideo ? (
                      <>
                        <div className="d-flex flex-column mt-2 ">
                          <div className="d-flex flex-row">
                            <span className="font-montserrat text-sm font-weight-500 color-count-questions">
                              {t('CREATE_SCREENING_YOUR_QUESTIONS_VIDEO_TOTAL_RECORD_TIME')}
                              :&nbsp;
                            </span>
                            <span className="font-montserrat text-sm font-weight-600 text-blue-modal">
                              {convertSecondsToTimeFormat(submission?.totalTime)}
                            </span>
                          </div>
                          <div className="d-flex flex-row">
                            <span className="font-montserrat text-sm font-weight-500 color-count-questions">
                              {t('CREATE_SCREENING_YOUR_QUESTIONS_VIDEO_PREPARATION_TIME')}:&nbsp;
                            </span>
                            <span className="font-montserrat text-sm font-weight-600 text-blue-modal">
                              {convertSecondsToTimeFormat(submission?.preparationTime)}
                            </span>
                          </div>
                          <div className="d-flex flex-row">
                            <span className="font-montserrat text-sm font-weight-500 color-count-questions">
                              {t('MODAL_YOUR_QUESTIONS_VIDEO_MAX_ATTEMPTS')}:&nbsp;
                            </span>
                            <span className="font-montserrat text-sm font-weight-600 text-blue-modal">
                              {submission?.maxAttempts}
                            </span>
                          </div>
                        </div>
                        <span className="font-montserrat font-weight-600 text-xs text-color-item-test-group mt-2">
                          {t('MODAL_ANSWER')}
                        </span>
                        <video
                          ref={(ref) => (videoRefs.current[index] = ref)}
                          id="submittedVideo"
                          width="640"
                          height="360"
                          controls
                          src={submission?.submittedVideo}
                          preload="auto"
                        />
                      </>
                    ) : (
                      <>
                        <span className="font-montserrat font-weight-600 text-xs text-color-item-test-group mt-2">
                          {t('EMPTY_CODE_RESPONSE')}
                        </span>
                      </>
                    )}
                  </>
                ),
              }),
              iconResult: submission?.submittedVideo ? (
                <a href={submission?.submittedVideo} target="_blank" rel="noreferrer">
                  <CloudIcon />
                </a>
              ) : (
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{t('MODAL_FILE_NOT_FOUND')}</Tooltip>}
                >
                  <Button variant="bg-transparent p-0 m-0">
                    <FaVideoSlash color="rgb(220, 37, 28)" size="24" />
                  </Button>
                </OverlayTrigger>
              ),
            });
            break;
          case 'TEXT_AI':
            submissionsModify.push({
              ...submission,
              titleCollapsed: t('MODAL_QUESTION_OPEN_IA'),
              componentResult: (
                <div className="d-flex flex-column">
                  <div className="d-flex flex-column my-3">
                    <span className="text-xs text-muted font-weight-bold font-montserrat mb-1">
                      {t('MODAL_QUESTION_OPEN_IA_CONCEPTS_TITLE')}
                    </span>
                    <span className="text-xs font-montserrat">{submission?.concepts}</span>
                  </div>
                  {!isTestgroupListDetail && (
                    <>
                      <OptionText submission={submission} showResult={showResult} />
                      <div className="d-flex mt-4">
                        <div className="d-flex flex-column align-items-center">
                          <span className="text-lg text-muted font-weight-bold font-montserrat mb-1">
                            {t('MODAL_QUESTION_OPEN_IA_SCORE')}
                          </span>
                          <span className="text-lg text-muted font-weight-600 font-montserrat">
                            {submission?.ratio * 100}%
                          </span>
                        </div>
                        <div className="d-flex pl-3 ml-5 flex-column align-items-start">
                          <span className="text-lg text-muted font-weight-bold font-montserrat mb-1">
                            {t('MODAL_QUESTION_OPEN_IA_DETAILS_TITLE')}
                          </span>
                          <span className="text-sm font-montserrat">{submission?.explanation}</span>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              ),
            });
            break;
          case 'WRITE':
            submissionsModify.push({
              ...submission,
              titleCollapsed: submission?.isTyping
                ? t('MODAL_QUESTION_TYPING')
                : t('MODAL_QUESTION_WRITE'),
              iconResult:
                !isTestgroupListDetail &&
                (!submission?.isSubmission ||
                  (submission?.answerType === AnswerTypeEnum.WRITE &&
                    !submission?.submittedText)) ? (
                  <QuestionIcon />
                ) : Number(submission?.ratio) > 0 ? (
                  Number(submission?.ratio) === 1 ? (
                    <CheckIcon />
                  ) : (
                    <MinusWarning />
                  )
                ) : (
                  <FailedIcon />
                ),
              difficultTag: difficultyTag,
              componentResult: (
                <div className="d-flex flex-column">
                  {!isTestgroupListDetail && (
                    <>
                      <OptionText submission={submission} showResult={showResult} />
                      <div className="d-flex mt-4">
                        <div className="d-flex flex-column align-items-center">
                          <span className="text-medium text-muted font-weight-bold font-montserrat mb-1">
                            {t('MODAL_QUESTION_OPEN_IA_SCORE')}
                          </span>
                          <span className="text-medium text-muted font-weight-600 font-montserrat">
                            {Math.round(submission?.ratio * 100)}%
                          </span>
                        </div>
                        <div className="d-flex pl-3 ml-5 flex-column align-items-start">
                          <span className="text-medium text-muted font-weight-bold font-montserrat mb-1">
                            {submission?.isTyping
                              ? t('MODAL_QUESTION_DETAIL_WRITE_TYPING')
                              : t('MODAL_QUESTION_DETAIL_WRITE')}
                          </span>
                          <span className="text-sm font-montserrat">
                            {submission?.writeResponse}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center text-xs italic font-montserrat mt-3">
                        {t('ADD_WRITE_QUESTION_NOTES')}
                        <InfoTooltip
                          placement="top"
                          content={
                            <div className="d-flex flex-column py-2">
                              <p className="text-xs font-montserrat  mb-2">
                                {submission?.isTyping
                                  ? t('ADD_WRITE_QUESTION_TOOLTIP_CORRECTION_TYPING')
                                  : t('ADD_WRITE_QUESTION_TOOLTIP_CORRECTION')}
                              </p>
                            </div>
                          }
                        />
                      </div>
                    </>
                  )}
                </div>
              ),
            });
            break;
          case 'CODE':
            submissionsModify.push({
              ...submission,
              titleCollapsed: t('MODAL_TECHNICAL_EXERCISE'),
              iconResult: showResult ? (
                submission?.submittedCode?.length >= 1 && submission.ratio > 0 ? (
                  <CheckIcon />
                ) : (
                  <FailedIcon />
                )
              ) : null,
              componentResult: showResult ? (
                <OptionCode submission={submission} showResult={showResult} />
              ) : null,
            });
            break;
          default:
            submissionsModify.push({
              ...submission,
              titleCollapsed:
                submission?.type !== 'TECHNICAL'
                  ? t('MODAL_QUESTION_OPEN')
                  : t('MODAL_TECHNICAL_EXERCISE'),
              componentResult: showResult ? (
                <OptionText submission={submission} showResult={showResult} />
              ) : null,
            });
            break;
        }
      });
      submissionsModify.sort((a, b) => {
        const dateA = new Date(a?.submittedAt);
        const dateB = new Date(b?.submittedAt);

        const isSubmissionA = a.isSubmission !== undefined ? a.isSubmission : false;
        const isSubmissionB = b.isSubmission !== undefined ? b.isSubmission : false;

        if (!isSubmissionA && isSubmissionB) {
          return 1;
        }
        if (isSubmissionA && !isSubmissionB) {
          return -1;
        }

        if (dateA < dateB) {
          return -1;
        }
        if (dateA > dateB) {
          return 1;
        }
        return 0;
      });
      setSubmissionsPrint(submissionsModify);
    }
  }, [show, submissions]);

  useEffect(() => {
    if (!show) {
      setTimeout(() => {
        dispatch(setSubmissionSelected({}));
      }, 750);
    }
  }, [show]);

  useEffect(() => {
    if (submissionsPrint?.length) {
      const indexToOpen = [];
      submissionsPrint?.forEach((submission, index) => {
        switch (submission?.answerType) {
          case 'CHECK':
          case 'RADIO':
            break;
          case 'FILE':
            indexToOpen.push(index);
            break;
          case 'VIDEO':
            indexToOpen.push(index);
            break;
          case 'TEXT_AI':
            break;
          case 'CODE':
            break;
          case 'WRITE':
            break;
          default:
            indexToOpen.push(index);
            break;
        }
      });
      setItemsToBeOpened(indexToOpen);
    }
  }, [submissionsPrint]);

  return (
    <div
      className={`modal modal_submission d-flex justify-content-center position-fixed my-0 ${
        show ? 'visible_submission' : ''
      }`}
    >
      <div className="modal__wrap_submission d-flex flex-column container-modal-screening w-100 bg-white">
        <div
          aria-hidden="true"
          onClick={() => {
            setShow(false);
            setItemsToBeOpened([]);
          }}
        >
          <CloseIcon className="position-absolute right-0 top-0 mt-3 mr-4 cursor-pointer button-close-modal font-weight-bold" />
        </div>
        <div className="d-flex align-items-center">
          <span className="font-montserrat text-base font-weight-600">
            {testGroup?.name || groupAcceleration?.technology?.displayName}
          </span>
          <div className="d-flex flex-column flex-md-row">
            {testGroup?.category?.name && isInvitedProfile && (
              <Ticket
                text={testGroup?.category?.name}
                className="ticket-technology-fullstack ticket-padding font-weight-bold font-roboto px-3 text-category-responsive mr-auto mr-md-2 ml-3"
              />
            )}
            {isInvitedProfile &&
              !!testGroup?.tags?.filter((tag) => tag?.type === 'LEVEL')?.length && (
                <Ticket
                  text={testGroup?.tags?.filter((tag) => tag?.type === 'LEVEL')[0]?.displayName}
                  className="ticket-technology-frontend ticket-padding font-weight-bold font-roboto text-uppercase px-3 mr-auto mr-md-0 mt-2 mt-md-0 ml-2"
                />
              )}
          </div>
        </div>

        {testGroup?.languages?.length ? (
          <>
            <div className="mt-4">
              <span className="font-montserrat text-base font-weight-600 color-title-test my-2 mr-2">
                {t('MODAL_SUBMISSION_TESTGROUP_LANGUAGES_LABEL')}
              </span>
              <Radio.Group value={null}>
                {testGroup?.languages?.map((lang) => (
                  <Radio.Button value={lang} disabled style={{ cursor: 'default' }}>
                    <CountryFlag
                      countryIso={languagesList?.find((y) => y.value == lang)?.iso}
                      size="sm"
                    />
                  </Radio.Button>
                ))}
              </Radio.Group>
            </div>
            {testGroup?.objetives && (
              <div className="mt-4">
                <span className="font-montserrat text-base font-weight-600 color-title-test my-2">
                  {t('MODAL_SUBMISSION_TESTGROUP_OBJETIVES_LABEL')}
                </span>
                <div className="d-flex flex-column flex-md-row my-2">
                  {testGroup?.objetives[key]?.length
                    ? testGroup?.objetives[key]?.map((obj) => (
                        <Ticket
                          text={obj}
                          className="ticket-technology-frontend ticket-padding font-weight-bold font-roboto text-uppercase px-3 mr-auto mr-md-0 mt-2 mt-md-0 ml-2"
                        />
                      ))
                    : testGroup.objetives[testGroup?.languages[0]].map((obj) => (
                        <Ticket
                          text={obj}
                          className="ticket-technology-frontend ticket-padding font-weight-bold font-roboto text-uppercase px-3 mr-auto mr-md-0 mt-2 mt-md-0 ml-2"
                        />
                      ))}
                </div>
              </div>
            )}
            {testGroup?.summary && (
              <div className="d-flex flex-column mt-4">
                <span className="font-montserrat text-base font-weight-600 color-title-test">
                  {t('MODAL_RESUME_OF_TEST')}
                </span>
                <span className="font-montserrat text-sm font-weight-500 color-title-test mt-2">
                  <pre className="tg-description font-montserrat text-sm font-weight-500 color-title-test mt-2 pr-2">
                    {testGroup?.summary[key] ?? testGroup?.summary[testGroup?.languages[0]]}
                  </pre>
                </span>
              </div>
            )}
            {testGroup?.detail && (
              <div className="d-flex flex-column mt-4">
                <span className="font-montserrat text-base font-weight-600 color-title-test">
                  {t('MODAL_TESTGROUP_DETAIL')}
                </span>
                <span className="font-montserrat text-sm font-weight-500 color-title-test mt-2">
                  <pre className="tg-description font-montserrat text-sm font-weight-500 color-title-test mt-2 pr-2">
                    {testGroup?.detail[key] ?? testGroup?.detail[testGroup?.languages[0]]}
                  </pre>
                </span>
              </div>
            )}
          </>
        ) : (
          (testGroup?.description ||
            testGroup?.categoryId === process.env.RAZZLE_RUNTIME_CATEGORY_ID) && (
            <div className="d-flex flex-column mt-4">
              <span className="font-montserrat text-base font-weight-600 color-title-test">
                {t('MODAL_RESUME_OF_TEST')}
              </span>
              <span className="font-montserrat text-sm font-weight-500 color-title-test mt-2">
                {testGroup?.description ? (
                  <pre className="tg-description font-montserrat text-sm font-weight-500 color-title-test mt-2 pr-2">
                    {testGroup?.description}
                  </pre>
                ) : testGroup?.categoryId === process.env.RAZZLE_RUNTIME_CATEGORY_ID ? (
                  t('MODAL_EMPTY_DESCRIPTION_WITH_CATEGORY')
                ) : null}
              </span>
            </div>
          )
        )}

        {testGroup?.scoreTags?.tags && (
          <div className="d-flex flex-column mt-4">
            <span className="font-montserrat text-base font-weight-600 color-title-test">
              {t('MODAL_SCORE_TAGS')}
            </span>
            <div className="d-flex flex-column mt-2">
              {scoreTagsFormatted?.ranges?.map((range, index) => {
                const [min, max] = range;
                return (
                  <div className="mt-2">
                    <span className="font-montserrat text-sm font-weight-600 color-title-test ">
                      {min} - {max}:
                    </span>
                    <span className="font-montserrat text-sm font-weight-500 color-title-test mx-2">
                      {scoreTagsFormatted?.descriptions[index]}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {testGroup?.testFormats?.length && (
          <div className="d-flex flex-column my-4">
            <span className="font-montserrat text-base font-weight-600 color-title-test my-2">
              {t('MODAL_TEST_FORMATS')}
            </span>
            <div className="d-flex flex-wrap  my-2">
              {testGroup?.testFormats?.map((format, index) => (
                <span className="ticket-technology-frontend ticket-padding font-weight-bold font-roboto text-uppercase px-3 mr-auto mr-md-0 mt-2 mt-md-0 ml-2 my-4 mx-2">
                  <img
                    src={format?.iconUrl}
                    alt="Format test logo"
                    className="text-maya-blue mr-2"
                    style={{ width: '1rem', height: '1rem' }}
                  />
                  {t(format?.label)}
                </span>
              ))}
            </div>
          </div>
        )}
        <div className="d-flex mt-2">
          {(isInvitedProfile || submissions?.length) && (
            <div className="d-flex flex-row">
              <span className="font-montserrat text-sm font-weight-500 color-count-questions">
                {t('MODAL_DURATION_TEXT')}:&nbsp;
              </span>
              <span className="font-montserrat text-sm font-weight-600 text-blue-modal">
                {formatMinutesSecondsToHours(testGroup?.duration)}
              </span>
            </div>
          )}
          {(isInvitedProfile || submissions?.length) && (
            <div
              className={`d-flex flex-row ${isInvitedProfile || submissions?.length ? 'ml-4' : ''}`}
            >
              <span className="font-montserrat text-sm font-weight-500 color-count-questions">
                {t('MODAL_COUNT_ANSWERS')}&nbsp;
              </span>
              <span className="font-montserrat text-sm font-weight-600 text-blue-modal">
                {submissions?.length || ''}
              </span>
            </div>
          )}

          {!isNullOrUndefined(testGroup?.minimumTier) && (
            <div className="d-flex flex-row ml-4">
              <span className="font-montserrat text-sm font-weight-500 color-count-questions">
                {t('MODAL_MINIMUM_TIER_LABEL')}&nbsp;
              </span>
              <span className="font-montserrat text-sm font-weight-600 text-blue-modal">
                {testGroup?.minimumTier}
              </span>
            </div>
          )}
        </div>
        {!isTestgroupListDetail && (isInvitedProfile || submissions?.length) && (
          <div className="d-flex my-3">
            {possibleResults.map((result) => (
              <div key={result.text} className="d-flex flex-row pr-4">
                {result.icon}
                <span className="pl-2 font-weight-500 font-montserrat color-title-test">
                  {result.text}
                </span>
              </div>
            ))}
          </div>
        )}
        {isTestgroupListDetail && (
          <>
            {submission?.testGroup?.difficultyConfig && (
              <div className="disclaimer-yellow-bg b-radius-8">
                <span className="font-montserrat text-sm font-weight-500">
                  {t('TEST_WERE_RANDOMIZED_DISCLAIMER')}
                </span>
              </div>
            )}
          </>
        )}
        <div className={`d-flex flex-column w-full  mt-1 ${isInvitedProfile ? 'mb-1' : 'my-2'}`}>
          <div className={`d-flex flex-column w-full  ${isInvitedProfile ? 'my-1' : 'bg-white'}`}>
            {isInvitedProfile || submissions?.length ? (
              submissionsPrint?.map((item, index) => (
                <div key={index} className="mb-2" aria-hidden="true">
                  <div
                    className="d-flex flex-row justify-content-between align-items-center border-item-test-group p-3 position-relative"
                    onClick={() =>
                      itemsToBeOpened.includes(index)
                        ? setItemsToBeOpened(itemsToBeOpened.filter((i) => i !== index))
                        : setItemsToBeOpened([...itemsToBeOpened, index])
                    }
                    aria-hidden="true"
                  >
                    <span
                      className={`position-absolute ticket-position ticket-color-${item?.difficultTag} ticket-difficult font-weight-bold font-roboto px-1 mr-auto mr-md-2 ml-3`}
                    >
                      {t(item?.difficultTag)}
                    </span>
                    <div className="d-block font-montserrat font-weight-600 text-sm text-color-item-test-group text-truncate media-body">
                      {itemsToBeOpened.includes(index)
                        ? item?.titleCollapsed
                        : item?.text
                            ?.replace(/<[^>]+>/g, '')
                            .replace(new RegExp('&lt;', 'g'), '<')
                            .replace(new RegExp('&gt;', 'g'), '>')}
                    </div>
                    <div className="d-flex align-items-center">
                      {item.answerType === 'TEXT_AI' && !isTestgroupListDetail && (
                        <span
                          data-testid="text-ia-percentage"
                          className="font-montserrat font-weight-bold text-muted mr-2"
                        >
                          {item.ratio * 100}%
                        </span>
                      )}
                      <Arrow
                        className={`cursor-pointer ${
                          item?.iconResult && showResult ? 'mr-3' : ''
                        } ${
                          itemsToBeOpened.includes(index)
                            ? 'arrow-dropdown-modal-collapsed'
                            : 'arrow-dropdown-modal-uncollapsed'
                        }`}
                      />
                      {showResult && !itemsToBeOpened.includes(index) && item?.iconResult}
                    </div>
                  </div>
                  <Accordion open={itemsToBeOpened.includes(index)}>
                    <div className="d-flex flex-row justify-content-between border-item-test-group-collapsed p-4 mb-2">
                      <div className="d-flex flex-column w-100">
                        <span
                          className="font-montserrat font-weight-500 text-sm mb-2 text-color-collapsed-modal"
                          dangerouslySetInnerHTML={{ __html: item?.text }}
                        />
                        {Boolean(item?.files?.length) &&
                          item?.files.map((file) => (
                            <ModalTestFile
                              file={file}
                              open={itemsToBeOpened.includes(index)}
                              key={file.id}
                            />
                          ))}
                        {item?.componentResult}
                      </div>
                    </div>
                  </Accordion>
                </div>
              ))
            ) : (
              <div className="bg-white description-container rounded mx-auto position-relative p-4">
                <div className="border-bottom mb-4">
                  <h3 className="font-montserrat font-weight-bold text-xl text-capitalize">
                    {profile?.firstName} {profile?.lastName}
                  </h3>
                </div>
                {alkymerAdvance?.error ? (
                  <div className="bg-gray d-flex align-items-center w-100 py-3 px-2 rounded font-montserrat font-weight-bold text-sm my-3">
                    <img src={timerIcon} alt={t('ICON_TIMER')} className="mr-2" />
                    {t('SKILLS_WAITING_ACCELERATION')}
                  </div>
                ) : (
                  <div className="w-100 d-flex flex-column flex-md-row">
                    <div className="results-left-column">
                      <TrainingSkills
                        className="px-4 pb-4"
                        skills={groupAcceleration?.skills ?? []}
                        waiting={false}
                      />
                    </div>
                    <div className="results-right-column d-flex flex-column pl-3">
                      <div className="d-flex flex-column my-2">
                        <span className="text-sm text-muted font-weight-bold font-montserrat">
                          {t('RESULTS_PAGE_RESULT')}
                        </span>
                        <span className="text-muted font-montserrat">
                          <span className="font-weight-bold text-dark percent-result-text font-montserrat name-text mr-2">
                            {Math.round(groupAcceleration?.bestScore)}
                          </span>
                          {t('RESULTS_PAGE_POINTS_OF').replace('{points}', '100')}
                        </span>
                      </div>
                      <div className="d-flex flex-column my-2">
                        <span className="text-sm text-muted font-weight-bold font-montserrat">
                          {t('RESULTS_PAGE_TECHNOLOGY')}
                        </span>
                        <span className="font-weight-bold text-dark font-montserrat name-text">
                          {groupAcceleration?.technology?.displayName}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          <button
            data-testid="MODAL_SUBMISSION_CLOSE_BUTTON"
            className="button-close-modal-submission mt-3 px-4 py-2 mr-auto border-0 text-decoration-none d-flex align-items-center"
            onClick={() => {
              setShow(false);
              setItemsToBeOpened([]);
            }}
            type="button"
          >
            <span className="font-montserrat text-xs font-weight-700 text-white">
              {t('MODAL_CLOSE')}
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalSubmission;

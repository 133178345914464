import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import './cardScreening.scss';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ShareIcon from '../../assets/screeningSearchs/ShareIcon';
import ExamIcon from '../../assets/screeningSearchs/ExamIcon';
import Progress from '../Progress/Progress';
import { t } from '../../i18n/i18n';
import CopyCheck from '../../assets/search/Check';
import AddInvitations from '../../assets/search/AddInvitations';
import CopyLink from '../../assets/search/Link';
import {
  setEmailsData,
  setNewSearchData,
  setSearchWasAlreadyCreated,
  setSelectedSearch,
  setSendEmailFrom,
  setSubjectEmail,
} from '../../redux/actions/screeningActions';
import DeleteForever from '../../assets/screening/DeleteForever';
import DuplicateEvaluation from '../DuplicateEvaluation/DuplicateEvaluation';
import useModalDetails from '../../hooks/useModalDetails';
import Modal from '../Modal/Modal';
import ArrowInvite from '../../assets/screeningSearchs/ArrowInvite';
import ModalDeleteDraft from './ModalDeleteDraft';
import VerticalDotsDropdown from '../VerticalDotsDropdown/VerticalDotsDropdown';
import DeleteIcon from '../../assets/profile/Delete';
import EditPencilIcon from '../../assets/common/EditPencilIcon';
import People from '../../assets/screeningSearchs/People';
import StatsIcon from '../../assets/screeningSearchs/StatsIcon';
import useSearchDashboard from '../../hooks/useSearchDashboard';
import CalendarClose from '../../assets/screeningSearchs/CalendarClose';
import useClickOutside from '../../hooks/useClickOutside';
import ArchiveBoxIcon from '../../assets/screeningSearchs/ArchiveBoxIcon';
import {
  getCustomSearch,
  getStageScreening,
  updateProctoringEnabledRecruitSessionStage,
} from '../../API/services/screeningService';
import { disableForWorkspaceRole } from '../../utils/workspaceRoleValidation';
import { reOpenEvaluation } from '../../API/services/companyService';
import { determineDisabledFeature } from '../../utils/determineDisabled';
import { PartnerWorkspaceRole, PlanFeaturesIds } from '../../constants/enums';
import CameraProctoringEnabled from '../../assets/screeningSearchs/CameraProctoringEnabled';
import CameraProctoringDisabled from '../../assets/screeningSearchs/CameraProctoringDisabled';
import { ModalProctoringEnabled } from './ModalProctoringEnabled';
import InfoIcon from '../../assets/search/InfoIcon';
import MoveScreeningIcon from '../../assets/screeningSearchs/MoveScreeningIcon';
import ModalMoveScreening from './ModalMoveScreening';
import { Link } from 'react-router-dom/cjs/react-router-dom';

const CardScreeningSearch = ({ data, fetchDraft, reloadSearches, setSearchesQuantity }) => {
  const { user, currentRole } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();
  const isCardScreeningSearch = true;
  const { handleScreeningModalData, screeningModalContent, closeModal, loadingModal, showModal } =
    useModalDetails();
  const { showConfirmationModal, setShowConfirmationModal, confirmCloseSearchModalContent } =
    useSearchDashboard(data.recruitSessionId, isCardScreeningSearch, reloadSearches);
  const cardRef = useRef(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showProctoringEnabledModal, setShowProctoringEnabledModal] = useState(false);
  const [proctoringDataModal, setProctoringDataModal] = useState({
    proctoringEnabled: false,
    recruitSessionStageId: '',
  });
  const [showMoveScreeningModal, setShowMoveScreeningModal] = useState(false);
  const [moveScreeningDataModal, setMoveScreeningDataModal] = useState({
    partnerId: '',
    recruitSessionStageId: '',
  });

  const [copySuccess, setCopySuccess] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [disabledWorkspaceFunctions, setDisabledWorkspaceFunctions] = useState(true);

  useEffect(() => {
    setDisabledWorkspaceFunctions(
      disableForWorkspaceRole(currentRole, [
        PartnerWorkspaceRole.OWNER,
        PartnerWorkspaceRole.ADMINISTRATOR,
      ])
    );
  }, []);

  useClickOutside(cardRef, () => setOpenDropdown(false));

  const today = new Date();
  const searchIsFinish = new Date(data.recruitSessionStageEndDate) < today;
  moment.locale('es');

  const progressBars = [
    {
      percent: ((data.createdAccounts * 100) / data.invitationsSent).toFixed(0),
      translate: t('CREATED_ACCOUNTS'),
      barColor: 'bg-secondary-contrast',
    },
    {
      percent: ((data.evaluationsStarted * 100) / data.invitationsSent).toFixed(0),
      translate: t('SCREENINGS_STARTED'),
      barColor: 'bg-yellow-contrast',
    },
    {
      percent: ((data.evaluationsFinished * 100) / data.invitationsSent).toFixed(0),
      translate: t('SCREENINGS_FINISHED'),
      barColor: 'bg-green-contrast',
    },
  ];
  const newSearch = {
    name: data?.recruitSessionStageName,
    stages: [
      {
        id: data?.recruitSessionStageId,
      },
    ],
  };

  const selectedSearch = {
    stages: [
      {
        id: data?.recruitSessionStageId,
      },
    ],
  };

  const addInvitations = () => {
    dispatch(setSendEmailFrom('edit'));
    dispatch(
      setNewSearchData({
        ...newSearch,
        ...selectedSearch,
        step: 4,
      })
    );
    getCustomSearch(data.recruitSessionId).then((res) => {
      dispatch(setSelectedSearch(res));
    });
    dispatch(setSubjectEmail({ subjectEmail: '' }));
    dispatch(setSearchWasAlreadyCreated(true));
    dispatch(setEmailsData({}));
    history.push('/createsearch/send');
  };

  const copyLink = () => {
    navigator.clipboard.writeText(data.invitationLink);
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 1000);
  };

  const goToSearch = () => {
    history.push(
      data.isDraft ? `/createevaluation?draftId=${data.id}` : `/search/${data.recruitSessionId}`
    );
  };

  const editStage = () => {
    history.push(`/search/${data.recruitSessionId}?editDates=1?editName=1`);
  };

  const goToSearchStats = () => {
    history.push(`/search/${data.recruitSessionId}?stats=${data.recruitSessionStageId}`);
  };

  const openModalForShowTests = async () => {
    const stage = await getStageScreening({ companyId: user.companyId, levelId: data.screeningId });
    handleScreeningModalData(stage.id);
  };

  const closeEvaluation = () => {
    setOpenDropdown(false);
    setShowConfirmationModal(true);
  };

  const setProctoringShowModal = ({ proctoringEnabled, recruitSessionStageId = '' }) => {
    setOpenDropdown(false);
    setShowProctoringEnabledModal(true);
    setProctoringDataModal({
      proctoringEnabled,
      recruitSessionStageId,
    });
  };

  const setMoveScreeningModal = (pId, data) => {
    setOpenDropdown(false);
    setShowMoveScreeningModal(true);
    setMoveScreeningDataModal({
      partnerId: pId,
      id: data.isDraft ? data.id : data.recruitSessionStageId,
      isDraft: data.isDraft,
    });
  };

  const onClickProctoringToggle = () => {
    updateProctoringEnabledRecruitSessionStage(proctoringDataModal.recruitSessionStageId, {
      proctoringEnabled: !proctoringDataModal.proctoringEnabled,
    })
      .then(() => {
        setShowProctoringEnabledModal(false);
        reloadSearches();
      })
      .catch(() => {
        setShowProctoringEnabledModal(false);
      });
  };

  const deleteDraft = () => {
    setOpenDropdown(false);
    setShowDeleteModal(true);
  };

  const handleReOpenEvaluation = async (recruitSessionId) => {
    await reOpenEvaluation(recruitSessionId);
    reloadSearches();
  };

  const dropdownOptions = [
    {
      icon: <EditPencilIcon className="icon" fill="#757575" width="14" height="14" />,
      text: t('EDIT'),
      disabled: false,
      action: () => (data.isDraft ? goToSearch() : editStage()),
      itemClass: `dropdown-option ${!data.isActive && !data.isDraft && 'd-none'}`,
      testid: 'edit-button',
    },
    {
      customContent: (
        <DuplicateEvaluation
          screeningId={data.screeningId || data.id}
          name={data.isDraft ? data.name : data.recruitSessionStageName}
          isDraft={data.isDraft}
          disabled={disabledWorkspaceFunctions}
          isDropdown
        />
      ),
      itemClass: 'dropdown-option',
      testid: 'duplicate-button',
    },
    {
      icon: <EditPencilIcon className="icon" fill="#757575" width="14" height="14" />,
      text: t('RE_OPEN'),
      disabled: false,
      action: () => handleReOpenEvaluation(data.recruitSessionId),
      itemClass: `dropdown-option ${
        data.isActive || new Date() > new Date(data.recruitSessionStageEndDate) || data?.isDraft
          ? 'd-none'
          : ''
      }`,
      testid: 'edit-pencil-button',
    },
    {
      icon: <DeleteIcon className="icon" />,
      text: t('DELETE_TOOLTIP'),
      disabled: false,
      action: () => deleteDraft(),
      itemClass: `dropdown-delete-option ${!data.isDraft && 'd-none'}`,
      testid: 'delete-button',
    },
    {
      icon: <People className="icon" />,
      text: t('VIEW_CANDIDATES'),
      disabled: false,
      action: () => goToSearch(),
      itemClass: `dropdown-option ${
        (data.isDraft ||
          Number(data.invitationsSent) === 0 ||
          (Number(data.createdAccounts) === 0 && !data.isActive)) &&
        'd-none'
      }`,
      testid: 'view-candidates-button',
    },
    {
      icon: <StatsIcon className="icon" width="14" height="16" />,
      text: t('VIEW_STATS'),
      disabled: false,
      action: () => goToSearchStats(),
      itemClass: `dropdown-option ${
        (data.isDraft || Number(data.invitationsSent) === 0) && 'd-none'
      }`,
      testid: 'view-stats-button',
    },
    {
      icon: data.proctoringEnabled ? (
        <CameraProctoringDisabled className="icon" />
      ) : (
        <CameraProctoringEnabled className="icon" />
      ),
      text: data.proctoringEnabled
        ? t('DISABLED_PROCTORING_DROPDOWN')
        : t('ENABLED_PROCTORING_DROPDOWN'),
      disabled: false,
      action: () =>
        setProctoringShowModal({
          proctoringEnabled: data.proctoringEnabled,
          recruitSessionStageId: data.recruitSessionStageId,
        }),
      itemClass: `dropdown-option `,
      testid: 'proctoring-enabled-button',
    },
    {
      icon: searchIsFinish ? (
        <ArchiveBoxIcon className="icon" />
      ) : (
        <CalendarClose className="icon" />
      ),
      text: searchIsFinish ? t('SCREENING_DASHBOARD_FINISH_SEARCH') : t('FINISH_EVALUATION'),
      disabled: false,
      action: () => closeEvaluation(),
      itemClass: `${searchIsFinish ? 'dropdown-option' : 'dropdown-delete-option'} ${
        (data.isDraft || !data.isActive) && 'd-none'
      }`,
      testid: 'close-search-button',
    },
    {
      icon: <MoveScreeningIcon />,
      text: t('MOVE_WORKSPACE_MENU'),
      disabled: false,
      action: () => setMoveScreeningModal(user.id, data),
      itemClass: `${disabledWorkspaceFunctions ? 'd-none' : 'dropdown-option'}`,
      testId: 'move-search-icon',
    },
  ];

  const selectDraftText = (isSubtitle) => {
    if (!data.testGroupList?.lengt && !data?.customTestGroup)
      return t(isSubtitle ? 'ADD_TESTS_SUBTITLE' : 'ADD_TESTS');
    if (new Date(data.startDate) < new Date() || new Date(data.endDate) > new Date())
      return t(isSubtitle ? 'REVIEW_DATES_SUBTITLE' : 'REVIEW_DATES');
    if (!data.testGroupList.every((tg) => tg.weight))
      return t(isSubtitle ? 'ADD_WEIGHT_TESTS_SUBTITLE' : 'ADD_WEIGHT_TESTS');
    return t('OPEN_EVALUATION');
  };

  const selectSearchText = (isSubtitle) => {
    if (data?.isActive) {
      if (Number(data.invitationsSent) === 0 && new Date(data.recruitSessionStageEndDate) > today)
        return t(isSubtitle ? 'FOUND_YOUR_TALENT_SUBTITLE' : 'FOUND_YOUR_TALENT');
      if (Number(data.invitationsSent) > 0 && new Date(data.recruitSessionStageEndDate) > today) {
        return t(isSubtitle ? 'ENCOURAGE_PARTICIPATION_SUBTITLE' : 'ENCOURAGE_PARTICIPATION');
      }
      if (Number(data.invitationsSent) === 0 && searchIsFinish) {
        return t(isSubtitle ? 'FOUND_TALENT_FINISH_SUBTITLE' : 'FOUND_TALENT_FINISH');
      }
      return t(
        isSubtitle
          ? 'FOUND_TALENT_ENCOURAGE_PARTICIPATION_SUBTITLE'
          : 'FOUND_TALENT_ENCOURAGE_PARTICIPATION'
      );
    }
    return t(isSubtitle ? 'DONT_BE_DISCOURAGED_SUBTITLE' : 'DONT_BE_DISCOURAGED');
  };

  const searchStatus = () => {
    if (data.isDraft) return t('SCREENING_SEARCH_DRAFT');
    if (!data.isActive) return t('SEARCH_INACTIVE');
    if (searchIsFinish) return t('SEARCH_FINISH');
    if (new Date(data.recruitSessionStageStartDate) > today) return t('SEARCH_SHEDULED');
    return t('SEARCH_ACTIVE');
  };

  return (
    <div
      ref={cardRef}
      data-testid="card-screening-search"
      className="col col-12 col-sm-6 col-md-4 col-lg-4 p-0 my-2 card-height bg-gray"
    >
      <Modal
        show={showModal}
        relativeCloseButton
        className="rounded px-4 py-2 justify-content-center w-80vw"
        showCloseButton={!loadingModal}
        closeBtnClassname="text-maya-blue font-weight-bold"
        setShow={(e) => closeModal(e)}
      >
        {screeningModalContent()}
      </Modal>
      <ModalDeleteDraft
        showDeleteModal={showDeleteModal}
        user={user}
        partnerId={data?.partner?.id}
        setShowDeleteModal={setShowDeleteModal}
        draftId={data.id}
        fetchDrafts={fetchDraft}
        setSearchesQuantity={setSearchesQuantity}
      />
      <Modal
        show={showConfirmationModal}
        className="close-search-modal-container"
        closeBtnClassname="text-maya-blue font-weight-bold"
        setShow={setShowConfirmationModal}
        showCloseButton={false}
      >
        {confirmCloseSearchModalContent()}
      </Modal>
      <ModalProctoringEnabled
        setShowModal={setShowProctoringEnabledModal}
        showModal={showProctoringEnabledModal}
        data={proctoringDataModal}
        submitAction={onClickProctoringToggle}
        disabledButtonProps
      />
      <ModalMoveScreening
        setShowModal={setShowMoveScreeningModal}
        showModal={showMoveScreeningModal}
        data={moveScreeningDataModal}
        reloadSearches={reloadSearches}
      />
      <div className="h-100 m-0 card-3 d-flex flex-column mx-3 screening-search-container bg-white">
        <div className="px-3 bg-white b-radius-8 d-flex h-100 flex-column">
          <div className="d-flex justify-content-between mt-1">
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip className="font-montserrat" placement="top">
                  {data?.isDraft ? data.name : data.recruitSessionStageName}
                </Tooltip>
              }
            >
              <Link
                data-testid="name-button"
                rel="noreferrer noopener"
                to={
                  data.isDraft
                    ? `/createevaluation?draftId=${data.id}`
                    : `/search/${data.recruitSessionId}`
                }
                className="text-decoration-none mt-2 text-left border-0 bg-transparent overflow-hidden text-truncate secondary-contrast-color font-montserrat font-weight-600 text-md title-screening description-card-search w-90"
                style={{ color: '#4bafe1' }}
              >
                {data.isDraft ? data?.name : data?.recruitSessionStageName}
              </Link>
            </OverlayTrigger>
            <span className="mt-2 position-dots">
              <VerticalDotsDropdown
                options={dropdownOptions}
                className="mr-2 width-selectable"
                setOpenDropdown={setOpenDropdown}
                openDropdown={openDropdown}
                disabled={disabledWorkspaceFunctions}
              />
            </span>
          </div>
          <div className="d-flex mt-2 mb-3 icons-position align-items-center">
            {!data.isDraft && (
              <>
                <div className="align-items-center mr-2">
                  {data?.evaluationsPendingCredits > 0 && (
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <TooltipReact className="font-montserrat">
                          {t('EVALUATIONS_PENDING_CREDITS')}
                        </TooltipReact>
                      }
                    >
                      <div>
                        <a
                          className="mx-1 font-montserrat text-sm diamond-background d-flex align-items-center justify-content-center rounded-circle"
                          href={process.env.RAZZLE_RUNTIME_SALES_CALENDAR_URL}
                        >
                          <InfoIcon color="#ff7676" />
                        </a>
                      </div>
                    </OverlayTrigger>
                  )}
                </div>
                <ShareIcon />
                <span className="font-montserrat font-weight-600 text-sm ml-2">
                  {data.invitationsSent}
                </span>
                <span className="font-montserrat text-sm ml-1 pr-3 border-right border-secondary mr-2">
                  {t('INVITATIONS')}
                </span>
              </>
            )}
            <ExamIcon />
            {data.isDraft ? (
              <>
                <span className="font-montserrat font-weight-600 text-sm ml-2">
                  {data?.customTestGroup?.tests?.length > 0
                    ? data.testGroupList?.length + 1
                    : data.testGroupList?.length}
                </span>
                <span className="font-montserrat text-sm ml-1">Tests</span>
              </>
            ) : (
              <button
                onClick={() => {
                  openModalForShowTests();
                }}
                className="text-sm ml-1 border-0 bg-white font-montserrat text-underline"
                type="button"
              >
                {t('VIEW_SCREENING_TEST')}
              </button>
            )}
          </div>
          <div className="mb-3 mt-1 h-100">
            {!data.isDraft ? (
              Number(data.createdAccounts) === 0 ? (
                <div className="mt-2 mb-3 position-texts mx-auto w-85">
                  <p className="font-montserrat font-weight-600 text-md text-center mb-2">
                    {selectSearchText(false)}
                  </p>
                  <p
                    dangerouslySetInnerHTML={{ __html: selectSearchText(true) }}
                    className="font-montserrat text-sm text-center"
                  />
                </div>
              ) : (
                progressBars.map((pb) => (
                  <div key={pb.translate} className="mb-2">
                    <span className="font-montserrat font-weight-700 text-sm mb-2">
                      {pb.percent}%
                      <span className="font-montserrat text-sm ml-1 font-weight-500">
                        {pb.translate}
                      </span>
                    </span>
                    <Progress percent={pb.percent} barColor={pb.barColor} />
                  </div>
                ))
              )
            ) : (
              <div className="mb-5 position-texts mx-auto w-85">
                <p className="font-montserrat font-weight-600 text-md text-center mb-2">
                  {selectDraftText(false)}
                </p>
                <p
                  dangerouslySetInnerHTML={{ __html: selectDraftText(true) }}
                  className="font-montserrat text-sm text-center"
                />
              </div>
            )}
          </div>
          <div className="d-flex position-relative w-100 justify-content-between mb-1">
            {(data?.partner?.firstName || data?.partnerFirstName) && (
              <span
                data-testid="span-created-by"
                className="mt-auto font-montserrat text-muted text-sm font-weight-500 overflow-hidden text-truncate"
              >
                {t('RECRUIT_SESSION_DRAFT_CREATED_BY')}
                <b className="font-montserrat text-muted text-sm ml-1">
                  {data.isDraft
                    ? ` ${data?.partner?.firstName} ${data?.partner?.lastName}`
                    : ` ${data?.partnerFirstName} ${data?.partnerLastName}`}
                </b>
              </span>
            )}
            {data?.isActive &&
            Number(data.invitationsSent) === 0 &&
            new Date(data.recruitSessionStageEndDate) > today ? (
              <span data-testid="arrow-invite-span" className="mr-2">
                <ArrowInvite />
              </span>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div
          data-testid="footer-card"
          className={`mt-auto footer-card ${
            data.isDraft
              ? 'gray-footer-card'
              : !data.isActive
              ? 'closed-footer-card-dark-grey'
              : searchIsFinish
              ? 'finished-footer-card'
              : 'open-footer-card'
          } d-flex justify-content-between`}
        >
          <div
            className={`d-flex align-items-center ticket-status-search text-white ${
              data.isDraft
                ? 'ticket-gray'
                : !data.isActive
                ? 'ticker-dark-grey'
                : searchIsFinish
                ? 'ticket-finish'
                : 'ticket-open'
            }`}
          >
            <span className="text-uppercase font-roboto">{searchStatus()}</span>
            {!data.isDraft &&
            new Date(data.recruitSessionStageEndDate) > today &&
            data.isActive &&
            !data.recruitSessionStageEmptyDates ? (
              <span className="font-roboto">
                {new Date(data.recruitSessionStageStartDate) > today
                  ? `${t('STARTED_AT')}${moment(data.recruitSessionStageStartDate).format(
                      'D MMM YYYY'
                    )}`
                  : `${t('FINISH_AT')}${moment(data.recruitSessionStageEndDate).format(
                      'D MMM YYYY'
                    )}`}
              </span>
            ) : (
              <></>
            )}
          </div>
          <div className="d-flex">
            {data.isDraft || new Date(data.recruitSessionStageEndDate) < today || !data.isActive ? (
              <>
                <DuplicateEvaluation
                  screeningId={data.screeningId || data.id}
                  name={data.isDraft ? data.name : data.recruitSessionStageName}
                  isDraft={data.isDraft}
                  disabled={disabledWorkspaceFunctions}
                />
                {data.isDraft && (
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip className="font-montserrat">{t('DELETE_TOOLTIP')}</Tooltip>}
                  >
                    <button
                      disabled={disabledWorkspaceFunctions}
                      className="border-0 outline-none bg-transparent ml-2"
                      type="button"
                      data-testid="delete-draft-button"
                      onClick={() => {
                        deleteDraft();
                      }}
                    >
                      <DeleteForever fill="#828282" />
                    </button>
                  </OverlayTrigger>
                )}
              </>
            ) : (
              <>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className="font-montserrat">
                      {t('ADD_INVITATIONS_CHART_TOOLTIP')}
                    </Tooltip>
                  }
                >
                  <button
                    disabled={disabledWorkspaceFunctions}
                    data-testid="add-invitation-button"
                    type="button"
                    className="border-0 bg-transparent mr-2"
                    onClick={addInvitations}
                  >
                    <AddInvitations width="17" height="17" />
                  </button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className="font-montserrat">
                      {copySuccess ? t('COPIED_INVITATION_LINK') : t('COPY_INVITATION_LINK')}
                    </Tooltip>
                  }
                >
                  <button
                    disabled={disabledWorkspaceFunctions}
                    data-testid="copy-link-button"
                    type="button"
                    className={`border-0 bg-transparent ${
                      determineDisabledFeature({ featureId: PlanFeaturesIds.SEARCH_LINKS })
                        ? 'displayNone'
                        : ''
                    }`}
                    onClick={copyLink}
                  >
                    {copySuccess ? <CopyCheck /> : <CopyLink width="17" height="17" />}
                  </button>
                </OverlayTrigger>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardScreeningSearch;

import { TreeSelect } from 'antd';
import React, { useEffect, useState } from 'react';
import './TreeMultiSelect.scss';
import arrowIcon from '../../assets/dashboard/arrow-small.svg';
import { t } from '../../i18n/i18n';

const TreeMultiSelect = ({
  options = [],
  value = [],
  onChange = async () => {},
  className = '',
  style = {},
  textSize = '12px',
  label = '',
  width = '100%',
  labelPosition = { top: 5, left: 10 },
  labelName = 'name',
  dropdownWidth = 'max-content',
}) => {
  const [treeData, setTreeData] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const prevTreeData = options?.map((item) => {
      const option = { ...item, value: item?.id, title: item[labelName], key: item?.id };
      if (item?.children) {
        const prevChildren = item.children?.map((child) => ({
          ...child,
          pId: child?.parentId,
          value: child?.id,
          key: child?.id,
          title: child[labelName],
          children: child?.children?.map((subChild) => ({
            ...subChild,
            pId: subChild?.parentId,
            value: subChild?.id,
            key: subChild?.id,
            title: subChild[labelName],
          })),
        }));
        option.children = prevChildren;
      }
      return option;
    });
    setTreeData(prevTreeData);
  }, [options]);

  const onChangeEvent = (newValues = []) => {
    const items = treeData?.filter((item) => newValues?.includes(item?.id));
    onChange(newValues, items);
  };

  const tProps = {
    treeData,
    value,
    onChange: onChangeEvent,
    treeCheckable: true,
  };

  return (
    <div
      style={{ width, position: 'relative', cursor: 'pointer' }}
      className={`tree-select-custom ${className}`}
    >
      <TreeSelect
        {...tProps}
        placeholder=""
        showCheckedStrategy="SHOW_ALL"
        className={className}
        onDropdownVisibleChange={() => {
          setOpen(!open);
        }}
        open={open}
        value={value}
        showSearch={false}
        aria-selected={false}
        style={{
          ...style,
          border: open ? '1px solid #1aacf0' : '',
          width: '100%',
        }}
        dropdownStyle={{
          borderColor: '#1aacf0',
          border: '1px solid #1aacf0',
          fontWeight: 'bold',
          color: '#000',
          fontSize: '8px',
          fontFamily: 'font-montserrat',
          overflow: 'scroll',
          // width: dropdownWidth,
          minWidth: dropdownWidth,
        }}
      />

      <span
        style={{
          position: 'absolute',
          top: labelPosition?.top ?? 5,
          left: labelPosition?.left ?? 10,
          userSelect: 'none',
          zIndex: 1,
          pointerEvents: 'none',
          fontSize: textSize,
        }}
        className="font-montserrat font-weight-bold"
      >
        {label}
      </span>

      <img
        src={arrowIcon}
        alt={t('ARROW_ALT')}
        className={`dropdown-select-arrow ${open && 'dropdown-select-arrow-open'}`}
        data-testid="multiple-select-arrow-img"
        style={{ position: 'absolute', top: 13, right: 10, pointerEvents: 'none' }}
      />
    </div>
  );
};

export default TreeMultiSelect;

export const getCategoryNameTranslate = (category, key = 'es') => {
  if (!category) return null;
  const langs = category?.languages != null ? Object.values(category?.languages) : [];
  return langs?.length ? category?.languages[key] ?? langs[0] : category?.name;
};
export const getRolNameTranslate = (rol, key = 'es') => {
  if (!rol) return null;
  const langs = rol?.languages != null ? Object.values(rol?.languages) : [];
  return langs?.length ? rol?.languages[key] ?? langs[0] : rol?.displayName;
};

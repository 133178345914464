import React, { useEffect, useRef, useState } from 'react';
import Lottie from 'lottie-react';
import { t } from '../../../i18n/i18n';
import EmptyFavorites from '../../../assets/screening/EmptyFavorites.json';
import useCreateScreening from '../../../hooks/useCreateScreening';
import HorizontalLoader from '../../HorizontalLoader/HorizontalLoader';
import useOnScreen from '../../../hooks/useOnScreen';
import Spinner from '../../spinner/Spinner';
import TestItem from '../../TestItem/TestItem';
import timerIcon from '../../../assets/screening/timer.svg';
import { formatMinutesToHours } from '../../../utils/formatMinutesToHours';

const FavoriteTests = ({ loadingTests, fetchTests, setShow, isCheck }) => {
  const { testArray, loading, fetchNextPage, handleTests, newScreening, testsDuration } =
    useCreateScreening();
  const fetchNextUrlRef = useRef();
  const fetchNextUrl = useOnScreen(fetchNextUrlRef, '0px', true);
  const favoriteTestGroups = testArray?.collection?.filter((item) => item?.favorite) ?? [];
  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    fetchTests({ favorites: true });
  }, []);

  useEffect(() => {
    if (!fetchNextUrl) setFetched(false);
    if (
      fetchNextUrl &&
      !fetched &&
      testArray?.collection?.length &&
      testArray?.pagination?.nextPage &&
      !loading &&
      !loadingTests
    ) {
      fetchNextPage();
      setFetched(true);
    }
  }, [fetchNextUrl]);

  return (
    <div data-testid="create-evaluation-favorite-tests">
      {isCheck && (
        <div className="my-1 my-sm-0 mr-0 mr-sm-2 d-flex align-items-center ml-2">
          <img
            src={timerIcon}
            className="mr-1"
            style={{ marginRight: '0px', marginLeft: 'auto' }}
            alt={t('TIMER_ALT')}
            data-testid="alkemy-tests-timer-img"
          />
          <span
            className="font-montserrat text-muted text-base font-weight-500"
            data-testid="alkemy-tests-time-title-span"
          >
            {t('CREATE_SCREENING_TOTAL_TIME')}
          </span>
          <span
            className="total-number-text font-montserrat text-base ml-1 font-weight-bold"
            data-testid="alkemy-tests-time-duration-span"
          >
            {formatMinutesToHours(testsDuration)}
          </span>
        </div>
      )}
      {!favoriteTestGroups?.length && !loadingTests && !loading && (
        <div className="w-100 bg-white mt-3">
          <div className="w-75 d-flex flex-column align-items-center mx-auto text-center">
            <div
              data-testid="create-evaluation-favorite-tests-lottie"
              className="empty-state-container mx-auto mb-2"
            >
              <Lottie animationData={EmptyFavorites} loop className="my-5" />
            </div>
            <div className="alkemy-tests-empty-state-texts mt-4 z-index-1">
              <p
                className="font-weight-600 font-montserrat px-2 text-base"
                data-testid="favorite-tests-empty-state-title-p"
              >
                {t('CREATE_EVALUATION_FAVORITE_TESTS_EMPTY_STATE_TITLE')}
              </p>
              <p
                className="font-weight-400 mt-2 mb-4 font-montserrat px-2 text-base"
                data-testid="favorite-tests-empty-state-subtitle-p"
              >
                {t('CREATE_EVALUATION_FAVORITE_TESTS_EMPTY_STATE_SUBTITLE')}
              </p>
            </div>
          </div>
        </div>
      )}
      <div
        data-testid="create-evaluation-favorite-tests-list"
        className={`px-2 px-md-4 py-4 overflow-auto bg-gray ${
          loadingTests ? ' d-flex justify-content-center' : ' px-2 px-md-4 py-4'
        } ${
          (testArray?.collection?.length || loading) &&
          'test-grid-container test-grid-container-tests'
        }`}
        style={{ height: '32rem' }}
      >
        {loadingTests ? (
          <Spinner show />
        ) : (
          <>
            {favoriteTestGroups?.map((item) => (
              <TestItem
                key={item.id}
                test={item}
                setShow={setShow}
                isStar
                isFavorite={item?.favorite ?? false}
                testArray={testArray}
                isCheck={isCheck}
                handleTests={handleTests}
                newScreening={newScreening}
              />
            ))}
          </>
        )}
        <div ref={fetchNextUrlRef} />
      </div>
      {loading && (
        <div
          data-testid="create-evaluation-favorite-tests-horizontal-loader"
          className="d-flex justify-content-center position-absolute pt-4 pr-5 left-0 right-0 horizontal-loader-height mt-0 mx-auto"
        >
          <HorizontalLoader />
        </div>
      )}
    </div>
  );
};

export default FavoriteTests;

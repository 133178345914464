import React, { useState } from 'react';

export const Tabs = ({ tabs = [{ text: '', component: <></> }] }) => {
  const [currentTab, setCurrentTab] = useState(0);
  const onChangeTab = (index) => {
    setCurrentTab(index);
  };
  return (
    <div className="d-flex flex-column">
      <div className="text-left position-relative d-flex mb-4  " style={{ top: 0, left: 0 }}>
        {tabs?.map((item, index) => (
          <div key={index}>
            <button
              onClick={() => onChangeTab(index)}
              type="button"
              data-testid={`button-tab-${item?.text}`}
              key={item?.text}
              className={`bg-transparent font-montserrat text-base button-tab
                ${index === currentTab ? 'button-tab-selected' : 'button-tab-select'}
                ${index ? 'pr-2 pl-2' : 'pl-2 pr-2'}
                `}
            >
              <span
                className={`font-montserrat ${
                  index === currentTab ? 'text-tab-selected' : 'text-tab-select'
                }`}
              >
                {item?.text}
              </span>
            </button>
          </div>
        ))}
      </div>
      {tabs[currentTab]?.component}
    </div>
  );
};

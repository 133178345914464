import { notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { editMe } from '../../API/services/authService';
import Check from '../../assets/modal/Check';
import { t } from '../../i18n/i18n';
import CountryFlag from '../CountryFlag/CountryFlag';
import { languagesList } from '../DropdownLanguage/DropdownLanguage';
import ModalCustom from '../ModalCustom/ModalCustom';
import { setUser } from '../../redux/actions/authActions';
function ModalPreferredLanguage(props) {
  const { user } = useSelector((state) => state.auth);
  const [preferredLanguage, setPreferredLanguage] = useState(null);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!user?.preferredLanguage) setOpen(true);
  }, [user]);

  const onClickOk = async () => {
    try {
      setLoadingSubmit(true);
      await editMe({
        id: user.id,
        toEdit: {
          preferredLanguage,
        },
      });
      dispatch(setUser({ ...user, preferredLanguage }));
      notification.open({
        message: t('PROFILE_EDIT_SUCCESS'),
        type: 'success',
      });
      setOpen(false);
    } catch (error) {
      notification.open({
        message: t('PROFILE_EDIT_FAILED'),
        type: 'error',
      });
    } finally {
      setLoadingSubmit(false);
    }
  };

  const onCancel = () => {
    setOpen(false);
  };

  return (
    <ModalCustom
      datatestid="modal-preferred-language"
      datatestidbuttonok="modal-preferred-language-button-ok"
      title={t('MODAL_PREFERRED_LANGUAGE_TITLE')}
      subtitle={t('MODAL_PREFERRED_LANGUAGE_SUBTITLE')}
      show={open}
      setShow={setOpen}
      onOkText={t('SAVE')}
      // onCancelText={t('CANCEL')}
      // onCancel={onCancel}
      onOk={onClickOk}
      classModal="modal-create-workspace"
      showCross={false}
      isLoading={loadingSubmit}
      onOkDisabled={loadingSubmit || !preferredLanguage}
      disableClickOutside={true}
      input={
        <div className="my-3">
          {languagesList.map((option) => (
            <button
              key={`lang-option-${option.iso}`}
              type="button"
              className={`bg-white option-container d-flex align-items-center justify-content-between b-radius-8 outline-border  ${
                preferredLanguage === option?.value ? 'interest-active' : 'interest-not-active'
              }`}
              onClick={() => setPreferredLanguage(option?.value)}
            >
              <div className="d-flex align-items-center justify-content-start">
                <CountryFlag countryIso={option?.iso} size="sm" className="mr-2" />
                <p className="font-montserrat my-auto font-weight-600 text-sm text-truncate interest-option">
                  {option.label}
                </p>
              </div>

              {preferredLanguage === option?.value && <Check />}
            </button>
          ))}
        </div>
      }
    />
  );
}

ModalPreferredLanguage.propTypes = {};

export default ModalPreferredLanguage;

import { t } from '../i18n/i18n';

export const templateDefault = (user) =>
  `<h2 class="ql-align-center"><strong>¡${user.company.displayName} ${t(
    'WANTS_TO_MEET_YOU'
  )}!</strong></h2><p class="ql-align-center"></p><p class="ql-align-center">¡${t(
    'HELLO_CUSTOM_EMAIL'
  )}, {firstName} {lastName}!</p><p class="ql-align-center">${t(
    'HOW_DO_YOU_FEEL'
  )}</p><p class="ql-align-center">${t('CUSTOM_EMAIL_TEXT')} ${user.company.displayName} ${t(
    'CUSTOM_EMAIL_TEXT_1'
  )}.</p><p class="ql-align-center">${t(
    'CUSTOM_EMAIL_TEXT_2'
  )}.</p><p class="ql-align-center"></p>`;

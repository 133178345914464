import { enGB, es, pt } from 'date-fns/locale';
import { registerLocale } from 'react-datepicker';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { t } from '../../i18n/i18n';
import CheckboxPartial from '../CheckBoxPartial/CheckboxPartial';
import Checkbox from '../Checkbox/Checkbox';
import OrderingAngles from '../OrderingAngles/OrderingAngles';
import Spinner from '../spinner/Spinner';
import ScreeningInvitationsItem from '../ScreeningInvitationsItem/ScreeningInvitationsItem';
import './SearchTable.scss';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const SearchTable = ({
  partialSelection,
  toggleSelectAll,
  invitationsList,
  quantity,
  handleOrder,
  query,
  id,
  setKeepData,
  invitationIdToScroll,
  updateStageSingleCandidate,
  processStatusStageOptions,
  size,
  selected,
  allSelected,
  toggleSelectedId,
  isSelected,
  handleSelectCandidate,
  startModifyStageConfirmation,
  loadingNextPage,
  stageScreening,
  disabledVisualizator,
  startAddingMemberToStageConfirmation,
  updateDateLimitInvitation,
}) => {
  registerLocale('en', enGB);
  registerLocale('pt', pt);
  registerLocale('es', es);
  const { searchTablePersonalization, selectedSearch } = useSelector((state) => state.screenings);
  const [isLoading, setIsLoading] = useState(true);

  const listener = () => {
    document.querySelector('#search-table-header-container').scrollTo({
      left: document.querySelector('#custom-search-table-horizontal-scroll').scrollLeft,
    });
    document.querySelector('#search-table-body-container').scrollTo({
      left: document.querySelector('#custom-search-table-horizontal-scroll').scrollLeft,
    });
  };

  const initialize = () => {
    const tableBody = document.querySelector('#custom-search-table-horizontal-scroll');
    if (tableBody) {
      tableBody.addEventListener('scroll', listener);
    }
  };

  const cleanup = () => {
    const tableBody = document.querySelector('#custom-search-table-horizontal-scroll');
    if (tableBody) {
      tableBody.removeEventListener('scroll', listener);
    }
  };

  useEffect(() => {
    initialize();
    return () => {
      cleanup();
    };
  }, []);

  const getPreviousOrderWidth = (order) => {
    const prevOrder = searchTablePersonalization.find((item) => item.order === order - 1);
    if (prevOrder) {
      const widthValue = Number(prevOrder.width.replace('rem', ''));
      return `${widthValue + 3}rem`;
    }
    return '3rem';
  };

  useEffect(() => {
    if (selectedSearch.stages) {
      setIsLoading(false);
    }
  }, [selectedSearch]);

  return (
    <div className="min-width-100 mt-1 container-search-dashboard">
      <div
        id="search-table-header-container"
        className="search-dashboard-header bg-white border-headers "
      >
        <div className="pb-3 bg-white d-flex flex-row align-items-center justify-content-start pl-2 search-table-header-item">
          <div
            data-testid="header-checkbox"
            className={`d-flex align-items-center flex-shrink-0 ${
              searchTablePersonalization.filter((item) => item.pinned).length > 0 ? 'pinned' : ''
            }`}
            style={{ width: '3rem' }}
          >
            {partialSelection ? (
              <CheckboxPartial
                fill="#FFF"
                className="search-dashboard-checkbox-partial"
                onClick={toggleSelectAll}
              />
            ) : (
              <Checkbox
                className="search-dashboard-checkbox"
                checked={quantity >= invitationsList?.length}
                onClick={toggleSelectAll}
              />
            )}
          </div>
          {searchTablePersonalization
            .filter((item) => item.checked)
            .sort((a, b) => a.order - b.order)
            .map((header) => (
              <div
                data-testid={`header-${header.key}`}
                key={header.key}
                className={`pl-3 flex-shrink-0 ${header.sortable ? 'cursor-pointer' : ''} ${
                  header.pinned ? 'pinned' : ''
                }`}
                style={{
                  width: header.width,
                  left: header.pinned ? getPreviousOrderWidth(header.order) : 'unset',
                }}
                {...(header.sortable
                  ? {
                      onClick: () => handleOrder(header.key),
                    }
                  : {})}
              >
                <div className="w-100 d-flex align-items-center">
                  <OverlayTrigger placement="top" overlay={<Tooltip>{t(header.name)}</Tooltip>}>
                    <span className="font-montserrat font-weight-600 text-sm mb-0 d-inline-block text-truncate">
                      {t(header.name)}
                    </span>
                  </OverlayTrigger>
                  {header.sortable && (
                    <div className="ml-1">
                      <OrderingAngles order={query?.orderBy === header.key ? query?.order : null} />
                    </div>
                  )}
                </div>
              </div>
            ))}
          <div
            className="flex-shrink-0"
            style={{
              width: '3.5rem',
            }}
          >
            <span className="font-montserrat font-weight-600 text-sm  mb-0 d-inline-block invisible">
              Linkedin
            </span>
          </div>
        </div>
      </div>
      <div id="search-table-body-container" className="search-table-body">
        {!isLoading &&
          invitationsList?.map((profile) => (
            <ScreeningInvitationsItem
              key={profile?.invitationId}
              profile={profile}
              headers={searchTablePersonalization
                .filter((item) => item.checked)
                .sort((a, b) => a.order - b.order)}
              recruitSessionId={id}
              setKeepData={setKeepData}
              className={
                invitationIdToScroll === profile.invitationId && 'focus-invitation-animation'
              }
              handleProcessStatus={updateStageSingleCandidate}
              processStatusOptions={processStatusStageOptions}
              selected={selected}
              allSelected={allSelected}
              toggleSelect={toggleSelectedId}
              disabled={isSelected}
              handleSelectCandidate={handleSelectCandidate}
              deleteSelectCandidate={() => startModifyStageConfirmation([profile], false)}
              toggleVisibilitySelectCandidate={() => startModifyStageConfirmation([profile], true)}
              toggleVisibilityInviteMembers={() => startAddingMemberToStageConfirmation([profile])}
              testGroups={profile.testgroupsResults ?? []}
              qualitativeConfig={stageScreening?.qualitativeConfig ?? []}
              getPreviousOrderWidth={getPreviousOrderWidth}
              disabledVisualizator={disabledVisualizator}
              updateDateLimitInvitation={updateDateLimitInvitation}
              evaluationLimitDate={selectedSearch.stages[0]?.endDate}
            />
          ))}
        <div
          className="non-selectable mx-auto bg-white px-0 pb-3 rounded position-relative flex-grow-1 w-100 profiles-invited-list-container overflow-hidden"
          data-size={size}
        >
          <Spinner show={loadingNextPage} />
        </div>
      </div>
      <div
        id="custom-search-table-horizontal-scroll"
        className=" table-horizontal-scroll d-flex flex-row pl-2"
      >
        <div className="flex-shrink-0" style={{ width: '3rem', height: '1rem' }} />
        {searchTablePersonalization
          .filter((item) => item.checked)
          .sort((a, b) => a.order - b.order)
          .map((header) => (
            <div
              key={`space-${header.key}`}
              className="pl-3 flex-shrink-0"
              style={{ width: header.width, height: '1rem' }}
            />
          ))}
        <div className="flex-shrink-0" style={{ width: '3.5rem', height: '1rem' }} />
      </div>
    </div>
  );
};

export default SearchTable;

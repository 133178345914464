const ParagraphWithTitle = ({
  titleText,
  subtitleText,
  containerClassname = '',
  titleClassname = '',
  subtitleClassname = '',
}) => (
  <div className={`d-flex flex-column ${containerClassname} mt-2`}>
    <p
      className={`font-montserrat font-weight-700 line-height-20 letter-spacing-05 mb-1 ${titleClassname}`}
    >
      {titleText}
    </p>
    <p
      className={`font-montserrat font-weight-500 line-height-17 letter-spacing-05 gray-subtitle text-sm ${subtitleClassname}`}
    >
      {subtitleText}
    </p>
  </div>
);

export default ParagraphWithTitle;

export const generateHours = (interval = 15, totalHours = 24, minHours = null) => {
  const times = [];
  let tt = 0;

  for (let i = 0; tt < totalHours * 60; i++) {
    const hh = Math.floor(tt / 60);
    const mm = tt % 60;
    const hours = `${`0${hh}`.slice(-2)}:${`0${mm}`.slice(-2)}`;

    if (minHours === null || tt >= minHours * 60) {
      times.push({
        id: hours,
        name: hours,
      });
    }

    tt += interval;
  }

  return times;
};

import { useState } from 'react';
import { notification } from 'antd';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { duplicateRecruitSessionDraft } from '../../API/services/screeningService';
import ModalCustom from '../ModalCustom/ModalCustom';
import DuplicateIcon from '../../assets/createSearch/DuplicateIcon';
// import './DuplicateEvaluation.scss';
import { t } from '../../i18n/i18n';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const DuplicateEvaluation = ({
  dashboard,
  screeningId,
  name,
  isDraft,
  isDropdown,
  disabled = false,
}) => {
  const { user, currentWorkspace } = useSelector((state) => state.auth);
  const history = useHistory();
  const [deprecatedTestGroups, setdeprecatedTestGroups] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [newDraftId, setNewDraftId] = useState('');

  const redirectToDraft = (id) => {
    history.push(`/createevaluation?draftId=${id}`);
  };

  const duplicateEvaluation = async () => {
    const dataToPost = {
      companyId: user.companyId,
      workspaceId: currentWorkspace,
      id: screeningId,
      name: `${t('COPY_TO')} ${name}`,
      isDraft,
    };

    try {
      const { newDraft, deprecatedTestGroups } = await duplicateRecruitSessionDraft(dataToPost);
      setNewDraftId(newDraft.id);
      if (deprecatedTestGroups.length) {
        setShowModal(true);
        setdeprecatedTestGroups(deprecatedTestGroups);
      } else {
        redirectToDraft(newDraft.id);
      }
    } catch (error) {
      notification.open({
        message: t('DUPLICATE_DRAFT_ERROR'),
        type: 'error',
      });
    }
  };

  return (
    <>
      <ModalCustom
        show={showModal}
        closeBtnClassname="text-maya-blue font-weight-bold"
        setShow={setShowModal}
        showCross={false}
        onOk={() => redirectToDraft(newDraftId)}
        onOkText={t('UNDERSTOOD')}
        datatestidbuttonok="button-understood"
        title={t('TITLE_MODAL_DUPLICATE_DRAFT')}
        subtitle={t('SUBTITLE_MODAL_DUPLICATE_DRAFT')}
        input={
          <div className="h-emails-error-container w-85 overflow-auto m-auto">
            {deprecatedTestGroups.map((item) => (
              <div
                key={item.email}
                className="border-bottom pb-2 d-flex mt-3 justify-content-around"
              >
                <span className="text-left font-montserrat font-weight-600 text-sm mx-1 w-75 text-start">
                  {item.name}
                </span>
                <span
                  className={`text-uppercase ticket-status-search font-roboto text-xs ${
                    item.status === 'DISABLED' ? 'ticket-off' : 'ticket-on'
                  }`}
                >
                  {t(item.status)}
                </span>
              </div>
            ))}
          </div>
        }
      />
      {dashboard ? (
        <div className="position-relative ">
          <span
            className="font-montserrat text-sm font-weight-500 purple-color mr-2 "
            style={{ position: 'relative', top: '10px' }}
          >
            {t('DUPLICATE_TOOLTIP')}
          </span>
          <button
            type="button"
            className="border-0 bg-transparent "
            data-testid="duplicate-draft-button"
            disabled={disabled}
            onClick={duplicateEvaluation}
            style={{ position: 'absolute', top: '7px', left: '70px' }}
          >
            <DuplicateIcon />
          </button>
        </div>
      ) : isDropdown ? (
        <button
          className="dropdown-btn d-flex align-items-center border-0 px-2 w-100"
          disabled={disabled}
          onClick={duplicateEvaluation}
          type="button"
        >
          <div className="row no-gutters w-100 justify-content-start align-items-center">
            <div className="col-2">
              <DuplicateIcon fill="#828282" />
            </div>
            <div className="col-10">
              <span className="pl-1 font-montserrat font-weight-500">{t('DUPLICATE_TOOLTIP')}</span>
            </div>
          </div>
        </button>
      ) : (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip className="font-montserrat">{t('DUPLICATE_TOOLTIP')}</Tooltip>}
        >
          <button
            type="button"
            className="border-0 bg-transparent"
            data-testid="duplicate-draft-button"
            disabled={disabled}
            onClick={duplicateEvaluation}
          >
            <DuplicateIcon fill="#828282" />
          </button>
        </OverlayTrigger>
      )}
    </>
  );
};

export default DuplicateEvaluation;

import React, { useEffect, useState } from 'react';
import { BiSupport } from 'react-icons/bi';
import mercadoPagoLogo from '../../../assets/payments/mercadopago.svg';
import ModalCustom from '../../ModalCustom/ModalCustom';
import TermsAndConditionsModal from '../../TermsAndConditionsModal/TermsAndConditionsModal';
import { Currencies, PlanType, Regions, TimeFrequency } from '../../../constants/enums';
import useTermsAndConditions from '../../../hooks/useTermsAndConditions';
import { t } from '../../../i18n/i18n';
import { getPlanPrice } from '../../../utils/plans/price';
const PaymentPrice = (props) => {
  const {
    data,
    title,
    priceComponent,
    buttonComponent,
    description,
    acceptedTermsAndConditions,
    setAcceptedTermsAndConditions,
    showTermsAndConditionsModal,
    showTerms,
  } = props;
  return (
    <div className="d-flex flex-column align-items-center w-100 mx-2">
      <span
        className="font-montserrat text-sm font-weight-700 my-2"
        style={{ color: `#${data.color}`, textAlign: 'center' }}
      >
        {data.name}
      </span>
      <div
        className="d-flex flex-column align-items-center custom-column row m-0 w-100 shadow-sm py-2 px-2"
        style={{ border: `1px solid #${data?.color}` }}
      >
        <section className="p-2 d-flex flex-column align-items-center custom-column row m-0">
          <span
            className="font-montserrat text-sm font-weight-700"
            style={{ color: `#${data?.color}`, textAlign: 'center' }}
          >
            {title}
          </span>
          <span className=" font-montserrat text-lg font-weight-700 text-center text-nowrap my-2">
            {priceComponent}
          </span>
          {description && (
            <span
              className="font-montserrat text-sm font-weight-500 my-2 w-75"
              style={{ textAlign: 'center' }}
            >
              {description}
            </span>
          )}

          {showTerms && (
            <div className="d-flex my-2 w-100 p-0 justify-content-center">
              <input
                data-testid="TERMS_AND_CONDITIONS_CHECKBOX"
                className="cursor-pointer"
                type="checkbox"
                onChange={() => setAcceptedTermsAndConditions((state) => !state)}
                value={acceptedTermsAndConditions}
                defaultChecked={acceptedTermsAndConditions}
              />
              <span className="ml-2 font-montserrat text-sm text-gray">
                {t('HAVE_READ_AND_ACCEPTED')}
                <button
                  data-testid="TERMS_AND_CONDITIONS_BUTTON"
                  type="button"
                  onClick={() => showTermsAndConditionsModal()}
                  className="ml-1 border-0 cursor-pointer text-purple bg-transparent"
                >
                  {t('TERMS_AND_CONDITIONS')}
                </button>
              </span>
            </div>
          )}

          {buttonComponent}
        </section>
      </div>
    </div>
  );
};

const PaymentModal = ({
  showDeleteModal,
  setShowDeleteModal,
  contactSalesAction,
  data,
  refetch,
  setLoadingModal,
  showTerms = true,
  isInternationalPrice = true,
}) => {
  const [submitEmailLoading, setSubmitEmailLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  console.log(showDeleteModal);
  console.log(data);
  const {
    showModal,
    setShowModal,
    termsAndConditionsLoading,
    termsAndConditionsData,
    acceptedTermsAndConditions,
    setAcceptedTermsAndConditions,
    showTermsAndConditionsModal,
    closeModal,
  } = useTermsAndConditions({ platform: 'PARTNERS_PAYMENTS' });

  const priceInArs = data?.prices?.find((x) => x.currency == Currencies.ARS);
  const priceInUsd = data?.prices?.find((x) => x.currency == Currencies.USD);

  const priceToShow = isInternationalPrice ? priceInUsd : priceInArs;

  useEffect(() => {
    setLoading(false);
    setLoadingModal(false);
    setShowDeleteModal({ ...showDeleteModal, loading: false });
  }, []);

  const contactSales = async (planId) => {
    setSubmitEmailLoading(true);
    try {
      await contactSalesAction(planId);
      setShowDeleteModal({ show: false, id: null, loading: false });
    } catch (error) {
    } finally {
      setSubmitEmailLoading(false);
    }
  };

  return (
    <ModalCustom
      datatestid="modal-payment-buy-plan"
      datatestidbuttonok="contact-sales-button"
      show={showDeleteModal?.show}
      setShow={(show) => setShowDeleteModal({ show })}
      onOkText={
        submitEmailLoading ? t('CUSTOM_BUTTON_TEXT_LOADING') : t('HEADER_PROFILE_CONTACT_BUTTON')
      }
      onOkLeftIcon={<BiSupport color="white" />}
      onCancel={() => {
        setShowDeleteModal({ show: false, loading: false });
      }}
      onOk={() => {
        if (data) contactSales(data?.id);
      }}
      classModal="modal-create-workspace"
      showCross={true}
      input={
        <div className="my-4">
          <div className="d-flex flex-column align-center">
            <div className="d-flex flex-row justify-content-center">
              {loading ? (
                <>
                  <div className="ph-row ph-item w-100 d-flex flex-wrap justify-content-center">
                    <div
                      data-testid="skeleton-non-dashboard-column"
                      className="flex-grow-1 ph-col-3 hiper mx-1"
                    />
                  </div>
                </>
              ) : (
                <>
                  {isInternationalPrice || !priceInArs ? (
                    <PaymentPrice
                      data={data}
                      description={t('PAYMENTS_CONTACT_WITH_SALES')}
                      showTerms={false}
                      priceComponent={
                        !data?.prices || !data?.prices.length
                          ? t('NEGOTIABLE_PRICE_MESSAGE')
                          : data?.prices?.length && (
                              <>
                                <span
                                  className="font-montserrat font-weight-700 text-center text-nowrap"
                                  style={{ fontSize: '1.35rem' }}
                                >
                                  {getPlanPrice(priceInUsd, Regions.USA)}
                                </span>

                                <span className="ml-1 font-montserrat text-xs font-weight-600">
                                  {priceInUsd?.currency}
                                </span>
                                <div>
                                  {data?.type == PlanType.PROJECT ? (
                                    <div className="d-flex flex-column">
                                      <span className="font-montserrat text-xs font-weight-600">
                                        {t('PAYMENT_UNIQUE_PAYMENT_LABEL')}
                                      </span>
                                      <span className="font-montserrat text-xs font-weight-500 my-2">
                                        {`${t('PAYMENT_PLAN_DURATION_LABEL')} ${
                                          priceToShow?.renewalFrequencyInDays
                                        } ${t('PAYMENT_PLAN_DURATION_DAYS_LABEL')}`}
                                      </span>
                                    </div>
                                  ) : (
                                    <span className="font-montserrat text-xs font-weight-600">
                                      {t('PRICE_PER_MONTH_LABEL')}
                                    </span>
                                  )}
                                </div>
                                <div>
                                  <span className="font-montserrat text-xs font-weight-600">
                                    {showDeleteModal?.modality == TimeFrequency.ANNUAL &&
                                      data?.type == PlanType.SUBSCRIPTION &&
                                      t('PAYMENT_UNIQUE_PAYMENT_TOTAL_LABEL')}
                                  </span>
                                </div>
                              </>
                            )
                      }
                      buttonComponent={<></>}
                      acceptedTermsAndConditions={acceptedTermsAndConditions}
                      setAcceptedTermsAndConditions={setAcceptedTermsAndConditions}
                      showTermsAndConditionsModal={showTermsAndConditionsModal}
                    />
                  ) : priceInArs?.isRenewable ? (
                    <PaymentPrice
                      data={data}
                      title={t('PAYMENT_MENSUAL_PAYMENT_LABEL')}
                      description={t(
                        data?.mercadoPagoCheckOutSubscriptionUrl
                          ? 'PAYMENT_SUBSCRIPTION_DESCRIPTION_LABEL'
                          : 'PAYMENTS_CONTACT_WITH_SALES'
                      )}
                      priceComponent={
                        !data?.prices || !data?.prices?.length
                          ? t('NEGOTIABLE_PRICE_MESSAGE')
                          : data?.prices?.length && (
                              <>
                                <div>
                                  <span
                                    className="font-montserrat font-weight-700 text-center text-nowrap"
                                    style={{ fontSize: '1.35rem' }}
                                  >
                                    {getPlanPrice(priceInArs, Regions.ARGENTINA)}
                                  </span>

                                  <span className="ml-1 font-montserrat text-xs font-weight-600">
                                    {priceInArs?.currency}
                                  </span>
                                </div>
                                <span className="font-montserrat text-xs font-weight-600">
                                  {t('PRICE_PER_MONTH_LABEL')}
                                </span>
                              </>
                            )
                      }
                      buttonComponent={
                        data?.mercadoPagoCheckOutSubscriptionUrl && (
                          <button
                            disabled={!acceptedTermsAndConditions}
                            name="MP-payButton"
                            className="blue-ar-l-rn-none text-decoration-none rounded border-none px-4 py-2 text-white font-weight-700 my-2"
                            style={{ backgroundColor: '#009ee3' }}
                            onClick={() => {
                              window?.open(data?.mercadoPagoCheckOutSubscriptionUrl, '_blank');
                            }}
                          >
                            <div className="d-flex">
                              <div
                                className="mr-3"
                                style={{
                                  width: '1.5rem',
                                  height: '1.5rem',
                                  backgroundImage: `url('${mercadoPagoLogo}')`,
                                  backgroundSize: 'contain',
                                  backgroundPosition: 'center',
                                  backgroundRepeat: 'no-repeat',
                                }}
                              />
                              {t('PAYMENT_SUBSCRIBE_BUTTON_LABEL')}
                            </div>
                          </button>
                        )
                      }
                      showTerms={data?.mercadoPagoCheckOutSubscriptionUrl}
                      acceptedTermsAndConditions={acceptedTermsAndConditions}
                      setAcceptedTermsAndConditions={setAcceptedTermsAndConditions}
                      showTermsAndConditionsModal={showTermsAndConditionsModal}
                    />
                  ) : (
                    <PaymentPrice
                      data={data}
                      title={t('PAYMENT_UNIQUE_PAYMENT_LABEL')}
                      acceptedTermsAndConditions={acceptedTermsAndConditions}
                      setAcceptedTermsAndConditions={setAcceptedTermsAndConditions}
                      showTermsAndConditionsModal={showTermsAndConditionsModal}
                      showTerms={true}
                      priceComponent={
                        !data?.prices || !data?.prices.length
                          ? t('NEGOTIABLE_PRICE_MESSAGE')
                          : data?.prices?.length && (
                              <>
                                <span
                                  className="font-montserrat font-weight-700 text-center text-nowrap"
                                  style={{ fontSize: '1.35rem' }}
                                >
                                  {getPlanPrice(
                                    {
                                      priceInCents:
                                        priceInArs.renewalFrequencyInDays == 360
                                          ? priceInArs?.priceInCents * 12
                                          : priceInArs?.priceInCents,
                                      currency: priceInArs.currency,
                                    },
                                    Regions.ARGENTINA
                                  )}
                                </span>

                                <span className="ml-1 font-montserrat text-xs font-weight-600">
                                  {priceInArs?.currency}
                                </span>

                                <div>
                                  <span className="font-montserrat text-xs font-weight-600">
                                    {showDeleteModal?.modality == TimeFrequency.ANNUAL &&
                                      data?.type == PlanType.SUBSCRIPTION &&
                                      t('PAYMENT_UNIQUE_PAYMENT_TOTAL_LABEL')}
                                  </span>
                                </div>
                              </>
                            )
                      }
                      buttonComponent={
                        <div
                          className={`d-flex justify-content-center align-items-center text-center `}
                        >
                          <button
                            disabled={!acceptedTermsAndConditions}
                            name="MP-payButton"
                            className="blue-ar-l-rn-none text-decoration-none rounded border-none px-4 py-2 text-white font-weight-700 my-2"
                            style={{ backgroundColor: '#009ee3' }}
                            onClick={() => {
                              window?.open(data?.mercadoPagoCheckOutUrl, '_blank');
                            }}
                          >
                            <div className="d-flex">
                              <div
                                className="mr-3"
                                style={{
                                  width: '1.5rem',
                                  height: '1.5rem',
                                  backgroundImage: `url('${mercadoPagoLogo}')`,
                                  backgroundSize: 'contain',
                                  backgroundPosition: 'center',
                                  backgroundRepeat: 'no-repeat',
                                }}
                              />
                              {t('PAYMENT_PAY_BUTTON_LABEL')}
                            </div>
                          </button>
                        </div>
                      }
                    />
                  )}
                </>
              )}
            </div>
          </div>

          <TermsAndConditionsModal
            showModal={showModal}
            setShowModal={setShowModal}
            termsAndConditionsLoading={termsAndConditionsLoading}
            termsAndConditionsData={termsAndConditionsData}
            closeModal={closeModal}
          />
        </div>
      }
    />
  );
};

export default PaymentModal;

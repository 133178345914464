const Clock = () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.1004 0.29488C6.94168 0.824375 5.3532 1.74081 3.58142 3.53295C1.76892 5.32509 0.852483 6.93394 0.322987 9.19448C-1.44879 16.7296 4.29421 24 12.033 24C15.3729 24 18.1629 22.7985 20.5864 20.3546C22.9487 17.9719 24.0485 15.304 24.0485 11.9438C24.0485 10.559 23.9874 10.3349 23.6004 10.0702C22.5007 9.29631 21.9101 9.8869 21.7065 11.9845C21.4417 14.9171 20.7086 16.7092 18.9979 18.5421C17.1243 20.5379 14.8027 21.5562 12.033 21.5562C8.55054 21.5562 5.41429 19.7233 3.64252 16.6685C2.03367 13.8988 2.0744 9.90727 3.74434 7.05613C4.37567 5.95641 6.39182 4.02172 7.47118 3.47186C9.77245 2.31104 12.4199 2.06665 14.9859 2.84053C16.1875 3.18674 16.6355 3.24784 17.0021 3.06455C17.9185 2.65725 17.776 1.43533 16.7374 0.905836C15.0267 0.00976753 11.4424 -0.275347 9.1004 0.29488Z"
      fill="#303030"
    />
    <path
      d="M17.1857 7.34127L12.0333 12.4937L10.6484 11.1088C9.63019 10.1109 9.12106 9.74437 8.71375 9.74437C8.0417 9.74437 7.45111 10.5386 7.6344 11.2107C7.69549 11.4754 8.5712 12.4937 9.56909 13.4916C11.0965 15.019 11.5038 15.3041 12.0333 15.3041C12.6035 15.3041 13.3163 14.6728 18.3669 9.62218C23.2953 4.6938 24.0488 3.83846 24.0488 3.30896C24.0488 2.53508 23.7229 2.20924 22.9287 2.20924C22.3992 2.20924 21.6457 2.88129 17.1857 7.34127Z"
      fill="#303030"
    />
  </svg>
);

export default Clock;

import { t } from '../../../i18n/i18n';

const OptionText = ({ submission }) => {
  const textWithLineBreaks = (submission?.submittedText || '').replace(/\n/g, '<br/>');
  return (
    <>
      <span className="font-montserrat font-weight-600 text-xs text-color-item-test-group mt-2">
        {t('MODAL_ANSWER')}
      </span>
      <div className="d-inline p-2 mt-2 w-100 modal-container-text-custom">
        <span
          className="font-montserrat text-xs text-black text-break"
          dangerouslySetInnerHTML={{ __html: textWithLineBreaks }}
        />
      </div>
    </>
  );
};

export default OptionText;

import { t } from '../../i18n/i18n';
import './QuestionText.scss';
import useCreateScreening from '../../hooks/useCreateScreening';
import GenericToggle from '../GenericToggle/GenericToggle';
import HorizontalLoader from '../HorizontalLoader/HorizontalLoader';
import { AnswerTypeEnum, PlanFeaturesIds } from '../../constants/enums';
import InfoTooltip from '../InfoTooltip/InfoTooltip';
import DisabledFeature from '../DisabledFeature/DisabledFeature';
import { OverlayTrigger } from 'react-bootstrap';
import { Tooltip } from 'antd';

const QuestionText = ({ testGroupABM, testAnswer, setTestAnswer, aiImg }) => {
  const {
    handleAddQuestionText,
    handleCurrentTypeQuestion,
    handleGenerateAIAnswer,
    loadingAIAnswer,
    currentQuestionData,
    currentTypeQuestion,
  } = useCreateScreening();
  return (
    <div data-testid="question-text">
      <textarea
        name="text"
        className={`maya-blue-thin-border outline-none w-100 mt-3 p-2 px-3 text-xs font-montserrat ${
          currentQuestionData?.text?.length ? 'selected-border' : 'unselected-border'
        }`}
        rows="3"
        placeholder={t('CREATE_SCREENING_YOUR_QUESTIONS_TEXT_INPUT_PLACEHOLDER')}
        onChange={(e) => handleAddQuestionText(e)}
        value={currentQuestionData?.text ?? ''}
        maxLength={5000}
        data-testid="question-text-textarea"
      />
      <div className="row mt-3 justify-content-start">
        <p
          className="text-sm ml-3 italic font-montserrat mt-1"
          data-testid="question-text-max-char-answer-p"
        >
          {t('CREATE_SCREENING_MAX_CHAR_ANSWER')}:
        </p>
        <div
          className={`input-group div-input maya-blue-thin-border ml-3 b-radius-8 ${
            currentQuestionData?.maxChar?.length ? 'selected-border' : 'unselected-border'
          }`}
        >
          <input
            type="number"
            name="maxChar"
            value={currentQuestionData?.maxChar ?? ''}
            onKeyDown={(e) => {
              const excludedKeys = ['e', '+', '-', '.'];
              if (excludedKeys.includes(e.key)) {
                e.preventDefault();
              }
            }}
            onChange={(e) => handleAddQuestionText(e)}
            className="form-control-sm border-0 w-75 h-100 b-radius-8 max-char-input text-xs font-weight-700 font-montserrat"
            min={0}
            max={5000}
            data-testid="question-text-max-char-input"
          />
          <div className="input-group-append">
            <span
              className="font-montserrat text-xs font-weight-700 mt-2"
              data-testid="question-text-max-text-span"
            >
              Max
            </span>
          </div>
        </div>
      </div>
      {testGroupABM ? (
        <div className="d-flex">
          <span className="font-montserrat">{t('QUESTION_TEXT_TOGGLE_AI_CORRECTION')}</span>
          {aiImg && <img src={aiImg} alt="Additional Feature" className="imange-ai" />}
          <GenericToggle
            disabled
            actionFunction={() => {
              if (currentTypeQuestion !== AnswerTypeEnum.TEXT_AI) {
                handleCurrentTypeQuestion(AnswerTypeEnum.TEXT_AI);
              } else {
                handleCurrentTypeQuestion(AnswerTypeEnum.TEXT);
              }
            }}
            defaultActive={currentTypeQuestion === AnswerTypeEnum.TEXT_AI}
          />
        </div>
      ) : (
        <DisabledFeature
          className="d-flex flex-row"
          iconClassName="align-self-start"
          childrenClassname="mt-2 pt-1"
          featureId={PlanFeaturesIds.PROCTORING}
        >
          <div className="d-flex">
            <span className="font-montserrat">{t('QUESTION_TEXT_TOGGLE_AI_CORRECTION')}</span>
            {aiImg && <img src={aiImg} alt="Additional Feature" className="imange-ai" />}
            <GenericToggle
              disabled={testGroupABM}
              actionFunction={() => {
                if (currentTypeQuestion !== AnswerTypeEnum.TEXT_AI) {
                  handleCurrentTypeQuestion(AnswerTypeEnum.TEXT_AI);
                } else {
                  handleCurrentTypeQuestion(AnswerTypeEnum.TEXT);
                }
              }}
              defaultActive={currentTypeQuestion === AnswerTypeEnum.TEXT_AI}
            />
          </div>
        </DisabledFeature>
      )}
      {currentTypeQuestion === AnswerTypeEnum.TEXT_AI && (
        <div className="d-flex flex-column">
          <div className="d-flex mt-3 align-items-start">
            <div className="d-flex flex-column w-50 mr-2">
              <span className="font-montserrat text-xs min-height-ia-text d-flex align-items-start">
                {t('QUESTION_TEXT_TOGGLE_AI_ADD_CONCEPTS')}
                <InfoTooltip
                  className="ml-2"
                  placement="top"
                  content={
                    <div className="d-flex flex-column py-2">
                      <span className="font-montserrat text-xs mb-2">
                        {t('QUESTION_TEXT_TOGGLE_AI_ADD_CONCEPTS_FIRST_DISCLAIMER')}
                      </span>
                      <span className="font-montserrat text-xs">
                        {t('QUESTION_TEXT_TOGGLE_AI_ADD_CONCEPTS_SECOND_DISCLAIMER')}
                      </span>
                    </div>
                  }
                />
              </span>
              <textarea
                name="concepts"
                className={`maya-blue-thin-border outline-none w-100 mt-3 p-2 px-3 text-xs font-montserrat ${
                  currentQuestionData?.concepts?.length ? 'selected-border' : 'unselected-border'
                }`}
                rows="3"
                onChange={(e) => handleAddQuestionText(e)}
                value={currentQuestionData?.concepts ?? ''}
                maxLength={1000}
                data-testid="question-text-concepts-textarea"
              />
            </div>
            <div className="d-flex flex-column w-50 ml-2">
              <span className="font-montserrat text-xs min-height-ia-text d-flex align-items-start">
                {t('QUESTION_TEXT_TOGGLE_AI_ADD_TEST_ANSWER')}
                <InfoTooltip
                  className="ml-2"
                  placement="top"
                  content={
                    <div className="d-flex flex-column py-2">
                      <span className="font-montserrat text-xs mb-2">
                        {t('QUESTION_TEXT_TOGGLE_AI_ADD_TEST_ANSWER_DISCLAIMER')}
                      </span>
                    </div>
                  }
                />
              </span>
              <textarea
                name="testAnswer"
                className={`maya-blue-thin-border outline-none w-100 mt-3 p-2 px-3 text-xs font-montserrat ${
                  testAnswer?.text?.length ? 'selected-border' : 'unselected-border'
                }`}
                rows="3"
                onChange={(e) => setTestAnswer({ text: e.target.value })}
                value={testAnswer?.text ?? ''}
                maxLength={5000}
                data-testid="question-text-test-answer-textarea"
              />
              <div className="d-flex mt-2 align-items-center">
                <button
                  data-testid="question-text-add-test-answer-button"
                  onClick={async () => {
                    setTestAnswer({
                      ...testAnswer,
                      score: undefined,
                      explanation: undefined,
                    });
                    const data = await handleGenerateAIAnswer(testAnswer.text);
                    setTestAnswer({
                      ...testAnswer,
                      score: data.score,
                      explanation: data.explanation,
                    });
                  }}
                  type="button"
                  disabled={
                    !testAnswer?.text?.length ||
                    !currentQuestionData?.concepts?.length ||
                    !currentQuestionData?.text?.length ||
                    loadingAIAnswer
                  }
                  className="members-invite-submit-button font-montserrat text-xs align-self-start font-weight-bold py-2 px-4 rounded-pill text-white"
                >
                  {t('QUESTION_TEXT_TOGGLE_AI_ADD_TEST_ANSWER_BUTTON')}
                </button>
                <InfoTooltip
                  className="ml-2"
                  placement="top"
                  content={
                    <div className="d-flex flex-column py-2">
                      <span className="font-montserrat text-xs mb-2">
                        {t('QUESTION_TEXT_TOGGLE_AI_ADD_TEST_ANSWER_BUTTON_FIRST_DISCLAIMER')}
                      </span>
                      <span className="font-montserrat text-xs">
                        {t('QUESTION_TEXT_TOGGLE_AI_ADD_TEST_ANSWER_BUTTON_SECOND_DISCLAIMER')}
                      </span>
                    </div>
                  }
                />
                <div className="d-flex flex-column ml-3">
                  <span className="text-xs font-montserrat">
                    {t('QUESTION_TEXT_TOGGLE_AI_ADD_TEST_ANSWER_SCORE')}
                    {testAnswer?.score && `${testAnswer?.score * 10} %`}
                  </span>
                  <span className="text-xs font-montserrat">
                    {t('QUESTION_TEXT_TOGGLE_AI_ADD_TEST_ANSWER_EXPLANATION')}
                    {testAnswer?.explanation}
                  </span>
                </div>
              </div>
              {loadingAIAnswer && (
                <div className="w-100 d-flex justify-content-center mt-3">
                  <HorizontalLoader />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default QuestionText;

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import SideBarDropDown from '../SideBarDropDown/SideBarDropDown';
import { t } from '../../i18n/i18n';
import useRouteSelected from '../../hooks/useRouteSelected';
import sidebarRoutes from '../../constants/sidebarRoutes';
import useSidebar from '../../hooks/useSidebar';
import useRoles from '../../hooks/useRoles';
import useWhiteLabelSidebar from '../../whiteLabel/useWhiteLabelSidebar';
import chartIcon from '../../assets/sidebar/icons/Chart';
import homeIcon from '../../assets/sidebar/icons/Home';
import workspaceIcon from '../../assets/sidebar/icons/WorkspaceIcon';
import categoriesIcon from '../../assets/sidebar/icons/CategoriesIcon';

import './sidebar.scss';
import {
  CATEGORIES_TEST,
  CREATE_ABM_TESTGROUP_SCREENING,
  HOME,
  RESET_TEST_GROUP,
  ROLES_TESTGROUP,
  RoutesKeys,
  WORKSPACE,
} from '../../constants/routes';
import { Window } from '../../utils/window';
import WorkspaceDropdown from '../WorkspaceDropdown/WorkspaceDropdown';

const ShowRouteForWorkspaceRole = ({ roles = [], currentRole, children }) => {
  if (roles.includes(currentRole) || roles.length === 0) {
    return children;
  }
  return null;
};

const SideBar = () => {
  const { selected } = useRouteSelected(sidebarRoutes);
  const { collapsed, setCollapsed } = useSidebar();
  const { roles } = useRoles();
  const history = useHistory();
  const { routes } = useWhiteLabelSidebar();
  const [createTestGroupCondition, setCreateTestGroupCondition] = useState(true);
  const [resetTestGroupCondition, setResetTestGroupCondition] = useState(true);
  const user = useSelector((state) => state.auth.user);
  const { currentRole } = useSelector((state) => state.auth);

  const onClick = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    if (Window()) {
      setCreateTestGroupCondition(
        JSON.parse(localStorage.getItem('roles'))?.includes(roles.TESTGROUPCREATOR)
      );
      setResetTestGroupCondition(
        JSON.parse(localStorage.getItem('roles'))?.includes(roles.TESTGROUPRESTARTER)
      );
    }
  }, [user]);

  const filterSubroutes = (subRoutes) => {
    const filteredRoutes = subRoutes.filter((r) => {
      if (r.workspaceRoles) {
        return r.workspaceRoles.includes(currentRole);
      }
      return true;
    });
    return filteredRoutes;
  };

  return (
    <div
      data-testid="side-bar-component"
      className={`sidebar position-fixed h-100 non-selectable ${collapsed ? 'close-sidebar' : ''}`}
    >
      <div className="my-3 d-flex justify-content-end px-3" aria-hidden="true">
        <div
          type="button"
          data-testid="burger-button-menu"
          className={`nav-links__item burger-menu d-flex align-items-center menu-collapses d-flex justify-content-end unstyled-button p-2 main-nav-toggle ${
            !collapsed ? 'active-menu' : ''
          }`}
          onClick={onClick}
          aria-hidden="true"
        >
          <span className={`main-nav-toggle ${!collapsed ? 'active-menu' : ''}`}>
            <i className={`${!collapsed ? 'd-none' : 'd-block'}`}>-</i>
          </span>
        </div>
      </div>
      <div
        style={{ paddingBottom: '100px' }}
        className={`${!collapsed ? 'sidebar-scrollable' : ''}`}
      >
        <ul className="nav-links">
          <li
            className={`my-3 ${!collapsed && 'overflow-hidden'}`}
            aria-hidden="true"
            key={RoutesKeys.WORKSPACE}
            data-testid="li-sidebar-workspace"
          >
            <Link
              className="dropdown-link d-flex align-items-center bg-transparent border-0"
              to={WORKSPACE}
            >
              <SideBarDropDown
                icon={workspaceIcon}
                imgWidth="35"
                imgClassName="ml-2 pl-2"
                collapsed={collapsed}
                selected={history.location.pathname === WORKSPACE}
                buttonText={t('WORKSPACE_PAGE_BANNER')}
              />
            </Link>
          </li>
          <li
            data-testid="li-sidebar-workspace-dropdown"
            aria-hidden="true"
            key={`${RoutesKeys.WORKSPACE}-dropdown`}
          >
            <WorkspaceDropdown collapsed={collapsed} setCollapsed={setCollapsed} />
          </li>
          <li
            className={`my-3 ${!collapsed && 'overflow-hidden'}`}
            aria-hidden="true"
            key={RoutesKeys.HOME}
          >
            <Link
              className="dropdown-link d-flex align-items-center bg-transparent border-0"
              to={HOME}
            >
              <SideBarDropDown
                icon={homeIcon}
                imgWidth="35"
                imgClassName="ml-2 pl-1"
                collapsed={collapsed}
                selected={history.location.pathname === HOME}
                buttonText={t('HOME_PAGE_BANNER')}
              />
            </Link>
          </li>
          {!!routes?.length &&
            routes
              .filter((item) => !item.hide)
              .map((route) => (
                <li
                  className={`my-3 ${!collapsed && 'overflow-hidden'}`}
                  aria-hidden="true"
                  key={route.key}
                  data-testid={route.key}
                >
                  {route?.path ? (
                    <ShowRouteForWorkspaceRole
                      currentRole={currentRole}
                      roles={route?.workspaceRoles}
                    >
                      <Link to={route?.path} className="m-0">
                        <SideBarDropDown
                          icon={route?.icon}
                          buttonText={t(route.label)}
                          collapsed={collapsed}
                          selected={selected === route.key}
                          imgClassName="ml-3 pr-1"
                          options={filterSubroutes(route?.subRoutes ?? [])}
                        />
                      </Link>
                    </ShowRouteForWorkspaceRole>
                  ) : (
                    <ShowRouteForWorkspaceRole
                      currentRole={currentRole}
                      roles={route?.workspaceRoles}
                    >
                      <SideBarDropDown
                        icon={route?.icon}
                        buttonText={t(route.label)}
                        collapsed={collapsed}
                        selected={selected === route.key}
                        imgClassName={
                          route.key === RoutesKeys.SCREENING_INVITATIONS ? 'ml-1_2rem' : 'ml-3'
                        }
                        options={filterSubroutes(route?.subRoutes ?? [])}
                      />
                    </ShowRouteForWorkspaceRole>
                  )}
                </li>
              ))}
          {createTestGroupCondition && (
            <li
              className={`my-3 ${!collapsed && 'overflow-hidden'}`}
              aria-hidden="true"
              key={RoutesKeys.CREATE_ABM_TESTGROUP_SCREENING}
              id={RoutesKeys.CREATE_ABM_TESTGROUP_SCREENING}
            >
              <Link
                className="dropdown-link d-flex align-items-center bg-transparent border-0"
                to={CREATE_ABM_TESTGROUP_SCREENING}
              >
                <SideBarDropDown
                  icon={chartIcon}
                  imgClassName="ml-3"
                  collapsed={collapsed}
                  selected={history.location.pathname === CREATE_ABM_TESTGROUP_SCREENING}
                  buttonText={t('MENU_ITEM_CREATE_TEST_GROUP')}
                />
              </Link>
            </li>
          )}
          {createTestGroupCondition && (
            <li
              className={`my-3 ${!collapsed && 'overflow-hidden'}`}
              aria-hidden="true"
              key={RoutesKeys.CREATE_CATEGORIES}
              id={RoutesKeys.CREATE_CATEGORIES}
            >
              <Link
                className="dropdown-link d-flex align-items-center bg-transparent border-0"
                to={CATEGORIES_TEST}
              >
                <SideBarDropDown
                  icon={categoriesIcon}
                  imgClassName="ml-3"
                  collapsed={collapsed}
                  selected={history.location.pathname === CATEGORIES_TEST}
                  buttonText={t('CATEGORIES')}
                />
              </Link>
            </li>
          )}
          {createTestGroupCondition && (
            <li
              className={`my-3 ${!collapsed && 'overflow-hidden'}`}
              aria-hidden="true"
              key={RoutesKeys.CREATE_ROLES}
              id={RoutesKeys.CREATE_ROLES}
            >
              <Link
                className="dropdown-link d-flex align-items-center bg-transparent border-0"
                to={ROLES_TESTGROUP}
              >
                <SideBarDropDown
                  icon={categoriesIcon}
                  imgClassName="ml-3"
                  collapsed={collapsed}
                  selected={history.location.pathname === ROLES_TESTGROUP}
                  buttonText={t('Roles')}
                />
              </Link>
            </li>
          )}
          {resetTestGroupCondition && (
            <li
              className={`my-3 ${!collapsed && 'overflow-hidden'}`}
              aria-hidden="true"
              key={RoutesKeys.RESET_TEST_GROUP}
              id={RoutesKeys.RESET_TEST_GROUP}
            >
              <Link
                className="dropdown-link d-flex align-items-center bg-transparent border-0"
                to={RESET_TEST_GROUP}
              >
                <SideBarDropDown
                  icon={chartIcon}
                  imgClassName="ml-3"
                  collapsed={collapsed}
                  selected={history.location.pathname === RESET_TEST_GROUP}
                  buttonText={t('MENU_ITEM_RESET_TEST_GROUP')}
                />
              </Link>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default SideBar;

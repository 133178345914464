import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { notification, Select } from 'antd';
import { t } from '../../i18n/i18n';
import HorizontalLoader from '../HorizontalLoader/HorizontalLoader';
import Modal from '../Modal/Modal';
import { PartnerWorkspaceRole } from '../../constants/enums';
import { determineIconType } from '../../utils/determineIconWorkspace/determineIconWorkspace';
import { getPlanByWorkspaceId, moveScreeningToWorkspace } from '../../API/services/alkymersService';
import { HOME } from '../../constants/routes';
import { transferScreeningDraft } from '../../API/services/screeningService';

const ModalMoveScreening = ({ showModal, setShowModal, data, reloadSearches }) => {
  const [workspaceSelected, setWorkspaceSelected] = useState('');
  const [workspaceCompanySelected, setWorkspaceCompanySelected] = useState('');
  const { workspacesList, currentWorkspace } = useSelector((state) => state.auth);
  const [workspaceOptions, setWorkspaceOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [disableButton, setDisabledButton] = useState(true);
  const [creditLeftWarning, setCreditLeftWarning] = useState(null);
  const [workspaceToName, setWorkspaceToName] = useState(t('WORKSPACE_TO'));
  const { Option } = Select;
  const navigate = useHistory();

  useEffect(() => {
    const workspacesAuthorize = workspacesList.filter(
      (w) =>
        [PartnerWorkspaceRole.OWNER, PartnerWorkspaceRole.ADMINISTRATOR].includes(w.role) &&
        w.id !== currentWorkspace
    );
    setWorkspaceOptions(workspacesAuthorize);
  }, [workspacesList]);

  const onClickMove = async () => {
    setIsLoading(true);
    try {
      const options = {
        workspaceId: workspaceSelected,
      };
      if (data?.isDraft) {
        await transferScreeningDraft(data.id, {
          ...options,
          ...(workspaceCompanySelected && { companyId: workspaceCompanySelected }),
        });
      } else {
        await moveScreeningToWorkspace(data.id, options);
      }

      notification.open({
        message: t('SCREENING_TRANSFERED'),
        type: 'success',
      });
      setShowModal(false);
      if (reloadSearches !== undefined) {
        reloadSearches();
      } else {
        navigate.push(HOME);
      }
    } catch (e) {
      const message = e.response.data.message.toLowerCase();
      if (e.response.status > 400 && (message.includes('plan') || message.includes('workspace'))) {
        notification.open({
          message: t('ERROR_IN_WORKSPACE_PLANS'),
          type: 'error',
        });
      } else {
        notification.open({
          message: t('SCREENING_NOT_TRANSFERED_ERROR'),
          type: 'error',
        });
      }
      setIsLoading(false);
    }
  };

  const onSelectWorkspace = async (id) => {
    try {
      setDisabledButton(true);
      const workspacePlanInfo = await getPlanByWorkspaceId(id);
      const creditLeftAmount =
        workspacePlanInfo.screeningsAmount - workspacePlanInfo.screeningsUsed;
      const warningText = creditLeftAmount ?? t('LIMIT_CREDITS');
      setCreditLeftWarning(warningText);
    } catch (e) {
      setCreditLeftWarning(t('LIMIT_CREDITS'));
    }
    const actualWorkspace = workspaceOptions.find((x) => x.id === id);
    setWorkspaceToName(actualWorkspace.name ?? t('WORSKPACE_NAME'));
    setWorkspaceCompanySelected(actualWorkspace.companyId);
    setWorkspaceSelected(id);
    setDisabledButton(false);
  };

  return (
    <>
      <Modal
        setShow={() => setShowModal(false)}
        show={showModal}
        data-testid="card-screening-search-modal"
        className="modal-buttons-border d-flex justify-content-end"
        showCloseButton
        minWidth={false}
      >
        <div className="px-4 mx-4" id="move-screening-container">
          <div className="py-4 d-flex flex-column align-items-center">
            <span className="font-weight-bold font-montserrat text-base mb-2">
              {t('MOVE_SCREENING_TITLE')}
            </span>
            <span className="my-3 h5 font-montserrat font-weight-600 gray-subtitle text-sm line-jump px-2 px-lg-5">
              {t('MOVE_SCREENING_WARNING')}
              <ol className="text-light-black font-weight-500 text-left px-3 font-semibold mt-2 mb-1">
                <li>{t('MOVE_SCREENING_WARNING_FIRST')}</li>
                <li>{t('MOVE_SCREENING_WARNING_SECOND')}</li>
                {creditLeftWarning && !disableButton && (
                  <li className="text-danger">
                    {t('MOVE_SCREENING_WARNING_THIRD_ALERT')}
                    {` ${workspaceToName} `}
                    {t('MOVE_SCREENING_WARNING_THIRD_INFO').replace('XX', creditLeftWarning)}
                  </li>
                )}
              </ol>
            </span>
            <div className="py-2 column w-100 px-5 ml-4">
              <span className="font-montserrat font-weight-500 text-base row w-75 mb-2">
                {t('SELECT_WORKSPACE_TO_MOVE')}
              </span>
              <Select
                className="row mb-1"
                style={{
                  width: '100%',
                  fontFamily: 'Montserrat',
                }}
                placeholder={t('SELECT_ONE_WORKSPACE')}
                onChange={(value) => {
                  onSelectWorkspace(value);
                }}
                value={workspaceSelected}
                showArrow
                getPopupContainer={() => document.getElementById('move-screening-container')}
              >
                {(workspaceOptions ?? [])?.map((x) => (
                  <Option
                    key={x.id}
                    value={x.id}
                    className="d-flex bg-transparent border-0 p-0 w-100"
                    style={{
                      fontFamily: 'Montserrat',
                    }}
                  >
                    <div className="d-flex align-items-center bg-transparent border-0 p-0 w-80">
                      {determineIconType(x)}
                      <span className="ml-2 font-roboto text-dark font-weight-600 w-75">
                        {x.name} {x.companyDisplayName ? `| ${x.companyDisplayName}` : ''}
                      </span>
                    </div>
                  </Option>
                ))}
              </Select>
            </div>
            <div className="d-flex flex-row justify-content-center mt-4">
              <button
                type="button"
                className="button-left-modal px-1 px-lg-3 mr-3 mr-lg-5 "
                onClick={() => {
                  setShowModal(false);
                }}
              >
                <span className="px-3 py-1 text-button-left-modal font-roboto d-flex justify-content-center align-items-center">
                  {t('CANCEL')}
                </span>
              </button>

              {isLoading ? (
                <HorizontalLoader className="h-25 w-75 mt-3 mr-5" />
              ) : (
                <button
                  type="button"
                  className="button-right-modal px-1 px-lg-4"
                  onClick={onClickMove}
                  disabled={disableButton}
                >
                  <span className="px-3 py-1 text-button-right-modal text-white font-roboto d-flex justify-content-center align-items-center">
                    {t('CONFIRM_MOVE_SCREENING')}
                  </span>
                </button>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ModalMoveScreening;

import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TrackEvent } from '../../utils/filters/segmentUtils';
import FormInputSearch from '../FormInputSearch/FormInputSearch';
import './RecruitSessionDropdown.scss';
import ScienceIcon from '../../assets/sidebar/icons/ScienceIcon';
import { t } from '../../i18n/i18n';
import { CREATE_EVALUATION, SCREENING_SEARCHES } from '../../constants/routes';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const RecruitSessionDropdown = ({
  options,
  className,
  setOpenDropdown,
  openDropdown,
  disabled = false,
  createAssessmentInvitationAction,
}) => {
  const [searchRecruitSessionStages, setSearchRecruitSessionStages] = useState('');
  const [recruitSessionStagesFiltered, setRecruitSessionStagesFiltered] = useState([]);
  const history = useHistory();

  useEffect(() => {
    setRecruitSessionStagesFiltered(options);
  }, [options]);

  useEffect(() => {
    if (searchRecruitSessionStages.length > 2) {
      const filterData = options.filter((recruit) =>
        recruit.name.toLowerCase().includes(searchRecruitSessionStages.toLowerCase())
      );
      setRecruitSessionStagesFiltered(filterData);
    } else {
      setRecruitSessionStagesFiltered(options);
    }
  }, [searchRecruitSessionStages, options]);

  const dropdownRef = useRef();

  const handleClick = (item) => {
    const body = {
      id: item?.id,
      levelId: item?.levelId,
      name: item?.name,
      technologyId: item?.technologyId,
    };
    createAssessmentInvitationAction(body);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setOpenDropdown]);

  return (
    !disabled && (
      <div ref={dropdownRef} className="rounded font-roboto font-weight-bold non-selectable">
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip className="font-montserrat-text-tiny">
              {t('SELECT_CANDIDATE_TO_EVALUATE_SHORTCUT')}
            </Tooltip>
          }
        >
          <div
            onClick={() => {
              if (!disabled) {
                setOpenDropdown(!openDropdown);
              }
            }}
            aria-hidden
            className={`mx-2 d-flex ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
            disabled={disabled}
          >
            <ScienceIcon width="18" height="18" color={disabled ? '#828282' : '#58c1f5'} />
          </div>
        </OverlayTrigger>
        <ul
          className={`magnifying-glass-dropdown position-absolute bg-white overflow-auto m-0 ${className} ${
            openDropdown && 'open py-2'
          }`}
        >
          {recruitSessionStagesFiltered?.length > 0 ? (
            <>
              <li>
                <FormInputSearch
                  searchText={searchRecruitSessionStages}
                  setSearchText={setSearchRecruitSessionStages}
                  placeholder={t('EVALUATION_PLACEHOLDER')}
                  className="w-90 text-xs"
                />
              </li>
              {recruitSessionStagesFiltered.map((item, index) => (
                <li key={item.id} className="py-1 px-2 w-100 cursor-pointer dropdown-option">
                  <button
                    className="dropdown-btn d-flex align-items-center border-0 px-2 w-100"
                    onClick={() => handleClick(item)}
                    type="button"
                  >
                    <div className="row no-gutters w-100 justify-content-start align-items-center recruit-session-stage-options">
                      <div className="col-2 text-xs text-left">{index}.</div>
                      <div className="col-10 text-xs text-left">
                        <span className="pl-1 font-montserrat">{item.name}</span>
                      </div>
                    </div>
                  </button>
                </li>
              ))}
            </>
          ) : (
            <div className="flex align-items-center text-center">
              <span className="font-montserrat font-weight-500 mx-2 text-base text-center mb-3">
                {t('NON_EVALUATIONS_TO_SELECT_SHORTCUT')}
              </span>
              <button
                data-testid="button-create-search"
                className={`mt-3 font-montserrat font-weight-bold border-0 text-xs text-white px-3 py-1  rounded-pill h-25 ${
                  disabled ? 'bg-disabled-button' : 'bg-maya-blue'
                }`}
                type="button"
                disabled={disabled}
                onClick={() => {
                  TrackEvent('create-evaluation-dashboard');
                  history.push({
                    pathname: CREATE_EVALUATION,
                    state: {
                      from: SCREENING_SEARCHES,
                      fromName: 'MENU_ITEM_SEARCHES',
                    },
                  });
                }}
              >
                {t('CREATE_SEARCH')}
              </button>
            </div>
          )}
        </ul>
      </div>
    )
  );
};

export default RecruitSessionDropdown;

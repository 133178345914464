import { alkymetricsClient } from '../alkymetricsClient';

export const saveCompany = async (newData) => {
  const { data } = await alkymetricsClient.post('/partner/me', newData);
  return data;
};

export const getSavedSearches = async () => {
  const { data } = await alkymetricsClient.get('/filter/me');
  return data;
};

export const deleteSavedSearch = async (id) => {
  const { data } = await alkymetricsClient.delete(`/filter/me/${id}`);
  return data;
};

export const saveSearchRequest = async (searchData) => {
  const { data } = await alkymetricsClient.post('/filter/me', searchData);
  return data;
};

export const getVideos = async () => {
  const { data } = await alkymetricsClient.get('/assets', {
    params: {
      type: 'VIDEOS',
    },
    headers: {
      ...(process.env.RAZZLE_RUNTIME_ORIGIN_DEV
        ? { OriginDev: process.env.RAZZLE_RUNTIME_ORIGIN_DEV }
        : {}),
    },
  });
  return data;
};

export const getCustomCapsules = async () => {
  const { data } = await alkymetricsClient.get('/partner/courses');

  return data;
};

export const getMostHiresCompanies = async () => {
  const { data } = await alkymetricsClient.get(`/company/mostHires`);
  return data;
};

export const createCompany = async (companyData) => {
  const { data } = await alkymetricsClient.post('/company', companyData);
  return data;
};

export const getCompanyAssociatedWithEmail = async (email) => {
  const { data } = await alkymetricsClient.get(`/company/partnerEmail/${email}`);
  return data;
};

export const getCommunityTopProfiles = async (quantity) => {
  const { data } = await alkymetricsClient.get(`/users/top/community?quantity=${quantity}`);
  return data;
};

export const getUserCompanyComments = async (userId) => {
  const { data } = await alkymetricsClient.get(`/comment/company/user/${userId}`);
  return data;
};

export const createUserCompanyComment = async (text, userId) => {
  const { data } = await alkymetricsClient.post('/comment/company/user', {
    comment: text,
    userId,
  });
  return data;
};

export const deleteUserCompanyComment = async (commentId) => {
  const { data } = await alkymetricsClient.delete(`/comment/company/${commentId}`);
  return data;
};

export const editSearchStage = async (stageId, stageData = {}) => {
  const { data } = await alkymetricsClient.patch(`/recruitSessionStage/${stageId}`, stageData);
  return data;
};

export const getCompaniesPaginated = async ({ search = '', offset = 0, limit = 15 }) => {
  const { data } = await alkymetricsClient.get('/company/paginated', {
    params: {
      search,
      offset,
      limit,
    },
  });
  return data;
};

export const getCompanySearches = async ({ companyId, search = '', offset = 0, limit = 15 }) => {
  const { data } = await alkymetricsClient.get('/recruitSession/company', {
    params: {
      companyId,
      search,
      offset,
      limit,
    },
  });
  return data;
};

export const getSearchInvitations = async ({ search = '', stage, offset = 0, limit = 15 }) => {
  const { data } = await alkymetricsClient.get('/assessmentInvitation/recruitSessionStage', {
    params: {
      search,
      stage,
      offset,
      limit,
    },
  });
  return data;
};

export const getWorkspaceMembers = async ({ search = '', offset = 0, limit = 15 }) => {
  try {
    const { data } = await alkymetricsClient.get('/workspace/members', {
      params: {
        search,
        offset,
        limit,
      },
    });
    return data;
  } catch (error) {
    return { collection: [], pagination: null };
  }
};

export const transferWorkspaceProperty = async ({ currentOwnerId, newOwnerId }) => {
  try {
    const { data } = await alkymetricsClient.patch(`/workspace/transferProperty`, {
      currentOwnerId,
      newOwnerId,
    });
    return data;
  } catch (error) {
    return { collection: [], pagination: null };
  }
};

export const acceptRequestAccessWorkspace = async (body) => {
  const { data } = await alkymetricsClient.post(
    `/workspace/request/acceptWorkspaceInvitation`,
    body
  );
  return data;
};

export const getWorkspaceDuplicatedInvitations = async (workspaceId, emails) => {
  try {
    if (!emails.length) return [];
    const { data } = await alkymetricsClient.post(`/workspace/invitation/duplicated`, {
      workspaceId,
      emails,
    });
    return data;
  } catch (error) {
    return [];
  }
};

export const createWorkspaceInvitationsBatch = async (workspaceId, emails, role) => {
  if (!emails.length) return [];
  const { data } = await alkymetricsClient.post(`/workspace/invitation/batch`, {
    workspaceId,
    emails,
    role,
  });
  return data;
};

export const deleteWorkspaceMember = async (email, partnerId, workspaceId) => {
  try {
    await alkymetricsClient.delete(`/workspace/member`, {
      data: { email, partnerId, workspaceId },
    });
    return true;
  } catch (e) {
    throw new Error(e);
  }
};

export const acceptWorkspaceInvitationsBatch = async () => {
  try {
    const { data } = await alkymetricsClient.post(`/workspace/invitation/accept`);
    return data;
  } catch (error) {
    return [];
  }
};

export const reOpenEvaluation = async (recruitSessionId) => {
  try {
    const { data } = await alkymetricsClient.post(`recruitsession/${recruitSessionId}/open`);
    return data;
  } catch (error) {
    return [];
  }
};

export const getCompanySizes = async () => {
  const data = await alkymetricsClient.get(`/company/sizes`);
  return data?.data;
};

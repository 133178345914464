import { useRef, useState } from 'react';
import { notification } from 'antd';
import XLSX from 'xlsx';
import { t } from '../i18n/i18n';
import moment from 'moment';

const useImportCsv = (keys, setLoading = () => {}) => {
  const [result, setResult] = useState(null);
  const importRef = useRef(null);

  const formatDate = (date) => {
    let daysSince1900 = Math.floor(date);
    let fractionalDay = date - daysSince1900;
    let baseDate = moment('1900-01-01');
    let excelMoment = baseDate.add(daysSince1900 - 2, 'days');
    let totalSeconds = fractionalDay * 24 * 60 * 60;
    let hours = Math.floor(totalSeconds / 3600);
    let minutes = Math.floor((totalSeconds % 3600) / 60);
    let seconds = totalSeconds % 60;
    excelMoment = excelMoment.add(hours, 'hours').add(minutes, 'minutes').add(seconds, 'seconds');
    return excelMoment.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
  };

  const excelToJson = async (data) => {
    try {
      const returnData = [];
      data.forEach((row) => {
        if (row.length > 0) {
          returnData.push(
            keys.reduce((acum, key, index) => ({ ...acum, [key]: row[index] ?? '' }), {})
          );
        }
      });
      return returnData;
    } catch {
      notification.open({
        message: t('SCREENING_IMPORT_FILE_ERROR'),
        icon: <i className="fas fa-times" style={{ color: 'red' }} />,
      });
      return [];
    }
  };

  const validateFile = (file) =>
    ['text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(
      file?.type
    );

  const readFile = () => {
    setLoading(true);
    const file = importRef.current.files[0];
    if (!validateFile(file)) {
      notification.open({
        message: t('SCREENING_IMPORT_FILE_ERROR'),
        icon: <i className="fas fa-times" style={{ color: 'red' }} />,
      });
      setLoading(false);
      return;
    }
    const reader = new FileReader();
    reader.addEventListener('load', async (event) => {
      const binaryString = event.target.result;
      const workBook = XLSX.read(binaryString, { type: 'binary', raw: true });
      const workSheetName = workBook.SheetNames[0];
      const workSheet = workBook.Sheets[workSheetName];
      const fileData = XLSX.utils.sheet_to_json(workSheet, { header: 1 });
      const json = await excelToJson(fileData);
      if (json) {
        setResult(json);
      }
    });
    reader.readAsBinaryString(file);
    importRef.current.value = '';
  };

  return { result, readFile, importRef, setResult };
};

export default useImportCsv;

import { useEffect, useState } from 'react';
import './Checkbox.scss';

const Checkbox = ({ className = '', onClick, checked, data, testid }) => {
  const [checkedInput, setCheckedInput] = useState(checked ?? false);
  useEffect(() => {
    setCheckedInput(checked);
  }, [checked]);
  return (
    <label data-testid={testid} className={`checkbox bounce mb-0 ${className}`}>
      <input
        type="checkbox"
        checked={checkedInput}
        readOnly
        onClick={(e) => {
          if (onClick) {
            onClick(e);
            setCheckedInput(!checkedInput);
          }
        }}
        {...data}
        data-testid="checkbox-input"
      />
      <svg viewBox="0 0 21 21">
        <polyline points="5 10.75 8.5 14.25 16 6" />
      </svg>
    </label>
  );
};

export default Checkbox;

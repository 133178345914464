import React from 'react';

const AssignedWeightIcon = ({ classNames }) => (
  <svg
    className={classNames}
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="17" height="16" rx="8" fill="#3D8C6B" />
    <path
      d="M4.5 7.5L7.5 10.5L12.5 5.5"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default AssignedWeightIcon;

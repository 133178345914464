export const findDuplicates = (arry) =>
  arry.filter((item, index) => arry.indexOf(item) !== index && item !== '' && item);

export const uniqueArray = (arr) => [...new Set(arr)];

export const chunkArray = (arr, chunkSize) => {
  const response = [];
  for (let i = 0; arr.length > i; i += chunkSize) {
    response.push(arr.slice(i, i + chunkSize));
  }
  return response;
};

export const sortArrayAlphabeticallyByPropertyName = (roles, prop) => {
  if (!Array.isArray(roles)) {
    return [roles];
  }
  return roles.sort((a, b) => {
    const aDisplayName = a[prop].toLowerCase();
    const bDisplayName = b[prop].toLowerCase();

    return aDisplayName < bDisplayName ? -1 : aDisplayName > bDisplayName ? 1 : 0;
  });
};

export const invertMatrix = (matrix = []) => {
  if (!matrix.length) return matrix;
  const invertedMatrix = [];
  for (let i = 0; i < matrix[0].length; i += 1) {
    invertedMatrix[i] = [];
    for (let j = 0; j < matrix.length; j += 1) {
      invertedMatrix[i][j] = matrix[j][i];
    }
  }
  return invertedMatrix;
};

export const removeDuplicates = (arr) => [...new Set(arr)];

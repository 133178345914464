import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ArrowSmall from '../../assets/screening/ArrowSmall';
import HeadsetAudioIcon from '../../assets/testgroupABM/HeadsetAudioIcon';
import DeleteForever from '../../assets/screening/DeleteForever';
import EditPen from '../../assets/screening/EditPen';
import TooltipIcon from '../../assets/companyProfile/tooltip.svg';
import { t } from '../../i18n/i18n';
import { AnswerTypeEnum, TestFileType } from '../../constants/enums';
import { formatAudioTimeSeconds } from '../../utils/formatSecondsToSSMM';
import './TestGroupsItem.scss';
import Checkbox from '../Checkbox/Checkbox';
import ImageFileIcon from '../../assets/testgroupABM/ImageFileIcon';

const TestGroupsItem = ({
  test,
  disableOrderUp,
  disableOrderDown,
  handleOrder,
  handleDelete,
  handleEdit,
  testGroupABM,
  isComplementaryQuestion,
  handleSelectTest,
}) => (
  <div className="d-flex w-100 align-items-center justify-content-between">
    <div className="maya-blue-thin-border align-items-center d-flex justify-content-between px-4 py-3 my-1 flex-grow-1 flex-wrap">
      <span
        className="text-muted font-montserrat text-xs font-weight-bold text-truncate test-name-max-width mb-2 mb-md-0"
        data-testid="tg-item-text-span"
        title={test.text}
      >
        {`${testGroupABM ? test.order + 1 : test.order} | ${
          !testGroupABM ? '' : test.id ? `${test.id} |` : 'Grupo Pregunta |'
        } ${test.text}`}
      </span>
      <div data-testid="test-groups-item-details-container" className="d-flex">
        {testGroupABM ? (
          <div className="d-flex">
            {test?.theme && (
              <span
                className="theme-question-div rounded font-montserrat border-0 py-1 px-2 font-weight-bold my-1 mr-2"
                data-testid="tg-item-theme-span"
              >
                {test?.theme}
              </span>
            )}

            <span
              className="level-question-div rounded font-montserrat border-0 py-1 px-2 font-weight-bold my-1 mr-2"
              data-testid="tg-item-difficulty-span"
            >
              {test.questionDifficulty || test.difficultyId
                ? test.questionDifficulty || test.name
                : t('EDIT_TG_PENDING_QUESTION_DIFFICULTY')}
            </span>

            {test?.listeningTest && (
              <span
                data-testid="listening-test-span"
                className="listening-question-div d-flex align-items-center rounded font-montserrat border-0 py-1 px-2 font-weight-bold my-1 mr-2"
              >
                <HeadsetAudioIcon className="mr-1" />
                {test?.testFiles?.some((file) => file.fileType === TestFileType.AUDIO)
                  ? formatAudioTimeSeconds(
                      test.testFiles
                        .filter((file) => file.fileType === TestFileType.AUDIO)
                        .reduce((acum, file) => acum + file.audioDuration, 0)
                    )
                  : t('TESTGROUP_ABM_QUESTION_WITH_AUDIO')}
              </span>
            )}
            {test?.langCode && (
              <span
                data-testid="code-test-span"
                className="code-question-div d-flex align-items-center rounded font-montserrat border-0 py-1 px-2 font-weight-bold my-1 mr-2"
              >
                {test?.langDisplayName?.replace('_', ' ')}
              </span>
            )}
          </div>
        ) : (
          <span
            className="font-montserrat text-xs mr-3 maya-blue-thin-border text-nowrap font-weight-bold px-3 py-2"
            data-testid="tg-item-answer-type-span"
          >
            {t(`CREATE_SCREENING_YOUR_QUESTIONS_${test.answerType}`)}
          </span>
        )}
        {handleEdit &&
          ([
            AnswerTypeEnum.CHECK,
            AnswerTypeEnum.RADIO,
            AnswerTypeEnum.TEXT_AI,
            AnswerTypeEnum.VIDEO,
            AnswerTypeEnum.WRITE,
          ].includes(test.answerType) ? (
            <div className="d-flex align-items-center justify-content-center">
              {test?.testFiles?.length > 0 &&
                test?.testFiles?.map((file, index) => {
                  if (file?.fileType === 'IMAGE') {
                    return <ImageFileIcon key={index} className="mr-2" />;
                  }
                  return null;
                })}
            </div>
          ) : (
            ''
          ))}
        {handleEdit &&
          (isComplementaryQuestion ||
          [
            AnswerTypeEnum.CHECK,
            AnswerTypeEnum.RADIO,
            AnswerTypeEnum.TEXT_AI,
            AnswerTypeEnum.VIDEO,
            AnswerTypeEnum.WRITE,
            AnswerTypeEnum.FILE,
            AnswerTypeEnum.CODE,
          ].includes(test.answerType) ? (
            <button
              className="bg-transparent border-0 outline-none mr-1"
              onClick={handleEdit}
              type="button"
              data-testid="tg-item-edit-btn"
            >
              <EditPen />
            </button>
          ) : (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>{t('TOOLTIP_QUESTION_NO_TYPE_MULTIPLE')}</Tooltip>}
            >
              <button
                className="bg-transparent p-0 m-0 border-0 outline-none"
                data-testid="tg-item-icon-info-btn"
                type="button"
              >
                <img src={TooltipIcon} alt={t('ALT_ICON_INFO')} data-testid="" />
              </button>
            </OverlayTrigger>
          ))}
        {handleEdit &&
          (!isComplementaryQuestion &&
          [
            AnswerTypeEnum.CHECK,
            AnswerTypeEnum.RADIO,
            AnswerTypeEnum.TEXT_AI,
            AnswerTypeEnum.VIDEO,
            AnswerTypeEnum.WRITE,
            AnswerTypeEnum.CODE,
          ].includes(test.answerType) ? (
            <div className="d-flex align-items-center justify-content-center">
              <Checkbox
                className="search-dashboard-checkbox"
                checked={test?.selected}
                onClick={() => handleSelectTest(test.id)}
              />
            </div>
          ) : (
            ''
          ))}
      </div>
    </div>
    <div className="ml-2 pl-1">
      {handleOrder && (
        <>
          <button
            disabled={disableOrderDown}
            className="bg-transparent border-0 outline-none mr-1"
            type="button"
            onClick={() => handleOrder(test.order, test.order + 1, 'customTestGroup')}
            data-testid="tg-item-disabled-order-down-btn"
          >
            <ArrowSmall color={disableOrderDown ? '#828282' : '#4bafe1'} />
          </button>
          <button
            disabled={disableOrderUp}
            className="bg-transparent border-0 outline-none ml-1"
            type="button"
            onClick={() => handleOrder(test.order, test.order - 1, 'customTestGroup')}
            data-testid="tg-item-disabled-order-up-btn"
          >
            <ArrowSmall classNames="rotate-180" color={disableOrderUp ? '#828282' : '#4bafe1'} />
          </button>
        </>
      )}
      <button
        onClick={handleDelete}
        type="button"
        className="bg-transparent border-0 outline-none ml-3"
        data-testid="tg-item-delete-btn"
      >
        <DeleteForever />
      </button>
    </div>
  </div>
);
export default TestGroupsItem;

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useLottie } from 'lottie-react';
import rocketWithSuccess from '../../assets/lottie/rocket-with-success-animation.json';
import './CreateScreeningStepSuccess.scss';
import { t } from '../../i18n/i18n';
import useCreateScreening from '../../hooks/useCreateScreening';
import { CREATE_SEARCH } from '../../constants/routes';
import { TrackEvent } from '../../utils/filters/segmentUtils';
import { setCreateScreeningFromSearches } from '../../redux/actions/screeningActions';

const CreateScreeningStepSuccess = () => {
  const { newScreening } = useCreateScreening();
  const { createScreeningFromSearches } = useSelector((state) => state.screenings);
  const dispatch = useDispatch();
  const history = useHistory();
  const options = {
    animationData: rocketWithSuccess,
    loop: true,
  };

  const { View } = useLottie(options);

  useEffect(
    () => () => {
      dispatch(setCreateScreeningFromSearches(false));
    },
    []
  );

  return (
    <div className="d-flex flex-column align-items-center py-4">
      <div className="lottie-container">{View}</div>
      <div className="my-3 d-flex flex-column align-items-center">
        <span className="my-1 font-montserrat font-weight-bold text-xl">
          {t('CREATE_SCREENING_SUCCESS_STEP_TITLE')}
        </span>
        <span className="my-1 font-montserrat text-muted text-sm font-italic">
          {t('CREATE_SCREENING_SUCCESS_STEP_SUBTITLE').replace(
            '{screeningName}',
            `"${newScreening?.name}"`
          )}
        </span>
      </div>
      <div>
        <button
          onClick={() => {
            TrackEvent('create-screening-to-searches');
            history.push(CREATE_SEARCH);
          }}
          className="mt-4 align-self-end px-3 py-1 rounded-pill font-roboto text-white text-sm font-weight-bold border-0 outline-none bg-maya-blue"
          type="button"
        >
          {createScreeningFromSearches
            ? t('CREATE_SCREENING_SUCCESS_STEP_GO_TO_SEARCHS')
            : t('CREATE_SCREENING_SUCCESS_STEP_GO_TO_SEARCHS_FROM_SEARCHES')}
        </button>
      </div>
    </div>
  );
};

export default CreateScreeningStepSuccess;

const LinkedinIcon = ({ color = '#58c1f5' }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5727_32648)">
      <path
        d="M12 0C5.373 0 0 5.37225 0 12C0 18.627 5.373 24 12 24C18.627 24 24 18.627 24 12C24 5.37225 18.627 0 12 0ZM8.883 16.7078H6.435V9.34275H8.883V16.7078ZM7.659 8.337H7.64325C6.82125 8.337 6.29025 7.77075 6.29025 7.06425C6.29025 6.342 6.83775 5.79225 7.67475 5.79225C8.51175 5.79225 9.02775 6.342 9.0435 7.06425C9.0435 7.7715 8.51175 8.337 7.659 8.337ZM17.7098 16.7078H15.2618V12.7673C15.2618 11.7773 14.9078 11.1023 14.0212 11.1023C13.3455 11.1023 12.9427 11.5575 12.7657 11.9977C12.7013 12.1552 12.6847 12.375 12.6847 12.5947V16.7078H10.2375C10.2375 16.7078 10.2697 10.0343 10.2375 9.34275H12.6855V10.386C13.0103 9.88425 13.5923 9.1695 14.892 9.1695C16.5023 9.1695 17.7098 10.2225 17.7098 12.4845V16.7078Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_5727_32648">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default LinkedinIcon;

import React from 'react';
import useCreateScreening from '../../hooks/useCreateScreening';
import { t } from '../../i18n/i18n';
import MultipleSelect from '../MultipleSelect/MultipleSelect';

const QuestionWithFiles = () => {
  const { fileTypes, currentQuestionData, handleAddFileTypes, handleAddQuestionText } =
    useCreateScreening();

  return (
    <div className="d-flex flex-column align-items-start">
      <textarea
        type="text"
        onChange={handleAddQuestionText}
        name="text"
        value={currentQuestionData?.text ?? ''}
        className={`w-100 mt-3 mb-1 py-2 px-3 font-montserrat maya-blue-thin-border outline-none text-xs ${
          currentQuestionData?.text?.length ? 'selected-border' : 'unselected-border'
        }`}
        placeholder={t('CREATE_SCREENING_YOUR_QUESTIONS_FILE_INPUT_PLACEHOLDER')}
        rows="3"
        data-testid="question-wf-textarea"
      />
      <MultipleSelect
        selectedList={currentQuestionData?.fileTypes}
        title={t('CREATE_SCREENING_YOUR_QUESTIONS_FILE_MULTIPLE_SELECT_PLACEHOLDER')}
        classNames="my-3"
        onSelect={(e) => handleAddFileTypes(e.id)}
        options={fileTypes}
      />
      <div>
        <span
          className="font-montserrat text-sm text-muted font-weight-500"
          data-testid="question-wf-accepted-file-span"
        >
          {t('CREATE_SCREENING_YOUR_QUESTIONS_FILE_ACCEPTED_FILES')}
        </span>
        {currentQuestionData?.fileTypes?.map((item, index) => (
          <span
            key={item}
            className="total-number-text font-montserrat text-sm font-weight-500"
            data-testid="question-wf-your-files-span"
          >
            {index !== currentQuestionData?.fileTypes?.length - 1
              ? `${t(`CREATE_SCREENING_YOUR_QUESTIONS_FILE_TYPE_${item}_DISPLAY`)}, `
              : `${t(`CREATE_SCREENING_YOUR_QUESTIONS_FILE_TYPE_${item}_DISPLAY`)}.`}
          </span>
        ))}
      </div>
    </div>
  );
};

export default QuestionWithFiles;

import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import BackgroundTop from '../../components/BackgroundTop/BackgroundTop';
import { t } from '../../i18n/i18n';
import {
  getCustomSearchs,
  getSearchesQuantity,
  getDraft,
} from '../../API/services/screeningService';
import {
  setKeepSearchData,
  setNewSearchData,
  setSearches,
  setSelectedSearch,
  setStageCandidates,
} from '../../redux/actions/screeningActions';
import processQueryString from '../../utils/processQueryString';
import verifiedSvg from '../../assets/screeningSearchs/verified.svg';
import searchSvg from '../../assets/profile/search.svg';
import navigateLeftSvg from '../../assets/screeningSearchs/navigateLeft.svg';
import navigateRightSvg from '../../assets/screeningSearchs/navigateRight.svg';
import EmptyComponent from '../../components/EmptyComponent/EmptyComponent';
import Spinner from '../../components/spinner/Spinner';
import { setAppliedFilters } from '../../redux/actions/profilesActions';
import { CREATE_EVALUATION, SCREENING_SEARCHES } from '../../constants/routes';
import ButtonFilters from '../../components/ButtonFilters/ButtonFilters';
import { isNullOrUndefined } from '../../utils/comparisons';
import CardScreeningSearch from '../../components/CardScreeningSearch/CardScreeningSearch';
import { TrackEvent } from '../../utils/filters/segmentUtils';
import { disableForWorkspaceRole } from '../../utils/workspaceRoleValidation';
import { PartnerWorkspaceRole } from '../../constants/enums';

const Screening = () => {
  const history = useHistory();
  const { currentWorkspace, currentRole } = useSelector((state) => state.auth);
  const { collection, pagination } = useSelector((state) => state.screenings.searches);
  const [searchs, setSearchs] = useState(collection);
  const [searchesQuantity, setSearchesQuantity] = useState({});
  const [pag, setPag] = useState(pagination?.total);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState('');
  const [search, setSearch] = useState('');
  const [reload, setReload] = useState(false);
  const [buttonSelected, setButtonSelected] = useState(0);
  const dispatch = useDispatch();

  const handleRequest = async (offset = '0', active, newPage = 1, search = '') => {
    await getCustomSearchs({ offset, active, search }).then((res) => {
      dispatch(setSearches(res));
      setSearchs(res?.collection.map((res) => ({ ...res, isDraft: false })));
      setCurrentPage(newPage);
      setPag(res.pagination.total);
      setSearch(search);
    });
  };

  const handleRequestDraft = async (offset = '0', newPage = 1, search = '') => {
    await getDraft({
      offset,
      search,
      workspaceIds: currentWorkspace,
    }).then((res) => {
      dispatch(setSearches(res));
      setSearchs(res?.collection?.map((res) => ({ ...res, isDraft: true })));
      setCurrentPage(newPage);
      setPag(res.pagination.total);
      setSearch(search);
    });
  };

  useEffect(async () => {
    setLoading(true);
    dispatch(setKeepSearchData(false));
    dispatch(setSelectedSearch({}));
    dispatch(setStageCandidates(null));

    const params = processQueryString(history.location.search)?.page;
    const statusParam = processQueryString(history.location.search)?.active;
    const searchParam = processQueryString(history.location.search)?.search;

    await getSearchesQuantity().then((res) => setSearchesQuantity(res ?? {}));

    let offset;
    let active;
    if (params) {
      offset = Number((params - 1) * 6);
      setCurrentPage(Number(params));
    } else {
      offset = 0;
      setCurrentPage(1);
    }
    if (statusParam === 'true' || statusParam === 'false') {
      setStatus(statusParam === 'true' ? 'active' : 'inactive');
      active = statusParam;
    } else {
      setStatus('active');
      active = true;
    }
    if (searchParam) {
      setSearch(searchParam);
    } else {
      setSearch('');
    }
    await getCustomSearchs({ offset, active, search: searchParam }).then(async (res) => {
      if (!res?.collection?.length && offset > 0) {
        handleRequest('0', '', 1);
        history.push('/screening/searches?page=1');
      } else {
        setSearchs(res?.collection?.map((res) => ({ ...res, isDraft: false })));
        setPag(res?.pagination?.total);
        dispatch(setSearches(res));
      }
    });
    setButtonSelected(0);
    setLoading(false);
    setReload(false);
  }, [reload, currentWorkspace]);

  useEffect(() => {
    dispatch(setAppliedFilters([]));
  }, []);

  const goToPage = async (offset, page) => {
    if ([0, 1].includes(buttonSelected)) {
      handleRequest(
        offset,
        status && status !== 'all' ? (status === 'active' ? 'true' : 'false') : '',
        page,
        search
      );
    } else {
      handleRequestDraft(offset, page, search);
    }
    history.push(
      `/screening/searches?page=${page}${
        status && status !== 'all' ? (status === 'active' ? '&active=true' : '&active=false') : ''
      }${search ? `&search=${search}` : ''}`
    );
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if ([0, 1].includes(buttonSelected)) {
      handleRequest(
        0,
        status && status !== 'all' ? (status === 'active' ? 'true' : 'false') : '',
        1,
        search
      );
    } else {
      handleRequestDraft(0, 1, search);
    }
    history.push(
      `/screening/searches?page=1${
        status && status !== 'all' ? (status === 'active' ? '&active=true' : '&active=false') : ''
      }${search ? `&search=${search}` : ''}`
    );
  };

  const onClick = async (index) => {
    setLoading(true);

    try {
      if ([0, 1].includes(index)) {
        setStatus(!index ? 'active' : 'inactive');
        await handleRequest(0, !index, 1, search);
        history.push({
          pathname: '/screening/searches',
          params: {
            page: 1,
            search,
            active: !index,
          },
        });
      } else {
        setStatus('all');
        await handleRequestDraft(0, 1, search);
        history.push({
          pathname: '/screening/searches',
          params: {
            page: currentPage,
            search,
          },
        });
      }
      setButtonSelected(index);
    } catch (error) {
      console.log('Error', error);
    } finally {
      setLoading(false);
    }
  };

  const reloadSearches = () => {
    setReload(true);
  };

  return (
    <div className="position-relative w-100 pb-5 screening-page-container">
      <div>
        <BackgroundTop
          title={t('MENU_ITEM_SEARCHES')}
          routes={[
            {
              text: 'MENU_ITEM_SCREENING',
              clickable: false,
            },
          ]}
          className="position-relative"
          titleClassname="text-xl"
        />
      </div>
      <div className="px-2 position-relative pl-3 pr-3">
        <div className="m-auto">
          <div className="bg-white rounded px-4 py-4">
            <div className="d-flex flex-column">
              <div>
                <div className="d-flex flex-row justify-content-between">
                  <h3 className="font-montserrat font-weight-bold text-xl">
                    {t('SEARCHES_CREATED')}
                  </h3>
                </div>
                {!isNullOrUndefined(searchesQuantity?.open) && (
                  <div>
                    <img src={verifiedSvg} alt={t('ICON_VERIFIED_ALT_TEXT')} />
                    <span className="text-create-search-screening font-montserrat font-weight-bold">
                      {`${searchesQuantity?.open} `}
                      {searchesQuantity?.open !== 1
                        ? t('ACTIVE_SEARCHES_PLURAL')
                        : t('ACTIVE_SEARCHES_SINGULAR')}
                    </span>
                  </div>
                )}
              </div>
              <div className="d-flex justify-content-between my-3">
                <button
                  data-testid="button-create-search"
                  className={`mt-3 font-montserrat font-weight-bold border-0 text-xs text-white px-3 py-1  rounded-pill h-25 ${
                    disableForWorkspaceRole(currentRole, [
                      PartnerWorkspaceRole.OWNER,
                      PartnerWorkspaceRole.ADMINISTRATOR,
                    ])
                      ? 'bg-disabled-button'
                      : 'bg-maya-blue'
                  }`}
                  type="button"
                  disabled={disableForWorkspaceRole(currentRole, [
                    PartnerWorkspaceRole.OWNER,
                    PartnerWorkspaceRole.ADMINISTRATOR,
                  ])}
                  onClick={() => {
                    dispatch(
                      setNewSearchData({
                        name: '',
                        stagesQuantity: 1,
                        stages: [],
                        step: 1,
                      })
                    );
                    TrackEvent('create-evaluation-dashboard');
                    history.push({
                      pathname: CREATE_EVALUATION,
                      state: {
                        from: SCREENING_SEARCHES,
                        fromName: 'MENU_ITEM_SEARCHES',
                      },
                    });
                  }}
                >
                  {t('CREATE_SEARCH')}
                </button>
                <div className="d-flex align-items-center">
                  {[
                    { text: t('SCREENING_SEARCH_OPEN'), key: 'open' },
                    { text: t('SCREENING_SEARCH_CLOSE'), key: 'closed' },
                    { text: t('SCREENING_SEARCH_DRAFT'), key: 'draft' },
                  ].map((tab, index) => (
                    <ButtonFilters
                      text={
                        tab.text +
                        (!isNullOrUndefined(searchesQuantity[tab.key])
                          ? ` (${searchesQuantity[tab.key]})`
                          : '')
                      }
                      index={index}
                      selected={buttonSelected === index}
                      className="mr-2 px-3"
                      buttonSelected={buttonSelected}
                      setButtonSelected={setButtonSelected}
                      onClick={() => index !== buttonSelected && onClick(index)}
                      key={tab.text}
                    />
                  ))}
                  <form
                    data-testid="form-search"
                    onSubmit={handleSearch}
                    className="Navbar-container-input border w-100 mr-3 d-none d-lg-flex align-items-center py-1 px-2"
                  >
                    <img src={searchSvg} alt="search icon" />
                    <input
                      data-testid="input-search"
                      className="border-0 input pl-2 w-100"
                      onChange={(e) => setSearch(e.target.value)}
                      type="text"
                      placeholder={t('PROFILES_SEARCH_PLACEHOLDER')}
                      value={search}
                    />
                  </form>
                </div>
              </div>
              <div className="row bg-gray pt-3 px-2">
                {loading ? (
                  <Spinner show />
                ) : searchs?.length ? (
                  searchs?.map((search) => (
                    <CardScreeningSearch
                      key={search.recruitSessionId || search.id}
                      data={search}
                      fetchDraft={handleRequestDraft}
                      setSearchesQuantity={setSearchesQuantity}
                      reloadSearches={reloadSearches}
                    />
                  ))
                ) : (
                  <EmptyComponent
                    className="p-4"
                    title={t('NOT_FOUND_SEARCHES')}
                    subtitle={t('EMPTY_SEARCHS_COMPONENT')}
                  />
                )}
              </div>
              {!!searchs?.length && !loading && (
                <div
                  data-testid="screening-searches-pagination"
                  className="d-flex justify-content-center align-items-center mt-3"
                >
                  <img
                    src={navigateLeftSvg}
                    alt="navigate-left"
                    aria-hidden="true"
                    className={currentPage !== 1 ? 'cursor-pointer' : ''}
                    onClick={() =>
                      currentPage !== 1 ? goToPage((currentPage - 1) * 6 - 6, currentPage - 1) : {}
                    }
                  />
                  <span
                    className="font-montserrat text-decoration-none cursor-pointer"
                    aria-hidden="true"
                    onClick={() =>
                      currentPage !== 1 ? goToPage((currentPage - 1) * 6 - 6, currentPage - 1) : {}
                    }
                  >
                    {Number(currentPage) > 1 ? Number(currentPage) - 1 : ''}{' '}
                  </span>
                  <span className="font-montserrat font-weight-bold mx-1">{currentPage}</span>{' '}
                  <span
                    className="font-montserrat text-decoration-none cursor-pointer"
                    aria-hidden="true"
                    onClick={() => goToPage(currentPage * 6, currentPage + 1)}
                  >
                    {Math.ceil(pag / 6) > currentPage ? currentPage + 1 : ''}
                  </span>
                  {Math.ceil(pag / 6) > currentPage && Math.ceil(pag / 6) !== currentPage + 1
                    ? '...'
                    : ''}
                  <span
                    className="font-montserrat text-decoration-none  cursor-pointer"
                    aria-hidden="true"
                    onClick={() => {
                      goToPage(Number(Math.ceil(pag / 6) - 1) * 6, Number(Math.ceil(pag / 6)));
                    }}
                  >
                    {Math.ceil(pag / 6) > currentPage && Math.ceil(pag / 6) !== currentPage + 1
                      ? `${Number(Math.ceil(pag / 6))}`
                      : ''}
                  </span>
                  <img
                    src={navigateRightSvg}
                    alt="navigate-left"
                    aria-hidden="true"
                    className={currentPage !== Number(Math.ceil(pag / 6)) ? 'cursor-pointer' : ''}
                    onClick={() =>
                      currentPage !== Number(Math.ceil(pag / 6))
                        ? goToPage(currentPage * 6, currentPage + 1)
                        : {}
                    }
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Screening;

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Timer from '../../assets/screening/timer.svg';
import { t } from '../../i18n/i18n';
import DropdownSelect from '../DropdownSelect/DropdownSelect';
import useCreateSearch from '../../hooks/useCreateSearch';
import './SearchStage.scss';
import { CREATE_SCREENING } from '../../constants/routes';
import DeleteForever from '../../assets/screening/DeleteForever';
import { formatMinutesToHours } from '../../utils/formatMinutesToHours';
import CalendarPicker from '../CalendarDatePicker/CalendarDatePicker';
import { TrackEvent } from '../../utils/filters/segmentUtils';
import { setCreateScreeningFromSearches } from '../../redux/actions/screeningActions';
import { sortAlphabetic } from '../../utils/sort';
import Modal from '../Modal/Modal';
import useModalDetails from '../../hooks/useModalDetails';
import { generateHours } from '../../constants/hours';

const SearchStage = ({
  stage,
  setStagesData,
  stagesData,
  nextStage,
  fullData,
  lastStage,
  searchCompletedData,
  toggleDisplayProctoringMeasuresScreen,
}) => {
  const [stageData, setStageData] = useState(stagesData);
  const { formattedScreenings } = useCreateSearch();
  const { screeningModalContent, closeModal, handleScreeningModalData, loadingModal, showModal } =
    useModalDetails();
  const dispatch = useDispatch();
  const history = useHistory();
  const isBack = history.action === 'PUSH';
  const hours = generateHours();

  useEffect(() => {
    if (isBack) {
      setStageData({
        stage: 1,
        startDate: {
          hours: '00:00',
        },
        endDate: {
          hours: '00:00',
        },
        screeningId: '',
        levelId: '',
        companyId: null,
        technologyId: null,
      });
    } else {
      setStageData(stagesData);
    }
  }, []);

  const handleChange = () => {
    if (Object.keys(stageData).length > 1) {
      setStagesData(stage, stageData);
    }
  };

  const handleSetStartDate = (e) => {
    TrackEvent('search-start-hour');
    setStageData({
      ...stageData,
      startDate: {
        ...stageData?.startDate,
        hours: e?.name,
      },
    });
  };

  const handleSetEndDate = (e) => {
    TrackEvent('search-end-hour');
    setStageData({
      ...stageData,
      endDate: {
        ...stageData?.endDate,
        hours: e?.name,
      },
    });
  };

  useEffect(() => {
    handleChange();
  }, [stageData]);

  useEffect(() => {
    if (stageData?.startDate?.day) {
      TrackEvent('search-start-date');
    }
  }, [stageData?.startDate?.day]);

  useEffect(() => {
    if (stageData?.endDate?.day) {
      TrackEvent('search-end-date');
    }
  }, [stageData?.endDate?.day]);

  return (
    <div>
      <Modal
        show={showModal}
        relativeCloseButton
        className="rounded px-4 py-2 justify-content-center w-80vw"
        showCloseButton={!loadingModal}
        closeBtnClassname="text-maya-blue font-weight-bold"
        setShow={(e) => closeModal(e)}
      >
        {screeningModalContent()}
      </Modal>
      <div>
        <DropdownSelect
          options={sortAlphabetic(formattedScreenings, 'name')}
          className={`maya-blue-thin-border outline-none screenings-select text-sm w-60 ${
            stageData?.levelId ? 'selected-border' : 'unselected-border'
          }`}
          placeholder={
            formattedScreenings.length > 0
              ? t('CREATE_SEARCH_STAGE_SELECT_SCREENING_PLACEHOLDER')
              : t('CREATE_SEARCH_STAGE_SELECT_SCREENING_PLACEHOLDER_2')
          }
          selectedPlaceholder
          setState={(e) => {
            TrackEvent('screening-select');
            setStageData({
              ...stageData,
              screeningId: e.screeningId,
              levelId: e.id,
              companyId: e.companyId,
              technologyId: e.technologyId,
              recruitSessionId: e.recruitSessionId,
            });
          }}
          arrow={false}
          arrowPurple={formattedScreenings.length !== 0}
          selected={stageData?.levelId}
          disabled={formattedScreenings.length === 0}
        />
        <p className="font-montserrat font-weight-600 line-height-20 letter-spacing-05 mt-1 gray-subtitle text-sm">
          {formattedScreenings.length > 0 && t('CREATE_SEARCH_STAGE_TEST_HABILITES')}
          <Link
            onClick={() => {
              dispatch(setCreateScreeningFromSearches(true));
              TrackEvent('screening-create-from-search');
            }}
            className="text-decoration-none purple-color font-montserrat ml-2"
            to={CREATE_SCREENING}
          >
            {formattedScreenings.length > 0
              ? t('CREATE_SEARCH_SCREENING_CUSTOM')
              : t('CREATE_SEARCH_SCREENING')}
          </Link>{' '}
        </p>
        {stageData?.levelId && (
          <div
            data-testid="box-info-select-screening"
            className="rounded my-4 p-2 d-flex selected-screening-capsule position-relative"
          >
            <span className="font-montserrat font-weight-600 line-height-20 letter-spacing-05 ml-1 mr-2 position-name text-sm">
              {formattedScreenings?.find((item) => item.id === stageData?.levelId)?.name}
            </span>
            <div className="d-flex position-timer">
              <img src={Timer} alt="timer" />
              <p className="text-xs text-gray-2 mt-3 font-weight-600 font-montserrat">
                {formatMinutesToHours(
                  formattedScreenings?.find((item) => item.id === stageData?.levelId)?.duration
                ).replace(' hs', ':00')}
              </p>
            </div>
            <button
              onClick={() => {
                handleScreeningModalData(stageData.screeningId);
              }}
              className="align-self-center ml-3 pt-3 border-0 bg-white text-decoration-none ml-1 font-montserrat font-weight-600 line-height-17 outline-none letter-spacing-05 mt-1 gray-subtitle text-xs text-maya-blue"
              type="button"
            >
              {t('VIEW_SCREENING_DETAILS')}
            </button>
            <button
              data-testid="box-info-screening-delete-btn"
              type="button"
              className="border-0 position-bin-button bg-transparent"
              onClick={() =>
                setStageData({
                  ...stageData,
                  levelId: '',
                  screeningId: '',
                })
              }
            >
              <DeleteForever />
            </button>
          </div>
        )}
      </div>
      {process.env.RAZZLE_RUNTIME_IS_PROCTORING_ENABLED === 'true' && (
        <div className="d-flex flex-column">
          <p className="font-montserrat font-weight-600 line-height-20 letter-spacing-05 mt-2">
            {t('PROCTORING_MEASURES_SEARCH_TITLE')}
          </p>
          <p className="font-montserrat font-weight-500 line-height-17 letter-spacing-05 mt-1 gray-subtitle text-sm">
            {t('PROCTORING_MEASURES_SEARCH_SUBTITLE')}
            <button
              className="border-0 bg-white text-decoration-none ml-1 font-montserrat font-weight-600 line-height-17 letter-spacing-05 mt-1 gray-subtitle text-sm purple-color"
              type="button"
              onClick={() => toggleDisplayProctoringMeasuresScreen(true)}
            >
              {t('PROCTORING_MEASURES_SEARCH_SUBTITLE_VIEW_DETAILS')}
            </button>
          </p>
        </div>
      )}
      <div className="d-flex flex-column">
        <p className="font-montserrat font-weight-600 line-height-20 letter-spacing-05 mt-2">
          {t('CREATE_SEARCH_OPEN_DATE')}
        </p>
        <p className="font-montserrat font-weight-500 line-height-17 letter-spacing-05 mt-1 gray-subtitle text-sm">
          {t('CREATE_SEARCH_OPEN_DATE_DESCRIPTION')}
        </p>
      </div>
      <div className="d-flex flex-column mt-2 mb-2">
        <div className="d-flex flex-column">
          <div className="mt-2 d-flex flex-column">
            <div className="d-flex justify-content-start">
              <div className="w-10">
                <CalendarPicker
                  stageData={
                    isBack
                      ? { ...stageData, startDate: { ...stageData.startDate, day: undefined } }
                      : stageData
                  }
                  dateType="startDate"
                  setStageData={setStageData}
                />
              </div>
              <DropdownSelect
                options={hours}
                selected={stageData?.startDate?.hours}
                selectedPlaceholder
                setState={handleSetStartDate}
                className="time-picker-button"
                placeholderClassname="ml-4"
              />
            </div>
          </div>
        </div>
        <div className="d-flex mt-4 flex-column">
          <p className="font-montserrat font-weight-600 line-height-20 letter-spacing-05 mt-2">
            {t('CREATE_SEARCH_CLOSE_DATE')}
          </p>
          <p className="font-montserrat font-weight-500 line-height-17 letter-spacing-05 mt-1 gray-subtitle text-sm">
            {t('CREATE_SEARCH_CLOSE_DATE_DESCRIPTION')}
          </p>
          <div className="mt-2 d-flex">
            <div className="w-10">
              <CalendarPicker
                stageData={
                  isBack
                    ? { ...stageData, endDate: { ...stageData.endDate, day: undefined } }
                    : stageData
                }
                dateType="endDate"
                setStageData={setStageData}
              />
            </div>
            <DropdownSelect
              options={hours}
              selected={stageData?.endDate?.hours}
              selectedPlaceholder
              setState={handleSetEndDate}
              className="time-picker-button"
              placeholderClassname="ml-4"
            />
          </div>
        </div>
      </div>
      <div
        className={`w-100 mt-4 d-flex ${
          stage !== 1 ? 'justify-content-between' : 'justify-content-end'
        }`}
      >
        <button
          disabled={!lastStage || !searchCompletedData || !fullData}
          onClick={() => {
            TrackEvent(`create-search-next-p1`);
            nextStage();
          }}
          className={`mt-4 align-self-end px-3 py-2 rounded-pill font-montserrat text-xs text-white font-weight-bold border-0 outline-none ${
            !lastStage || !searchCompletedData || !fullData ? 'disabled-button' : 'bg-maya-blue'
          }`}
          type="button"
        >
          {t('CREATE_SCREENING_CONTINUE')}
        </button>
      </div>
    </div>
  );
};

export default SearchStage;

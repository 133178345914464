const Clock = () => (
  <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.60476 0.468931C7.4547 0.825317 7.4547 1.0504 7.60476 1.40679C7.79233 1.80069 7.96114 1.87572 8.58013 1.87572H9.33042V2.68228V3.47008L8.16747 3.69517C4.09716 4.48297 0.702112 8.08435 0.101882 12.3235C-0.629648 17.4254 2.65286 22.4336 7.54849 23.7278C9.01155 24.103 11.7876 24.0842 13.1006 23.7091C16.8521 22.6024 19.6844 19.5262 20.4159 15.7936C20.6785 14.3868 20.5472 11.9483 20.1346 10.5978C19.9845 10.0726 19.5156 9.09724 19.1029 8.44074L18.3714 7.22152L18.8591 6.71507L19.3468 6.18987L19.7407 6.56502C20.4722 7.25903 21.7102 6.88389 21.7102 5.96479C21.7102 5.7397 21.0912 4.93314 20.1721 3.99528C18.8216 2.62601 18.559 2.41968 18.0713 2.47595C17.6774 2.51346 17.4148 2.70104 17.2272 3.03867C16.9646 3.5076 16.9646 3.58262 17.3773 4.14534L17.8087 4.72681L17.321 5.23326C16.9083 5.64591 16.777 5.68343 16.4957 5.4771C15.6891 4.89563 13.457 3.92025 12.4816 3.71392L11.3937 3.48884V2.68228C11.3937 1.89448 11.4125 1.87572 12.0127 1.87572C12.763 1.87572 13.0819 1.59436 13.0819 0.93786C13.0819 0.0937862 12.8193 0 10.2308 0C7.8486 0 7.81109 0 7.60476 0.468931ZM13.6446 6.30242C20.1158 9.3786 20.1158 18.1757 13.6446 21.2707C12.5004 21.8146 12.2941 21.8521 10.2683 21.8521C8.20499 21.8521 8.05493 21.8334 6.83571 21.2331C3.55321 19.62 1.5837 15.9436 2.18393 12.5486C2.57783 10.2977 3.83456 8.29068 5.6165 7.07146C7.28589 5.92727 8.3738 5.6084 10.5496 5.68343C12.2003 5.72094 12.5754 5.79597 13.6446 6.30242Z"
      fill="#303030"
    />
    <path
      d="M9.55516 7.54039C9.40511 7.69044 9.33008 8.7596 9.33008 11.1043C9.33008 14.1992 9.34884 14.443 9.68646 14.6306C9.89279 14.7244 11.112 14.8182 12.4063 14.8182C14.7321 14.8182 14.7697 14.8182 15.0885 14.3305C15.3699 13.8803 15.3699 13.7865 15.126 13.3364C14.8634 12.8674 14.7697 12.8487 13.119 12.7924L11.3934 12.7361V10.3165C11.3934 8.3657 11.3371 7.8405 11.0933 7.61542C10.7556 7.25903 9.87404 7.22151 9.55516 7.54039Z"
      fill="#303030"
    />
  </svg>
);

export default Clock;

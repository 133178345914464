import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { notification } from 'antd';
import NewStyleInput from '../../../components/NewStyleInput/NewStyleInput';
import buttonEditImage from '../../../assets/profile/button-edit-image.svg';
import profileImageNull from '../../../assets/profile/profile.png';
import { t } from '../../../i18n/i18n';
import { getTextColor } from '../../../utils/colors/determineColor';
import { uploadImagePartner } from '../../../API/services/alkymersService';
import {
  setCurrentRole,
  setCurrentWorkspace,
  setUser,
  setWhitelabel,
} from '../../../redux/actions/authActions';
import { saveCompany } from '../../../API/services/companyService';
import { getMe } from '../../../API/services/authService';

const Evaluations = () => {
  const { user, whitelabel, currentWorkspace, currentRole } = useSelector((state) => state?.auth);
  const history = useHistory();
  const [evaluationData, setEvaluationData] = useState({
    color1: whitelabel?.colors?.BANNER_COLOR_1 || '#000000',
  });
  const dispatch = useDispatch();
  const setData = (e) => {
    setEvaluationData({ ...evaluationData, [e.target.name]: e.target.value });
  };
  const uploadImage = async (e) => {
    const fmData = new FormData();
    fmData.append('file', e.target.files[0]);
    if (['image/png', 'image/jpeg', 'image/jpg'].includes(e.target.files[0]?.type)) {
      await uploadImagePartner(fmData).then((res) => {
        dispatch(
          setUser({
            ...user,
            company: {
              ...user?.company,
              picture: res?.company?.picture,
            },
          })
        );
      });
    } else {
      notification.open({
        message: t('IMAGE_FORMAT_INVALID'),
        type: 'error',
      });
    }
  };

  const handleSaveColor = () => {
    saveCompany({
      ...(evaluationData?.color1 ? { primaryColor: evaluationData?.color1 } : {}),
    }).then(() => {
      notification.open({
        message: t('PROFILE_UPDATED_SUCCESSFULLY'),
        icon: <i className="fas fa-check" style={{ color: '#58DDA6' }} />,
      });
      dispatch(
        setWhitelabel({
          ...whitelabel,
          colors: {
            BANNER_COLOR_1: evaluationData?.color1,
          },
        })
      );
      getMe()
        .then((data) => {
          dispatch(setUser(data));
          dispatch(setCurrentWorkspace(currentWorkspace));
          dispatch(setCurrentRole(currentRole));
        })
        .catch((error) => {
          TrackEvent('partner-get-me-error', { error });
          history.push('/error');
        });
    });
  };

  const handleCancel = () => {
    setEvaluationData({ ...evaluationData, color1: whitelabel?.colors?.BANNER_COLOR_1 });
  };

  return (
    <div data-testid="evaluations-tab" className="d-flex flex-column">
      <div className="d-flex flex-row">
        <div className="d-flex flex-column w-50">
          <span className="font-montserrat font-weight-600 text-base mt-2">
            Personaliza la experiencia con tu marca
          </span>
          <span className="font-montserrat mt-2">
            Se aplicará a todas las evaluaciones de este workspace.
          </span>
          <div className="d-flex flex-row mt-3">
            <NewStyleInput
              className="mt-3 mr-2"
              value={user?.company?.displayName}
              onChange={setData}
              disabled
              title="Nombre de la empresa"
              name="companyName"
              classNameInput="w-350"
              titleBold
            />
            <div className="w-100 mt-2">
              <span className="font-montserrat text-sm mt-3 font-weight-600">
                {t('PROFILE_MAIN_COLOR')}
              </span>
              <div className="d-flex align-items-center mt-2 px-2 py-1 input-type-color-profile">
                <input
                  type="color"
                  onChange={setData}
                  className="input-picker-color-custom cursor-pointer"
                  style={{ width: '450px' }}
                  name="color1"
                  value={evaluationData.color1}
                />
                <span className="font-montserrat font-weight-600 text-base ml-2 text-uppercase">
                  {evaluationData.color1}
                </span>
              </div>
            </div>
          </div>
          <div
            className="d-flex flex-column position-relative mt-4"
            style={{ width: 'fit-content' }}
          >
            <span className="font-montserrat text-base font-weight-600">Logo</span>
            <img
              className="user-heading-img rounded-circle p-1"
              src={user?.company?.picture || profileImageNull}
              alt={t('PROFILE_MENU_LOGO_USER')}
            />
            <div className="image-upload position-absolute bottom-0 right-0">
              <label htmlFor="file-input-company" className="m-0 p-0">
                <img
                  src={buttonEditImage}
                  className="button-edit-image-profile cursor-pointer"
                  alt={t('EDIT_ALT')}
                />
              </label>
              <input
                id="file-input-company"
                type="file"
                accept="image/x-png,image/jpeg"
                onChange={uploadImage}
              />
            </div>
          </div>
        </div>
        <div className="d-flex flex-column w-50 mt-3">
          <span className="font-montserrat font-weight-600 text-base text-black">
            {t('PERSONALIZATION_PREVIEW')}
          </span>
          <div
            style={{
              border: `1px solid ${evaluationData?.color1}`,
              minHeight: '-webkit-fill-available',
            }}
            className="mt-2"
          >
            <div
              className="py-3 px-4"
              data-testid="evaluation-preview"
              style={{
                backgroundColor: evaluationData?.color1,
                color: getTextColor(evaluationData?.color1 || ''),
              }}
            >
              <div className="d-flex justify-content-between align-items-center">
                {!user?.company?.picture ? (
                  <span className="font-montserrat font-weight-600 text-base ml-3">
                    {user?.company?.displayName}
                  </span>
                ) : (
                  <img
                    className="rounded-circle profile-personalization-img"
                    src={user?.company?.picture || profileImageNull}
                    alt={t('PROFILE_MENU_LOGO_USER')}
                  />
                )}
                <span className="font-montserrat font-weight-600 text-base ml-3">
                  {t('PERSONALIZATION_TITLE_OF_EXAMPLE')}
                </span>
              </div>
            </div>
            <div className="d-flex flex-column flex-md-row justify-content-between align-items-center p-4 py-5 mt-2">
              <button
                type="button"
                data-testid="personalization-button"
                className="px-4 py-2 font-montserrat border-0 font-weight-700 text-sm profile-personalization-button"
                style={{
                  backgroundColor: evaluationData?.color1,
                  color: getTextColor(evaluationData?.color1 || ''),
                }}
              >
                {t('PERSONALIZATION_BUTTON_OF_EXAMPLE')}
              </button>
              <span className="font-montserrat font-weight-600 text-sm text-black text-decoration-none mt-2 mt-lg-0 ml-0 ml-md-5 cursor-pointer">
                {t('PERSONALIZATION_LINK_OF_EXAMPLE')}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex flex-row justify-content-between mt-5 mb-3">
        <button
          onClick={handleCancel}
          type="submit"
          data-testid="workspace-personalize-cancel-button"
          className="float-right mt-3 border-0 px-4 py-1 bg-transparent"
          style={{ borderRadius: '50px' }}
        >
          <span className="font-montserrat text-xs font-weight-700 text-tab-selected">
            {t('CANCEL')}
          </span>
        </button>
        <button
          onClick={handleSaveColor}
          type="submit"
          data-testid="workspace-personalize-save-button"
          className="float-right mt-3 button-save-profile border-0 px-4 py-1"
        >
          <span className="font-montserrat text-xs font-weight-700 text-white">{t('SAVE')}</span>
        </button>
      </div>
    </div>
  );
};

export default Evaluations;

import {
  SET_LANGUAGE,
  SET_REFS,
  SET_SIDEBAR,
  SET_TOUR_RUNNING,
  SET_TOUR_STEP,
  SET_KEEP_TESTS_FILTERS,
  SET_MODAL_DATA,
} from '../actionTypes';

export const setSidebar = (collapsed) => ({
  type: SET_SIDEBAR,
  payload: { sidebar: { collapsed } },
});

export const setLanguage = (key) => ({
  type: SET_LANGUAGE,
  payload: { language: key },
});

export const setTour = (running) => ({
  type: SET_TOUR_RUNNING,
  payload: { tour: running },
});

export const setTourStep = (tourStep) => ({
  type: SET_TOUR_STEP,
  payload: { tourStep },
});

export const setRefs = (ref) => ({
  type: SET_REFS,
  payload: { refs: { ...ref } },
});

export const setKeepTestsFilters = (keepTestsFilters) => ({
  type: SET_KEEP_TESTS_FILTERS,
  payload: { keepTestsFilters },
});

export const setModalData = (modalData) => ({
  type: SET_MODAL_DATA,
  payload: { modalData },
});

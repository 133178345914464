import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { notification } from 'antd';
import {
  getAlkymerAdvance,
  getProfile,
  setAlkymerAdvance,
  setAppliedFilters,
  setInvitedProfile,
  setProfile,
} from '../../redux/actions/profilesActions';
import Spinner from '../../components/spinner/Spinner';
import { t } from '../../i18n/i18n';
import BackgroundTop from '../../components/BackgroundTop/BackgroundTop';
import InvitedProfileData from '../../components/InvitedProfileData/InvitedProfileData';
import InvitedProfileStages from '../../components/InvitedProfileStages/InvitedProfileStages';
import {
  getAllTechnologies,
  getInvitedProfile,
  getProgress,
} from '../../API/services/alkymersService';
import ModalSubmission from '../../components/ModalSubmission/ModalSubmission';
import './InvitedProfile.scss';
import EditEmailTemplate from '../../components/EditEmailTemplate/EditEmailTemplate';
import useTableBatchAction from '../../hooks/useTableBatchAction';
import {
  postSendEmailCustom,
  updateScreeningCandidatesBatch,
} from '../../API/services/screeningService';
import { setStageCandidates, setSubjectEmail } from '../../redux/actions/screeningActions';
import {
  breadcrumbAlkemyCommunity,
  breadcrumbAlkymetricsEvaluation,
} from '../../constants/breadcrumb';
import { disableForWorkspaceRole } from '../../utils/workspaceRoleValidation';
import { PartnerWorkspaceRole } from '../../constants/enums';

const InvitedProfile = ({ isInvitedProfile }) => {
  const [disabledWorkspaceFunctions, setDisabledWorkspaceFunctions] = useState(false);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const invitedProfile = useSelector((state) => state.profiles.invitedProfile);
  const { template, stageCandidates, subjectEmail } = useSelector((state) => state.screenings);
  const profile = useSelector((state) => state.profiles.profile);
  const alkymerAdvance = useSelector((state) => state.profiles.alkymerAdvance);
  const {
    editMailVisible,
    setSelected,
    setEditMailVisible,
    invitationsList,
    confirmModifyModalStage,
    startModifyStageConfirmation,
  } = useTableBatchAction();
  const [loading, setLoading] = useState(true);
  const [progressTechs, setProgressTechs] = useState();
  const [groupAcceleration, setGroupAcceleration] = useState({});
  const { recruitSessionId, profileId, id } = useParams();
  const containerRef = useRef(null);
  const history = useHistory();
  const { currentRole } = useSelector((state) => state.auth);

  useEffect(() => {
    setDisabledWorkspaceFunctions(
      disableForWorkspaceRole(currentRole, [
        PartnerWorkspaceRole.OWNER,
        PartnerWorkspaceRole.ADMINISTRATOR,
      ])
    );
  }, []);

  if (isInvitedProfile) {
    useEffect(async () => {
      try {
        const profile = await getInvitedProfile(recruitSessionId, profileId);
        dispatch(setInvitedProfile(profile));
        setLoading(false);
      } catch (error) {
        history.goBack();
      }
      return () => {
        dispatch(setInvitedProfile(null));
      };
    }, [recruitSessionId, profileId]);
  } else {
    useEffect(async () => {
      dispatch(getProfile(id));
      const technologyProgress = await getProgress(id).then((res) => res.technologyProgress);
      const technologies = await getAllTechnologies();
      const dictionary = {};
      technologies?.forEach((technology) => {
        dictionary[technology.id] = technology;
      });
      setProgressTechs(
        technologyProgress?.map((technology) => ({
          ...technology,
          technology: dictionary[technology.alkymetricsId],
        }))
      );
      setLoading(false);
      return () => {
        dispatch(setProfile(null));
        dispatch(setAlkymerAdvance(null));
      };
    }, [id]);
    useEffect(() => {
      if (profile) {
        const groups = profile?.groups;
        const jobreadyId = groups && groups[groups.length - 1]?.group?.jobReadyProfileId;
        dispatch(getAlkymerAdvance(id, jobreadyId));
      }
    }, [profile]);
  }

  useEffect(
    () => () => {
      dispatch(setProfile(null));
      dispatch(setAlkymerAdvance(null));
      dispatch(setInvitedProfile(null));
    },
    []
  );

  useEffect(
    () => () => {
      if (!isInvitedProfile && !history.location.pathname.includes('/profiles')) {
        dispatch(setAppliedFilters([]));
      }
    },
    []
  );

  const updateStageSingleCandidate = async (newStatus, id) => {
    const body = {
      ids: [id],
      processStatus: newStatus,
    };
    if (newStatus && invitedProfile.stages[0].invitation.processStatus !== newStatus) {
      const updated = await updateScreeningCandidatesBatch(body);
      if (updated) {
        notification.open({
          message: t('SCREENING_PROCESS_STATUS_MODIFY_SUCCESS_SINGLE'),
          type: 'success',
        });
        dispatch(
          setStageCandidates({
            ...stageCandidates,
            collection: stageCandidates?.collection?.map((screening) => ({
              ...screening,
              ...(screening?.invitationId === id ? { processStatus: body.processStatus } : {}),
            })),
          })
        );
        const tempStages = invitedProfile.stages;
        tempStages[0].invitation.processStatus = newStatus;
        dispatch(setInvitedProfile({ ...invitedProfile, stages: tempStages }));
      } else {
        notification.open({
          message: t('SCREENING_PROCESS_STATUS_MODIFY_ERROR'),
          type: 'error',
        });
      }
    }
  };

  const handleSendEmail = async () => {
    const id = stageCandidates?.collection?.find(
      (candidate) => candidate.userId === invitedProfile.profile.id
    )?.invitationId;

    const requestData = {
      invitationsIds: [id],
      emailType: 'CUSTOM_GENERIC',
      emailParams: {
        subject: subjectEmail.subjectEmail,
        html: template.template,
      },
    };
    try {
      await postSendEmailCustom(requestData, {});
      notification.open({
        message: t('INVITED_SINGLE_PROFILE_CORRECTLY'),
        icon: <i className="fas fa-check" style={{ color: '#58DDA6' }} />,
      });
      setEditMailVisible(false);
      dispatch(setSubjectEmail({ subjectEmail: '' }));
    } catch (error) {
      notification.open({
        message: t('INVITATION_ERROR'),
        icon: <i className="fas fa-times" style={{ color: 'red' }} />,
      });
    }
  };
  return loading || (!invitedProfile && !profile) ? (
    <Spinner show={loading} />
  ) : editMailVisible ? (
    <EditEmailTemplate
      handleSendRequest={handleSendEmail}
      goBack={() => setEditMailVisible(false)}
      viewInfo={false}
      emailDashboard
    />
  ) : (
    <div
      data-testid="invited-profile-container"
      className="w-100 h-100 bg-profile"
      ref={containerRef}
    >
      <BackgroundTop
        title={invitedProfile ? t('INVITED_PROFILE_CANDIDATE_BANNER_TITLE') : t('TALENT')}
        className="position-relative"
        routes={
          invitedProfile
            ? [
                ...breadcrumbAlkymetricsEvaluation,
                {
                  text: 'SCREENING_DASHBOARD_CAPSULE_TITLE',
                  clickable: true,
                  route: `/search/${recruitSessionId}`,
                },
              ]
            : [
                ...breadcrumbAlkemyCommunity,
                {
                  text: 'TALENTS',
                  clickable: true,
                  route: '/profiles',
                },
              ]
        }
        titleClassname="text-xl"
      />
      <div className="d-flex flex-column description-container mx-auto mb-5 pt-0 px-2">
        <ModalSubmission
          show={show}
          setShow={setShow}
          showResult
          isInvitedProfile={isInvitedProfile}
          {...(groupAcceleration?.skills?.length ? { groupAcceleration } : {})}
        />
        {confirmModifyModalStage}
        <div className="container-invited-profile-ca">
          <InvitedProfileData
            profile={invitedProfile?.profile || profile}
            isInvitedProfile={isInvitedProfile}
            search={invitedProfile?.recruitSession}
            setSelected={setSelected}
            invitationsList={invitationsList}
            updateStageSingleCandidate={updateStageSingleCandidate}
            setEditMailVisible={setEditMailVisible}
            currentStage={
              invitedProfile?.stages?.filter((stage) => Boolean(stage.invitation)).reverse()[0]
                ?.recruitSessionStage
            }
            nextStage={
              invitedProfile?.stages?.filter((stage) => !stage.invitation)[0]?.recruitSessionStage
            }
            currentInvitation={
              invitedProfile?.stages?.filter((stage) => Boolean(stage.invitation)).reverse()[0]
                ?.invitation
            }
            deleteCandidate={startModifyStageConfirmation}
            disabledWorkspaceFunctions={disabledWorkspaceFunctions}
          />
          <InvitedProfileStages
            search={invitedProfile?.recruitSession}
            isInvitedProfile={isInvitedProfile}
            score={
              invitedProfile?.stages?.filter((stage) => Boolean(stage.invitation))[0]?.invitation
                ?.score || alkymerAdvance
            }
            stages={invitedProfile?.stages?.filter((stage) => Boolean(stage.invitation))}
            setShow={setShow}
            profile={invitedProfile?.profile || profile}
            progressTechs={progressTechs}
            setGroupAcceleration={setGroupAcceleration}
          />
        </div>
      </div>
    </div>
  );
};

export default InvitedProfile;

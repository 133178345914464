import { useEffect } from 'react';
import { Steps } from 'antd';
import { useHistory } from 'react-router-dom';
import { t } from '../../i18n/i18n';
import BackgroundTop from '../../components/BackgroundTop/BackgroundTop';
import Spinner from '../../components/spinner/Spinner';
import './CreateEvaluation.scss';
import useCreateEvaluation from '../../hooks/useCreateEvaluation';
import DropDownArrow from '../../assets/screening/DropDownArrow';
import CreateEvaluationFirstStep from '../../components/CreateEvaluationFirstStep/CreateEvaluationFirstStep';
import CreateEvaluationSecondStep from '../../components/CreateEvaluationSecondStep/CreateEvaluationSecondStep';
import CreateEvaluationThirdStep from '../../components/CreateEvaluationThirdStep/CreateEvaluationThirdStep';
import { CREATE_EVALUATION, SCREENING_SEARCHES } from '../../constants/routes';
import BlockRedirection from '../../components/BlockRedirection/BlockRedirection';
import CreateEvaluationFourthStep from '../../components/CreateEvaluationFourthStep/CreateEvaluationFourthStep';

const CreateEvaluation = () => {
  const {
    step,
    draftId,
    enableCreateButton,
    existingChanges,
    evaluation,
    loading,
    loadingTests,
    disableCustomTestGroupWeight,
    missingTestgroupWeight,
    preventReload,
    incompleteQuestionValidation,
    fetchTests,
    saveChangesModal,
    setShowExitModal,
    handleEvaluationName,
    handleClearData,
    handleSetEvaluationData,
    handleCreateDraft,
    handleOpenEvaluation,
    handleEvaluationQuickRole,
    handleChangeStep,
    loadingCreateDraft,
    newScreeningFilters,
    quickTest,
    setQuickTest,
  } = useCreateEvaluation();
  const history = useHistory();

  useEffect(
    () => () => {
      handleClearData();
    },
    []
  );

  return (
    <BlockRedirection
      conditionToBlock={preventReload}
      route={CREATE_EVALUATION}
      updateConditionToBlock
      message={t('CREATE_SCREENING_LEAVE_SECTION_ALERT')}
    >
      <div className="w-100 mx-auto h-100 pl-3 pr-3">
        {saveChangesModal()}
        <div>
          <BackgroundTop
            title={t('CREATE_EVALUATION_SECTION_TITLE')}
            routes={[
              { text: 'MENU_ITEM_SCREENING', clickable: false },
              ...(history.location.state?.from
                ? [
                    {
                      text: history.location.state?.fromName,
                      clickable: true,
                      route: history.location.state?.from,
                    },
                  ]
                : []),
            ]}
            className="position-relative"
            titleClassname="text-xl"
          />
        </div>
        <div
          className={`position-relative new-search-container p-4 bg-white d-flex w-100 flex-column rounded mx-auto ${
            step === 2 ? 'window-height-step3' : ''
          }`}
        >
          {loading ? (
            <Spinner show />
          ) : (
            <>
              <div>
                <Steps
                  data-testid="create-evaluation-steps-container"
                  current={step}
                  onChange={handleChangeStep}
                  items={[
                    {
                      title: t('CREATE_EVALUATION_FIRST_STEP_TITLE'),
                      'data-testid': 'CREATE_EVALUATION_FIRST_STEP_TITLE',
                    },
                    {
                      title: t('CREATE_EVALUATION_SECOND_STEP_TITLE'),
                      disabled: !draftId,
                      'data-testid': 'CREATE_EVALUATION_SECOND_STEP_TITLE',
                    },
                    {
                      title: t('CREATE_EVALUATION_THIRD_STEP_TITLE'),
                      disabled: !draftId,
                      'data-testid': 'CREATE_EVALUATION_THIRD_STEP_TITLE',
                    },
                    {
                      title: t('CREATE_EVALUATION_FOURTH_STEP_TITLE'),
                      disabled: !draftId,
                      'data-testid': 'CREATE_EVALUATION_FOURTH_STEP_TITLE',
                    },
                  ]}
                />
              </div>
              <div className="mt-3">
                {step === 0 && (
                  <CreateEvaluationFirstStep
                    evaluation={evaluation}
                    handleEvaluationName={handleEvaluationName}
                    handleEvaluationQuickRole={handleEvaluationQuickRole}
                    handleSetEvaluationData={handleSetEvaluationData}
                    newScreeningFilters={newScreeningFilters}
                    quickTest={quickTest}
                    setQuickTest={setQuickTest}
                  />
                )}
                {step === 1 && (
                  <CreateEvaluationSecondStep
                    loadingTests={loadingTests}
                    fetchTests={fetchTests}
                    evaluation={evaluation}
                  />
                )}
                {step === 2 && <CreateEvaluationThirdStep />}
                {step === 3 && (
                  <CreateEvaluationFourthStep
                    handleChangeStep={handleChangeStep}
                    evaluation={evaluation}
                    handleSetEvaluationData={handleSetEvaluationData}
                    missingTestgroupWeight={missingTestgroupWeight}
                    disableCustomTestGroupWeight={disableCustomTestGroupWeight}
                  />
                )}
              </div>
              <div className="mt-auto d-flex justify-content-between align-items-center px-5">
                <button
                  data-testid="create-evaluation-exit-button"
                  type="button"
                  className="border-0 bg-transparent text-maya-blue text-xs font-weight-bold font-montserrat"
                  onClick={() => {
                    if (existingChanges || incompleteQuestionValidation) {
                      setShowExitModal(true);
                    } else {
                      history.push(SCREENING_SEARCHES);
                    }
                  }}
                >
                  {t('CREATE_EVALUATION_EXIT_BUTTON')}
                </button>
                {draftId ? (
                  <div>
                    {step > 0 && (
                      <button
                        onClick={() => handleChangeStep(step - 1)}
                        data-testid="create-evaluation-previous-step-bottom"
                        type="button"
                        className="border-0 outline-none bg-transparent mx-2 my-1"
                      >
                        <div className="bottom-navigator-button p-1 fa-rotate-90 border-maya-blue">
                          <DropDownArrow lightBlue />
                        </div>
                      </button>
                    )}
                    {step < 3 && (
                      <button
                        onClick={() => handleChangeStep(step + 1)}
                        data-testid="create-evaluation-next-step-bottom"
                        type="button"
                        className="border-0 outline-none bg-transparent"
                      >
                        <div className="bottom-navigator-button p-1 fa-rotate-270 border-maya-blue">
                          <DropDownArrow lightBlue />
                        </div>
                      </button>
                    )}
                    {step === 3 && (
                      <button
                        onClick={handleOpenEvaluation}
                        className="align-self-end px-3 py-2 rounded-pill font-montserrat text-xs text-white font-weight-bold border-0 outline-none mr-2 bg-maya-blue"
                        type="button"
                        data-testid="create-evaluation-open-search-btn"
                      >
                        {t('CREATE_EVALUATION_OPEN_SEARCH_BUTTON')}
                      </button>
                    )}
                  </div>
                ) : (
                  <button
                    disabled={!enableCreateButton || loadingCreateDraft}
                    onClick={handleCreateDraft}
                    className={`mt-4 align-self-end px-3 py-2 rounded-pill font-montserrat text-xs text-white font-weight-bold border-0 outline-none ${
                      !enableCreateButton || loadingCreateDraft ? 'disabled-button' : 'bg-maya-blue'
                    }`}
                    type="button"
                    data-testid="create-evaluation-create-draft-btn"
                  >
                    {t('CREATE_EVALUATION_CREATE_DRAFT_BUTTON')}
                  </button>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </BlockRedirection>
  );
};

export default CreateEvaluation;

import { useRef, useState, useEffect } from 'react';
import Lottie from 'lottie-react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'query-string';
import HorizontalLoader from '../HorizontalLoader/HorizontalLoader';
import useCreateScreening from '../../hooks/useCreateScreening';
import useOnScreen from '../../hooks/useOnScreen';
import SearchIcon from '../../assets/screening/SearchIcon';
import { t } from '../../i18n/i18n';
import { formatMinutesToHours } from '../../utils/formatMinutesToHours';
import MultipleSelect from '../MultipleSelect/MultipleSelect';
import EmptyState from '../../assets/screening/EmptyState.json';
import TestItem from '../TestItem/TestItem';
import './CreateScreeningStep2.scss';
import ModalSubmission from '../ModalSubmission/ModalSubmission';
import { getScreeningTestGroups } from '../../API/services/screeningService';
import Spinner from '../spinner/Spinner';
import {
  setAmountOfFilters,
  setNewScreeningFilters,
  setTestGroupsData,
} from '../../redux/actions/screeningActions';
import { setKeepTestsFilters } from '../../redux/actions/uxActions';
import ButtonFilters from '../ButtonFilters/ButtonFilters';
import { CREATE_EVALUATION, TESTS } from '../../constants/routes';
import { TrackEvent } from '../../utils/filters/segmentUtils';

const CreateScreeningStep2 = ({
  isTestsPage = false,
  loadingTests = false,
  setLoadingTests = () => {},
}) => {
  const {
    fetchNextPage,
    handleNextStep,
    handlePreviousStep,
    handleSelectFilterOption,
    handleInput,
    handleSubmit,
    testArray,
    loading,
    newScreening,
    testsDuration,
    newScreeningFilters,
    testGroupLevelsFilterOptions,
    testGroupCategoriesFilterOptions,
    testGroupRolesFilterOptions,
    amountOfFilters,
    handleClearFilters,
    setLastNameFetched,
    formatFilters,
    fetchCategories,
    fetchLevels,
    fetchRoles,
    handleTests,
  } = useCreateScreening();
  const { keepTestsFilters } = useSelector((state) => state.ux);

  const { search } = useLocation();

  const history = useHistory();
  const roleSelected = Number(qs.parse(search)?.roleSelected);
  const [buttonSelected, setButtonSelected] = useState(roleSelected <= 2 ? roleSelected : 0);
  const fetchNextUrlRef = useRef();
  const fetchNextUrl = useOnScreen(fetchNextUrlRef, '0px', true);
  const [fetched, setFetched] = useState(false);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!testGroupLevelsFilterOptions.length) {
      fetchLevels();
    }

    if (!testGroupCategoriesFilterOptions.length) {
      fetchCategories();
    }

    if (!testGroupRolesFilterOptions.length) {
      fetchRoles();
    }
  }, []);
  useEffect(() => {
    if (!fetchNextUrl) setFetched(false);
    if (
      fetchNextUrl &&
      !fetched &&
      testArray?.collection?.length &&
      testArray?.pagination.nextPage &&
      !loading &&
      !loadingTests
    ) {
      fetchNextPage();
      setFetched(true);
    }
  }, [fetchNextUrl]);

  useEffect(() => {
    setLoadingTests(true);
    getScreeningTestGroups(
      buttonSelected === 1
        ? {
            ...formatFilters(
              Object.entries(keepTestsFilters).length ? keepTestsFilters : newScreeningFilters
            ),
            favorites: true,
          }
        : {
            ...formatFilters(
              Object.entries(keepTestsFilters).length ? keepTestsFilters : newScreeningFilters
            ),
          }
    ).then((res) => {
      dispatch(setTestGroupsData(res));
      setLoadingTests(false);
    });
    if (Object.entries(keepTestsFilters).length) {
      dispatch(setNewScreeningFilters(keepTestsFilters));
      dispatch(
        setAmountOfFilters(
          Object.values({
            category: keepTestsFilters?.category,
            levelId: keepTestsFilters?.levelId,
            rolesIds: keepTestsFilters?.rolesIds,
          }).flat().length
        )
      );
      dispatch(setKeepTestsFilters({}));
    }
  }, [buttonSelected]);

  const handleButton = () => {
    TrackEvent('create-evaluation-tests');
    history.push({
      pathname: CREATE_EVALUATION,
      state: {
        from: TESTS,
        fromName: 'MENU_ITEM_TESTS',
      },
    });
  };

  const onClick = (index) => {
    if (index === 2 || (index !== 2 && buttonSelected === 2)) {
      dispatch(
        setNewScreeningFilters({
          category: [],
          levelId: [],
          name: '',
          rolesIds: [],
        })
      );
      dispatch(setAmountOfFilters(0));
      setLastNameFetched('');
    }
    setButtonSelected(index);
  };
  return (
    <div data-testid="create-screening-step-2-container" className="d-flex flex-column">
      <ModalSubmission show={show} setShow={setShow} isInvitedProfile isTestgroupListDetail />
      <span
        className={`font-montserrat font-weight-bold text-base mt-2 ${
          isTestsPage ? 'd-none' : 'd-block'
        }`}
      >
        {t('SCREENING_TESTS_TITLE')}
      </span>
      <span
        className={`font-montserrat text-muted text-sm mt-3 font-weight-500 ${
          isTestsPage ? 'd-none' : 'd-block'
        }`}
      >
        {t('SCREENING_TESTS_SUBTITLE')}
      </span>
      <div className="my-2 d-flex align-items-center">
        {[t('TEST_ALKEMY'), t('MY_FAVORITES'), t('TEST_BY_ROLE')].map((text, index) => (
          <ButtonFilters
            key={text}
            text={text}
            index={index}
            selected={buttonSelected === index}
            className="mr-2"
            buttonSelected={buttonSelected}
            setButtonSelected={setButtonSelected}
            onClick={() => onClick(index)}
          />
        ))}
        {isTestsPage && (
          <button
            data-testid="test-page-redirect-button"
            className="font-montserrat ml-auto button-close-modal-submission text-white text-xs font-weight-700 px-2 py-1 d-flex justify-content-center align-items-center border-0 text-decoration-none"
            onClick={handleButton}
            type="button"
          >
            {t('CREATE_SCREENING_TEXT')}
          </button>
        )}
      </div>
      {buttonSelected === 2 && (
        <div className="container-test-role my-1 py-1 px-3">
          <span className="font-montserrat text-test-role">{t('TEST_FOR_ROLE_TEXT')}</span>
        </div>
      )}
      <div className="my-2 d-flex align-items-center">
        {buttonSelected !== 2 ? (
          <>
            <MultipleSelect
              title={t('MULTIPLE_SELECT_CATEGORIES_PLACEHOLDER')}
              onSelect={(e) => handleSelectFilterOption(e.id, 'category', buttonSelected === 1)}
              selectedList={newScreeningFilters.category}
              options={testGroupCategoriesFilterOptions
                ?.filter((category) => category?.id !== process.env.RAZZLE_RUNTIME_CATEGORY_ID)
                ?.map((item) => ({
                  id: item.id,
                  displayName: item.name,
                }))}
              classNames="select-color"
            />
            <MultipleSelect
              title={t('MULTIPLE_SELECT_LEVELS_PLACEHOLDER')}
              classNames="ml-3 select-color"
              onSelect={(e) => handleSelectFilterOption(e.id, 'levelId', buttonSelected === 1)}
              selectedList={newScreeningFilters.levelId}
              options={testGroupLevelsFilterOptions}
            />
          </>
        ) : (
          <MultipleSelect
            title={t('ROLES_FILTERS')}
            classNames="select-color"
            onSelect={(e) => handleSelectFilterOption(e.id, 'rolesIds', buttonSelected === 1)}
            selectedList={newScreeningFilters.rolesIds}
            options={testGroupRolesFilterOptions}
          />
        )}
        {amountOfFilters > 0 && (
          <div className="py-1">
            <span className="text-sm font-montserrat text-purple font-weight-500 ml-3 total-number-text">
              {`${t('SCREENING_AMOUNT_OF_FILTERS')} (${amountOfFilters})`}
            </span>
          </div>
        )}
        {amountOfFilters > 0 && (
          <div className="py-1">
            <button
              className="bg-transparent mt-1 ml-2 font-montserrat border-0 outline-0 red-color font-weight-bold text-sm"
              type="button"
              onClick={() => handleClearFilters(buttonSelected === 1)}
            >
              {t('PROFILES_CLEAR_FILTERS')}
            </button>
          </div>
        )}
        <form
          onBlur={handleSubmit}
          onSubmit={handleSubmit}
          className={`my-1 my-sm-0 rounded transition-2-ms p-2 ${
            isTestsPage ? 'd-flex align-items-center ml-auto' : 'd-none'
          } ${
            newScreeningFilters?.name?.length
              ? 'tests-search-input-full bg-white'
              : 'tests-search-input-empty bg-gray'
          }`}
          style={{ maxWidth: '385px' }}
        >
          <SearchIcon color={newScreeningFilters?.name?.length ? '#1aacf0' : '#424242'} />
          <input
            onChange={handleInput}
            value={newScreeningFilters?.name}
            className="font-montserrat text-xs bg-transparent border-0 outline-none ml-2"
            placeholder={t('CREATE_SCREENING_SEARCH_PLACEHOLDER')}
            type="text"
          />
        </form>
      </div>
      <div
        className={`${
          isTestsPage
            ? 'd-none'
            : 'mt-2 mb-3 d-flex justify-content-between align-items-center flex-wrap-reverse'
        }`}
      >
        <div className="d-flex flex-wrap">
          <div className="my-1 my-sm-0 mr-0 mr-sm-2 mr-md-5">
            <span className="font-montserrat text-muted text-md font-weight-bold">
              {t('CREATE_SCREENING_SELECTED_TESTS')}
            </span>
            <span className="total-number-text  font-montserrat font-weight-bold">
              {newScreening?.testGroups?.length}
            </span>
          </div>
          <div className="my-1 my-sm-0 ml-0 ml-sm-2 ml-md-5">
            <span className="font-montserrat text-muted text-md font-weight-bold">
              {t('CREATE_SCREENING_TOTAL_TIME')}
            </span>
            <span className="total-number-text font-montserrat font-weight-bold">
              {formatMinutesToHours(testsDuration)}
            </span>
          </div>
        </div>
        <form
          onBlur={handleSubmit}
          onSubmit={handleSubmit}
          className={`my-1 my-sm-0 rounded d-flex align-items-center transition-2-ms p-2 ${
            newScreeningFilters?.name?.length
              ? 'tests-search-input-full bg-white'
              : 'tests-search-input-empty bg-gray'
          }`}
          style={{ maxWidth: '385px' }}
        >
          <SearchIcon color={newScreeningFilters?.name?.length ? '#1aacf0' : '#424242'} />
          <input
            onChange={handleInput}
            value={newScreeningFilters?.name}
            className="font-montserrat text-xs bg-transparent border-0 outline-none ml-2"
            placeholder={t('CREATE_SCREENING_SEARCH_PLACEHOLDER')}
            type="text"
          />
        </form>
      </div>
      {!testArray?.collection?.length && !loading && !loadingTests && (
        <div className="w-100 tests-bg mt-3">
          <div className="w-50 mx-auto text-center">
            <div className="empty-state-container mx-auto mb-4">
              <Lottie animationData={EmptyState} loop className="my-5" />
              <p className="font-weight-600 font-montserrat px-2 text-base">
                {t('CREATE_SCREENING_TESTGROUPS_EMPTY_TEXT')}
              </p>
            </div>
            <a
              className="font-montserrat font-weight-600 font-montserrat text-base text-decoration-none"
              onClick={() => {
                TrackEvent('suggest-test-link');
              }}
              href={process.env.RAZZLE_RUNTIME_TEST_SUGGESTIONS_LINK}
              rel="noopener noreferrer"
              target="_blank"
            >
              {t('CREATE_SCREENING_TESTGROUPS_EMPTY_LINK')}
            </a>
          </div>
        </div>
      )}
      <div
        className={`test-grid-container tests-bg px-2 px-md-4 py-4 overflow-auto test-grid-container-tests ${
          loadingTests ? ' d-flex justify-content-center' : ' px-2 px-md-4 py-4'
        }`}
      >
        {loadingTests ? (
          <Spinner show />
        ) : (
          <>
            {testArray?.collection?.map((item) => (
              <TestItem
                key={item.id}
                test={item}
                setShow={setShow}
                isStar
                isTestsPage={isTestsPage}
                isFavorite={item?.favorite ?? false}
                testArray={testArray}
                handleTests={handleTests}
                newScreening={newScreening}
              />
            ))}
          </>
        )}
        <div ref={fetchNextUrlRef} />
      </div>
      <div className="d-flex align-items-start justify-content-between">
        <button
          onClick={handlePreviousStep}
          className={`${
            isTestsPage
              ? 'd-none'
              : 'align-self-center mt-4  px-3 py-1 rounded-pill font-roboto text-white text-sm font-weight-bold border-0 outline-none bg-maya-blue'
          }`}
          type="button"
        >
          {t('CREATE_SCREENING_BACK')}
        </button>
        {loading && (
          <div className="d-flex justify-content-center horizontal-loader-height mt-0 mx-auto">
            <HorizontalLoader />
          </div>
        )}
        <button
          onClick={handleNextStep}
          className={`${
            isTestsPage
              ? 'd-none'
              : 'align-self-center mt-4  px-3 py-1 rounded-pill font-roboto text-white text-sm font-weight-bold border-0 outline-none bg-maya-blue'
          }`}
          type="button"
        >
          {t('CREATE_SCREENING_CONTINUE')}
        </button>
      </div>
    </div>
  );
};

export default CreateScreeningStep2;

import React from 'react';

const UnassignedWeightIcon = ({ classNames }) => (
  <svg
    className={classNames}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_275_398)">
      <path
        d="M10.0001 1.6665C5.40008 1.6665 1.66675 5.39984 1.66675 9.99984C1.66675 14.5998 5.40008 18.3332 10.0001 18.3332C14.6001 18.3332 18.3334 14.5998 18.3334 9.99984C18.3334 5.39984 14.6001 1.6665 10.0001 1.6665ZM10.8334 14.1665H9.16675V9.1665H10.8334V14.1665ZM10.8334 7.49984H9.16675V5.83317H10.8334V7.49984Z"
        fill="#DD361E"
      />
    </g>
    <defs>
      <clipPath id="clip0_275_398">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default UnassignedWeightIcon;

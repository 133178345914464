import React from 'react';
import useCreateScreening from '../../hooks/useCreateScreening';
import { t } from '../../i18n/i18n';

const CreateScreeningStep1 = () => {
  const { handleNextStep, handleScreeningName, newScreening } = useCreateScreening();

  return (
    <div className="d-flex flex-column">
      <span className="font-montserrat font-weight-bold text-base mt-2">
        {t('SCREENING_NAME_TITLE')}
      </span>
      <span className="font-montserrat text-muted text-sm mt-3 font-weight-500">
        {t('SCREENING_NAME_SUBTITLE')}
      </span>
      <div className="mt-3 d-flex flex-column create-screening-section-container w-100">
        <span className="font-montserrat font-weight-bold text-sm screening-name-title">
          {t('SCREENING_NAME_INPUT_TITLE')}
        </span>
        <input
          minLength={1}
          maxLength={50}
          placeholder={t('SCREENING_NAME_INPUT_PLACEHOLDER')}
          value={newScreening?.name}
          type="text"
          onChange={handleScreeningName}
          className={`p-2 font-montserrat rounded mt-2 text-sm screening-name-input ${
            newScreening?.name?.trim()?.length >= 1 && 'completed'
          }`}
        />
        <button
          disabled={newScreening?.name?.trim()?.length === 0}
          onClick={handleNextStep}
          className={`mt-4 align-self-end px-3 py-1 rounded-pill font-roboto text-white text-sm font-weight-bold border-0 outline-none ${
            newScreening?.name?.trim()?.length >= 1 ? 'bg-maya-blue' : 'disabled-button'
          }`}
          type="button"
        >
          {t('CREATE_SCREENING_CONTINUE')}
        </button>
      </div>
    </div>
  );
};
export default CreateScreeningStep1;

import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { utils, writeXLSX } from 'xlsx';
import { AnswerTypeEnum, MechanografyTypeEnum } from '../constants/enums';

const useExportCsv = () => {
  const { newScreening } = useSelector((state) => state.screenings);
  const [testDifficulty, setTestDifficulty] = useState([]);

  const generateTestGroupTestsJsonToExport = () => {
    const result = [];
    newScreening.customTestGroup.forEach((element) => {
      const row = {
        ID: element.id,
        Dificultad: testDifficulty.find((d) => d.id === element.difficultyId)?.name.toUpperCase(),
        Tipo: element.answerType,
        Titulo: element.name,
        'Respuesta con peso': !element.hasGlobalScore,
        Tematica: element.theme,
        Consigna: element.text,
        ImagenConsigna: element?.files ? element?.textFileNameAttached : undefined,
      };
      if (element?.answerType == AnswerTypeEnum.VIDEO) {
        result.push({
          ...row,
          Respuestas: element.totalTime,
          Files: element.preparationTime,
          Puntos: element.maxAttempts,
          'Respuesta/s correcta/s': true,
          'Fijar respuesta': false,
        });
      }
      if (element?.answerType == AnswerTypeEnum.WRITE) {
        result.push({
          ...row,
          Respuestas: element?.writeResponse,
          'Respuesta con peso': element?.isTyping
            ? MechanografyTypeEnum.MECHANOGRAFY
            : MechanografyTypeEnum.NO_MECHANOGRAFY,
          'Respuesta/s correcta/s': true,
          'Fijar respuesta': false,
        });
      }
      if (element?.answerType == AnswerTypeEnum.TEXT_AI) {
        result.push({
          ...row,
          Respuestas: element?.concepts,
          Puntos: element?.maxChar,
          'Respuesta/s correcta/s': true,
          'Fijar respuesta': false,
        });
      }
      if (element?.answerType == AnswerTypeEnum.FILE) {
        result.push({
          ...row,
          Respuestas: element?.options[0]?.text,
          'Respuesta/s correcta/s': true,
          'Fijar respuesta': false,
        });
      }
      if (element?.answerType == AnswerTypeEnum.CODE) {
        element?.expectedResult?.map((testCase, index) => {
          let optionToExcel = {
            ID: null,
            Dificultad: null,
            Tipo: null,
            Titulo: null,
            'Respuesta con peso': null,
            Tematica: null,
            Consigna: null,
            ImagenConsigna: null,
          };
          if (index === 0) {
            optionToExcel = {
              ...row,
              Puntos: element?.existingCode,
              'Respuesta con peso': element.langDisplayName.replace(' ', '_').toUpperCase(),
            };
          }
          optionToExcel.Respuestas = testCase.input;
          optionToExcel.Files = testCase.output;
          optionToExcel['Respuesta/s correcta/s'] = true;
          optionToExcel['Fijar respuesta'] = false;
          result.push(optionToExcel);
        });
      }
      if (
        [AnswerTypeEnum.CHECK, AnswerTypeEnum.RADIO].includes(element?.answerType) &&
        element.options &&
        element.options.length
      ) {
        element.options.forEach((option, index) => {
          let optionToExcel = {
            ID: null,
            Dificultad: null,
            Tipo: null,
            'Respuesta con peso': null,
            Tematica: null,
            Consigna: null,
            ImagenConsigna: null,
          };
          if (index === 0) {
            optionToExcel = { ...row };
          }
          optionToExcel.Respuestas = option.text;
          optionToExcel.Files = option?.files;
          optionToExcel.Puntos = option.weight;
          optionToExcel['Respuesta/s correcta/s'] = option.correct;
          optionToExcel['Fijar respuesta'] = option.pinnedIndex !== undefined;

          result.push(optionToExcel);
        });
      }
    });
    return result;
  };

  const generateTestGroupTestsMerges = (testgroupJson) => {
    const merges = [];
    let startIndex;
    let endIndex;
    const addMergeConditions = (startIndex, endIndex) => {
      const columnsToMerge = 6;
      for (let i = 0; i < columnsToMerge; i += 1) {
        merges.push({
          s: {
            // Start
            r: startIndex, // Row
            c: i, // Column
          },
          e: {
            // End
            r: endIndex,
            c: i,
          },
        });
      }
    };
    testgroupJson.forEach((item, index) => {
      if (item.Dificultad) {
        if (endIndex !== undefined) {
          addMergeConditions(startIndex + 1, endIndex + 1);
        }
        startIndex = index;
        endIndex = undefined;
      } else {
        endIndex = index;
      }
    });
    addMergeConditions(startIndex + 1, endIndex + 1);
    return merges;
  };

  const exportFile = useCallback(() => {
    const result = generateTestGroupTestsJsonToExport();
    const ws = utils.json_to_sheet(result);
    ws['!cols'] = [
      { width: 15 },
      { width: 20 },
      { width: 15 },
      { width: 15 },
      { width: 20 },
      { width: 30 },
      { width: 60 },
      { width: 60 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
    ];
    ws['!merges'] = generateTestGroupTestsMerges(result);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Data');
    const element = writeXLSX(wb, { type: 'base64' });
    const fileLink = document.createElement('a');
    fileLink.href = `data:application/vnd.ms-excel;base64,${element}`;
    fileLink.setAttribute('download', 'Carga_Tests.xlsx');
    fileLink.setAttribute('target', '_blank');
    document.body.appendChild(fileLink);
    fileLink.click();
  }, [newScreening?.customTestGroup]);

  return { exportFile, setTestDifficulty };
};

export default useExportCsv;

import introJs from 'intro.js';
import { t } from '../../i18n/i18n';

const onboardingTour = (setOpen) => {
  const onboarding = introJs().setOptions({
    steps: [
      {
        title: `<div class="font-montserrat text-base mt-1">${t(
          'ONBOARDING_TOUR_ONE_TITLE'
        )}</div>`,
        intro: `<div class="d-flex flex-column">
            <span class="font-montserrat text-sm">
            ${t('ONBOARDING_TOUR_ONE_SUBTITLE')}
            </span>
            <span class="font-montserrat text-sm">
            ${t('ONBOARDING_TOUR_ONE_SUBTITLE_1')}
            </span>
            </div>`,
      },
      {
        title: `<div class="font-montserrat text-base mt-1">${t(
          'ONBOARDING_TOUR_TWO_TITLE'
        )}</div>`,
        intro: `<span class="font-montserrat text-sm">${t('ONBOARDING_TOUR_TWO_SUBTITLE')}</span>`,
        element: '#DROPDOWN',
      },
      {
        title: `<div class="font-montserrat text-base mt-1">${t(
          'ONBOARDING_TOUR_THREE_TITLE'
        )}</div>`,
        intro: `<span class="font-montserrat text-sm">${t(
          'ONBOARDING_TOUR_THREE_SUBTITLE'
        )}</span>`,
        element: '#MENU_ITEM_CREATE_SCREENING',
      },
      {
        title: `<div class="font-montserrat text-base mt-1">${t(
          'ONBOARDING_TOUR_FOUR_TITLE'
        )}</div>`,
        intro: `<span class="font-montserrat text-sm">${t('ONBOARDING_TOUR_FOUR_SUBTITLE')}</span>`,
        element: '#MENU_ITEM_SEARCHES',
      },
      {
        title: `<div class="font-montserrat text-base mt-1">${t(
          'ONBOARDING_TOUR_FOUR_TITLE'
        )}</div>`,
        intro: `<span class="font-montserrat text-sm">${t('ONBOARDING_TOUR_FIVE_SUBTITLE')}</span>`,
        element: '#MENU_ITEM_SEARCHES',
      },
      {
        title: `<div class="font-montserrat text-base mt-1">${t(
          'ONBOARDING_TOUR_SIX_TITLE'
        )}</div>`,
        intro: `<span class="font-montserrat text-sm">${t('ONBOARDING_TOUR_SIX_SUBTITLE')}</span>`,
        element: '#TALENT',
      },
    ],
    nextLabel: t('ONBOARDING_TOUR_NEXT'),
    prevLabel: t('ONBOARDING_TOUR_BACK'),
    doneLabel: t('ONBOARDING_TOUR_END'),
    exitOnOverlayClick: false,
  });

  onboarding
    .onbeforechange(() => {
      setOpen(true);
    })
    .onexit(() => localStorage.setItem('isOnboarding', false))
    .start();

  return onboarding;
};

export default onboardingTour;

import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import HomeSectionContainer from '../HomeSectionContainer/HomeSectionContainer';
import CardScreeningSearch from '../CardScreeningSearch/CardScreeningSearch';
import './ActiveSearches.scss';
import Spinner from '../spinner/Spinner';
import { t } from '../../i18n/i18n';
import AddEvaluationPlus from '../../assets/home/AddEvaluationPlus';
import { CREATE_EVALUATION, SCREENING_SEARCHES } from '../../constants/routes';
import { getCustomSearchs } from '../../API/services/screeningService';

const ActiveSearches = ({ activeSearches, loading, setActiveSearches, setLoading }) => {
  const history = useHistory();
  const maxOfProfilesToDisplay = 6;

  useEffect(() => {
    const fetchSearches = async () => {
      await getCustomSearchs({ offset: 0, active: true, search: '' })
        .then((res) => {
          setActiveSearches(res.collection);
        })
        .finally(() => setLoading(false));
    };
    fetchSearches();
  }, []);

  const reloadSearches = async () => {
    await getCustomSearchs({ offset: 0, active: true, search: '' })
      .then((res) => {
        setActiveSearches(res.collection);
      })
      .finally(() => setLoading(false));
  };

  return (
    <HomeSectionContainer className="mb-3">
      <div className="d-flex justify-content-between px-3">
        <span className="text-md font-weight-600 font-montserrat">{t('HOME_ACTIVE_SEARCHES')}</span>
        <button
          type="button"
          onClick={() => history.push(SCREENING_SEARCHES)}
          className="text-sm text-muted font-weight-600 border-0 bg-transparent outline-none font-montserrat"
        >
          {t('HOME_SEARCHES_SEE_ALL_EVALUATIONS')}
        </button>
      </div>
      <div className="d-flex flex-wrap cards-container">
        {loading && <Spinner show />}
        {activeSearches?.length > 0 &&
          activeSearches
            .slice(0, maxOfProfilesToDisplay)
            .map((search) => (
              <CardScreeningSearch
                data={{ ...search, isDraft: false }}
                key={search.id}
                reloadSearches={reloadSearches}
              />
            ))}
        {activeSearches?.length < 3 && (
          <div className="col col-12 col-sm-6 col-md-4 col-lg-4 p-0 my-2 card-height">
            <div className="h-100 m-0 card-3 d-flex flex-column align-items-center justify-content-center mx-3 empty-screening-search-container">
              <button
                type="button"
                data-testid="empty-searches-add-evaluation-button"
                onClick={() => history.push(CREATE_EVALUATION)}
                className="px-3 bg-white border-0 outline-none bg-transparent d-flex flex-column align-items-center"
              >
                <AddEvaluationPlus />
                <span className="mt-2 font-montserrat text-base font-weight-600">
                  {t('HOME_EMPTY_SEARCH_CREATE_EVALUATION')}
                </span>
              </button>
            </div>
          </div>
        )}
      </div>
    </HomeSectionContainer>
  );
};

export default ActiveSearches;

import { useRef, useState, useEffect } from 'react';
import { t } from '../../i18n/i18n';
import './QuestionCode.scss';
import useCreateScreening from '../../hooks/useCreateScreening';
import UploadTestFiles from '../UploadTestFiles/UploadTestFiles';
import { AnswerTypeEnum, TestFileType, languagesToImplement } from '../../constants/enums';
import GenericToggle from '../GenericToggle/GenericToggle';
import DropdownSelect from '../DropdownSelect/DropdownSelect';
import CodeAnswer from '../CodeAnswer/CodeAnswer';
import { OverlayTrigger } from 'react-bootstrap';
import { Tooltip } from 'antd';

const QuestionCode = ({ testGroupABM }) => {
  const {
    currentQuestionData,
    defaultCheckState,
    handleAddCodeAnswers,
    handleAddQuestionText,
    handleAddTestFile,
    handleRemoveTestFile,
    handleUpdateTestFile,
    defaultCodeOutputState,
    handleAddQuestionLanguage,
  } = useCreateScreening();

  const [rowAnswer, setRowAnswer] = useState(defaultCodeOutputState);
  const [hasInitialCode, setHasInitialCode] = useState(true);

  useEffect(() => {
    setRowAnswer(currentQuestionData?.expectedResult ?? defaultCodeOutputState);
  }, [currentQuestionData?.expectedResult]);

  useEffect(() => {
    if (!currentQuestionData?.text) {
      const copyRows = [...defaultCodeOutputState];
      setRowAnswer(currentQuestionData?.expectedResult ?? copyRows);
    }
  }, [currentQuestionData]);

  const handleOnChange = (index, key, value) => {
    const copyRows = [...rowAnswer];
    copyRows[index] = {
      ...copyRows[index],
      [key]: value,
    };
    setRowAnswer(copyRows);
    handleAddCodeAnswers(copyRows);
  };

  const handleAddAswer = () => {
    const emptyNewOption = {
      text: '',
      ...(!testGroupABM ? { correct: false } : {}),
    };
    setRowAnswer(rowAnswer.concat(emptyNewOption));
    handleAddCodeAnswers(rowAnswer.concat(emptyNewOption));
  };

  const handleOnRemove = (index) => {
    const copyRows = [...rowAnswer];
    copyRows.splice(index, 1);
    handleAddCodeAnswers(copyRows);
  };

  const resetInitalCode = () => {
    handleAddQuestionText({ target: { name: 'existingCode', value: '' } });
  };

  return (
    <div data-testid="question-code-container">
      <textarea
        type="text"
        onChange={handleAddQuestionText}
        name="text"
        value={currentQuestionData?.text ?? ''}
        className={`w-100 mt-3 py-2 px-3 font-montserrat maya-blue-thin-border outline-none text-xs min-height-add-question ${
          currentQuestionData?.text?.length ? 'selected-border' : 'unselected-border'
        }`}
        rows="3"
        maxLength={5000}
        placeholder={t('CREATE_SCREENING_YOUR_QUESTIONS_CODE_INPUT_PLACEHOLDER')}
        data-testid="question-code-add-question-textarea"
      />
      <div
        className="w-full d-flex justify-content-between"
        data-testid="code-options-language-prexisting-code"
      >
        <GenericToggle
          className="my-3"
          rightText={t('TOGGLE_HAS_INITAL_CODE')}
          defaultActive={hasInitialCode}
          actionFunction={() => {
            resetInitalCode();
            setHasInitialCode(!hasInitialCode);
          }}
        />
        <span className="align-items-center">
          <DropdownSelect
            selectedPlaceholder
            alignEnd="justify-content-end"
            className="mr-0 mr-2"
            placeholder={t('MULTIPLE_SELECT_CODE_LANGUAGE_PLACEHOLDER')}
            setState={(e) => handleAddQuestionLanguage(e)}
            selected={currentQuestionData?.langCode}
            options={languagesToImplement?.map((item) => ({
              id: item.id,
              name: item.displayName,
              version: item?.version ?? 0,
            }))}
          />
          <p
            className={`font-montserrat text-sm fit-content mb-0 font-weight-500 ${
              currentQuestionData?.langCode
                ? 'px-2 b-radius-8 bg-yellow-warning py-1 w-100 my-1 gray-subtitle'
                : ''
            }`}
          >
            {currentQuestionData?.langCode ? 'Se utilizará la ultima version del lenguaje' : ''}
          </p>
        </span>
      </div>
      {hasInitialCode && (
        <textarea
          type="text"
          onChange={handleAddQuestionText}
          name="existingCode"
          value={currentQuestionData?.existingCode ?? ''}
          className={`w-100 mb-3 py-2 px-3 font-montserrat maya-blue-thin-border outline-none text-xs min-height-add-question ${
            currentQuestionData?.existingCode?.length ? 'selected-border' : 'unselected-border'
          }`}
          rows="5"
          maxLength={5000}
          placeholder={t('CREATE_SCREENING_YOUR_QUESTIONS_INITIAL_CODE_INPUT_PLACEHOLDER')}
          data-testid="question-code-add-inital-code-textarea"
        />
      )}
      {testGroupABM && (
        <UploadTestFiles
          fileTypes={[TestFileType.IMAGE, TestFileType.AUDIO]}
          handleAddFile={handleAddTestFile}
          handleRemoveFile={handleRemoveTestFile}
          handleUpdateFile={handleUpdateTestFile}
          testFiles={currentQuestionData?.testFiles ?? []}
          id="test"
        />
      )}
      <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
        <span className="font-montserrat font-bold text-muted font-weight-bold text-sm">
          {t('CREATE_QUESTION_CODE_TEST_CASES_TITLE')}
        </span>
      </div>
      {rowAnswer.map((row, index) => (
        <CodeAnswer
          {...row}
          onChange={(name, value) => handleOnChange(index, name, value)}
          onChangeOutput={(name, value) => handleOnChange(index, name, value)}
          index={index}
          onRemove={() => handleOnRemove(index)}
        />
      ))}
      <button
        type="button"
        className="font-montserrat bg-transparent border-0 text-xs text-maya-blue font-weight-600 mt-3"
        onClick={handleAddAswer}
        data-testid="question-code-create-option-btn"
      >
        {t('CREATE_QUESTION_MULTIPLE_CHOICE_ADD_OPTION')}
      </button>
    </div>
  );
};

export default QuestionCode;

import React from 'react';
import useCreateScreening from '../../hooks/useCreateScreening';
import { t } from '../../i18n/i18n';
import NewStyleSelect from '../NewStyleSelect/NewStyleSelect';

import { maxRecordTimes, maxPreparationTimes, maxAttemptsOptions } from '../../constants/questions';

const QuestionWithVideoAnswer = () => {
  const { currentQuestionData, handleAddQuestionText } = useCreateScreening();

  return (
    <div
      data-testid="question-video-answer-container"
      className="d-flex flex-column align-items-start"
    >
      <QuestionItem dataTestId="question-video-answer-name" className="w-100">
        <textarea
          name="text"
          className={`maya-blue-thin-border outline-none w-100 mt-3 p-2 px-3 text-xs font-montserrat ${
            currentQuestionData?.text?.length ? 'selected-border' : 'unselected-border'
          }`}
          rows="3"
          placeholder={t('CREATE_SCREENING_YOUR_QUESTIONS_VIDEO_INPUT_PLACEHOLDER')}
          onChange={(e) => handleAddQuestionText(e)}
          value={currentQuestionData?.text ?? ''}
          maxLength={5000}
          data-testid="question-text-textarea"
        />
      </QuestionItem>
      <div
        data-testId="question-video-answer-total-time"
        class="container justify-content-start mx-2 my-2"
      >
        <div class="row w-100">
          <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
            <span className="font-montserrat font-italic text-muted font-weight-bold text-sm">
              {t('CREATE_SCREENING_YOUR_QUESTIONS_VIDEO_TOTAL_RECORD_TIME')}
            </span>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
            <NewStyleSelect
              error={false}
              className="w-100"
              value={currentQuestionData?.totalTime ?? ''}
              options={maxRecordTimes}
              onChange={(e) => {
                handleAddQuestionText(e);
              }}
              name="totalTime"
            />
          </div>
        </div>
      </div>
      <div
        data-testId="question-video-answer-preparation-time"
        class="container justify-content-start mx-2 my-2"
      >
        <div class="row w-100">
          <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
            <span className="font-montserrat font-italic text-muted font-weight-bold text-sm">
              {t('CREATE_SCREENING_YOUR_QUESTIONS_VIDEO_PREPARATION_TIME')}
            </span>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
            <NewStyleSelect
              error={false}
              className="w-100"
              value={currentQuestionData?.preparationTime ?? ''}
              options={maxPreparationTimes}
              onChange={(e) => {
                handleAddQuestionText(e);
              }}
              name="preparationTime"
            />
          </div>
        </div>
      </div>
      <div
        data-testId="question-video-answer-max-attempts"
        class="container justify-content-start mx-2 my-2"
      >
        <div class="row w-100">
          <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
            <span className="font-montserrat font-italic text-muted font-weight-bold text-sm">
              {t('CREATE_SCREENING_YOUR_QUESTIONS_VIDEO_MAX_ATTEMPTS')}
            </span>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
            <NewStyleSelect
              error={false}
              className="w-100"
              value={currentQuestionData?.maxAttempts ?? ''}
              options={maxAttemptsOptions}
              onChange={(e) => {
                handleAddQuestionText(e);
              }}
              name="maxAttempts"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const QuestionItem = ({ children, dataTestId, className = '' }) => {
  return (
    <div data-testid={dataTestId} className={className + ' row justify-content-between mx-2 my-2'}>
      {children}
    </div>
  );
};

export default QuestionWithVideoAnswer;

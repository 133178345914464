const EmailIcon = ({ color = '#58c1f5', className }) => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_5727_32638)">
      <path
        d="M22.4517 4.16649C22.3571 4.15674 22.2616 4.15674 22.167 4.16649H2.72255C2.59793 4.16842 2.47413 4.1871 2.35449 4.22205L12.3892 14.2151L22.4517 4.16649Z"
        fill={color}
      />
      <path
        d="M23.4787 5.13184L13.3676 15.2013C13.1074 15.46 12.7554 15.6052 12.3885 15.6052C12.0215 15.6052 11.6695 15.46 11.4093 15.2013L1.38845 5.20822C1.35765 5.32145 1.34132 5.43812 1.33984 5.55545V19.4443C1.33984 19.8127 1.48617 20.166 1.74664 20.4264C2.00711 20.6869 2.36038 20.8332 2.72873 20.8332H22.1732C22.5415 20.8332 22.8948 20.6869 23.1553 20.4264C23.4157 20.166 23.5621 19.8127 23.5621 19.4443V5.55545C23.5565 5.41076 23.5284 5.26783 23.4787 5.13184ZM3.68012 19.4443H2.71484V18.4513L7.76345 13.4443L8.74262 14.4235L3.68012 19.4443ZM22.1593 19.4443H21.1871L16.1246 14.4235L17.1037 13.4443L22.1523 18.4513L22.1593 19.4443Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_5727_32638">
        <rect width="25" height="25" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default EmailIcon;

import { useEffect } from 'react';
import Spinner from '../spinner/Spinner';
import CheckIcon from '../../assets/login/CheckIcon';
import useModalDetails from '../../hooks/useModalDetails';
import Modal from '../Modal/Modal';
import { t } from '../../i18n/i18n';

const ResetTestgroupStep4 = ({
  loading,
  handlePreviousStep,
  handleNextStep,
  selectedTestgroups,
  clearSelectedTestgroups,
  handleSelectTestgroup,
  selectedInvitations,
  fetchSearchScreening,
  testgroups,
}) => {
  const { showModal, closeModal, handleShowModal } = useModalDetails();

  useEffect(() => {
    clearSelectedTestgroups();
    fetchSearchScreening();
  }, []);

  return (
    <>
      <Modal
        show={showModal}
        relativeCloseButton
        className="rounded px-4 pt-2 pb-3 w-50"
        closeBtnClassname="text-maya-blue font-weight-bold"
        setShow={(e) => closeModal(e)}
      >
        <>
          <span className="font-montserrat text-danger font-weight-bold text-lg">
            {t('RESET_TESTGROUP_STEP_4_MODAL_WARNING')}
          </span>
          <div className="reset-testgroup-modal-data-max-height overflow-auto">
            <span className="font-montserrat font-weight-bold text-base">
              {t('RESET_TESTGROUP_STEP_4_MODAL_FIRST_ITEM')}
            </span>
            <ul className="pl-3">
              {testgroups
                .filter((item) => selectedTestgroups.includes(item.id))
                .map((item) => (
                  <li key={item?.id} className="font-montserrat font-weight-600 text-muted text-sm">
                    {item.name}
                  </li>
                ))}
            </ul>
            <span className="font-montserrat font-weight-bold text-base">
              {t('RESET_TESTGROUP_STEP_4_MODAL_SECOND_ITEM')}
            </span>
            <ul className="pl-3">
              {selectedInvitations.map((item) => (
                <li key={item.email} className="font-montserrat font-weight-600 text-muted text-sm">
                  {item.email}
                </li>
              ))}
            </ul>
            <button
              onClick={async () => {
                await handleNextStep();
                closeModal();
              }}
              className="align-self-end mt-4 px-3 py-1 rounded-pill font-roboto text-white text-sm font-weight-bold border-0 outline-none bg-maya-blue"
              type="button"
            >
              {t('RESET_TESTGROUP_STEP_4_MODAL_ACCEPT_BUTTON')}
            </button>
          </div>
        </>
      </Modal>
      <div className="d-flex flex-column">
        <span className="font-montserrat font-weight-bold text-base mt-2 mb-3">
          {t('RESET_TESTGROUP_STEP_4_TITLE')}
        </span>
        <span className="font-montserrat font-weight-bold text-sm text-muted mt-2 mb-3">
          {t('RESET_TESTGROUP_STEP_4_SUBTITLE')}
        </span>
        <div className="w-100 d-flex flex-column mt-2 reset-testgroup-lists-container overflow-auto">
          {loading ? (
            <Spinner show />
          ) : (
            testgroups?.map((testgroup) => (
              <button
                key={testgroup?.id}
                type="button"
                onClick={() => handleSelectTestgroup(testgroup.id)}
                className={`bg-transparent tech-button-border my-1 px-2 py-3 d-flex justify-content-between align-items-center ${
                  selectedTestgroups.includes(testgroup.id)
                    ? 'selected-border'
                    : 'unselected-border'
                }`}
              >
                <span className="font-montserrat font-weight-bold text-muted text-xs">
                  {testgroup.name}
                </span>
                {selectedTestgroups.includes(testgroup.id) && (
                  <CheckIcon className="mr-1" width="19" height="19" />
                )}
              </button>
            ))
          )}
        </div>
      </div>
      <div className="d-flex flex-grow-1 justify-content-between">
        <button
          onClick={handlePreviousStep}
          className="align-self-end mt-4 px-3 py-1 rounded-pill font-roboto text-white text-sm font-weight-bold border-0 outline-none bg-maya-blue"
          type="button"
        >
          {t('RESET_TESTGROUP_BACK_BUTTON')}
        </button>
        <button
          onClick={handleShowModal}
          disabled={!selectedTestgroups.length}
          className={`align-self-end mt-4 px-3 py-1 rounded-pill font-roboto text-white text-sm font-weight-bold border-0 outline-none ${
            !selectedTestgroups.length ? 'bg-disabled' : 'bg-maya-blue'
          }`}
          type="button"
        >
          {t('RESET_TESTGROUP_NEXT_BUTTON')}
        </button>
      </div>
    </>
  );
};

export default ResetTestgroupStep4;

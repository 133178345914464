import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { verifyCompleteData } from '../../utils/verifyCompleteData';
import BackgroundTop from '../../components/BackgroundTop/BackgroundTop';
import { formatMinutesToHours } from '../../utils/formatMinutesToHours';
import useCreateSearch from '../../hooks/useCreateSearch';
import HorizontalLoader from '../../components/HorizontalLoader/HorizontalLoader';
import { t } from '../../i18n/i18n';
import { getScreenings } from '../../API/services/screeningService';
import { setEmailsData, setScreenings } from '../../redux/actions/screeningActions';
import Timer from '../../assets/screening/timer.svg';
import useModalDetails from '../../hooks/useModalDetails';
import Modal from '../../components/Modal/Modal';

const NewSearchResume = () => {
  const { newSearch } = useSelector((state) => state.screenings);
  const { user } = useSelector((state) => state?.auth);
  const { screeningModalContent, closeModal, handleScreeningModalData, loadingModal, showModal } =
    useModalDetails();
  const dispatch = useDispatch();
  const {
    formatDate,
    currentScreening,
    stageData,
    formattedScreenings,
    postData,
    step,
    loading,
    handleBackStep,
  } = useCreateSearch();
  const history = useHistory();

  useEffect(() => {
    if (!verifyCompleteData(newSearch.stagesQuantity, newSearch)) {
      history.push('/createsearch');
    }
    if (!formattedScreenings?.length) {
      getScreenings(user?.workspaces[0]?.workspaceId).then((screenings) => {
        dispatch(setScreenings(screenings));
      });
    }
  }, []);

  return (
    <div className="w-100 mx-auto h-100">
      <div>
        <BackgroundTop
          title={`${t('CREATE_SEARCH_CAPSULE_TITLE')}${newSearch?.name && `: ${newSearch?.name}`}`}
          className="position-relative"
          titleClassname="text-xl"
        />
      </div>
      <Modal
        show={showModal}
        relativeCloseButton
        className="rounded px-4 py-2 justify-content-center w-80vw"
        showCloseButton={!loadingModal}
        closeBtnClassname="text-maya-blue font-weight-bold"
        setShow={(e) => closeModal(e)}
      >
        {screeningModalContent()}
      </Modal>
      <div className="px-2">
        <div className="position-relative description-container new-search-container d-flex flex-column p-4 bg-white rounded mx-auto mb-5">
          <div className="d-flex justify-content-between align-items-start">
            {step <= 3 && (
              <span className="text-sm font-montserrat text-muted">
                {t('CREATE_SEARCH_PLACED_STEP').replace('{step}', step)}
              </span>
            )}
          </div>
          <h3 className="font-montserrat font-weight-600 text-base my-3">
            {t('CREATE_SEARCH_RESUME_TITLE')}
          </h3>
          <h3 className="font-montserrat font-weight-600 text-base mt-4 text-gray-2">
            {t('CREATE_SEARCH_RESUME_SUBTITLE')}
          </h3>
          <div
            data-testid="box-info-resume-screening"
            className="rounded my-4 p-2 selected-screening-capsule position-relative"
          >
            <span className="font-montserrat font-weight-600 line-height-20 letter-spacing-05 mr-2 text-sm position-name">
              {currentScreening?.screeningsName}
            </span>
            <div className="d-flex position-timer">
              <img src={Timer} alt={t('CREATE_SEARCH_ICON_TIMER')} />
              <p className="text-xs text-gray-2 ml-2 mt-3 font-weight-600 font-montserrat">
                {formatMinutesToHours(
                  formattedScreenings?.find((item) => item.id === stageData?.levelId)?.duration
                ).replace(' hs', ':00')}
              </p>
            </div>
            <button
              onClick={() => {
                handleScreeningModalData(currentScreening.screeningId);
              }}
              className="align-self-center ml-3 pt-3 mt-4 border-0 bg-white text-decoration-none ml-1 font-montserrat font-weight-600 line-height-17 outline-none letter-spacing-05 mt-1 gray-subtitle text-xs text-maya-blue"
              type="button"
            >
              {t('VIEW_SCREENING_DETAILS')}
            </button>
          </div>
          <div className="my-2">
            <span className="font-montserrat font-weight-600 text-base mt-4 text-gray-2">
              {t('CREATE_SEARCH_DATE_TIME')}
            </span>
            <div className="mt-3 d-flex flex-column align-items-start flex-md-row">
              <p className="italic font-montserrat font-weight-600 text-sm purple-color mx-3 mt-2">
                {t('CREATE_SEARCH_FROM')}
              </p>
              <span className="custom-date-button font-montserrat font-weight-bold text-xs text-center pt-2">
                {formatDate(stageData?.startDate?.day, stageData?.startDate?.hours).format(
                  'DD/MM/YYYY H:mm a'
                )}
              </span>
              <p className="italic font-montserrat font-weight-600 text-sm purple-color mx-3 mt-2">
                {t('CREATE_SEARCH_TO')}
              </p>
              <span className="custom-date-button font-montserrat font-weight-bold text-xs text-center pt-2">
                {formatDate(stageData?.endDate?.day, stageData?.endDate?.hours).format(
                  'DD/MM/YYYY H:mm a'
                )}
              </span>
            </div>
          </div>
          {loading && (
            <div className="w-100 d-flex justify-content-end">
              <HorizontalLoader />
            </div>
          )}
          <div className="w-100 h-100 d-flex mt-auto justify-content-between">
            <button
              type="button"
              disabled={loading}
              onClick={handleBackStep}
              className="mt-4 align-self-end px-3 py-2 rounded-pill font-montserrat text-white text-xs font-weight-bold border-0 outline-none bg-maya-blue"
            >
              {t('CREATE_SEARCH_STAGE_BACK')}
            </button>
            <button
              type="button"
              disabled={loading}
              className="mt-4 align-self-end px-3 py-2 rounded-pill font-montserrat text-white text-xs font-weight-bold border-0 outline-none bg-maya-blue"
              onClick={() => {
                postData();
                dispatch(setEmailsData({}));
              }}
            >
              {t('CREATE_SCREENING_CONTINUE')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewSearchResume;

import { useState, useEffect } from 'react';
import { notification } from 'antd';
import { t } from '../../i18n/i18n';
import TestGroupsItem from '../TestGroupsItem/TestGroupsItem';
import useCreateScreening from '../../hooks/useCreateScreening';
import AddQuestions from '../AddQuestions/AddQuestions';
import DropdownSelect from '../DropdownSelect/DropdownSelect';
import { generateHours } from '../../constants/hours';

const CreateScreeningStep3 = () => {
  const {
    handleOrder,
    handleNextStep,
    handleDelete,
    handlePreviousStep,
    handleCustomScreeningsTime,
    setEditData,
    newScreening,
    currentQuestionData,
    customScreeningTotalTime,
    allTests,
  } = useCreateScreening();
  const incompleteQuestionValidation =
    (currentQuestionData && currentQuestionData?.text?.length) ||
    currentQuestionData?.maxChar ||
    currentQuestionData?.fileTypes?.length ||
    currentQuestionData?.options?.some(
      (option) => Boolean(option?.text?.length) || option?.correct
    );
  const [incompleteQuestion, setIncompleteQuestion] = useState(false);
  const hours = generateHours();
  useEffect(() => {
    if (incompleteQuestion && !incompleteQuestionValidation) {
      setIncompleteQuestion(false);
    }
  }, [currentQuestionData]);

  const goToNextStep = () => {
    if (incompleteQuestionValidation) {
      notification.open({
        message: t('CREATE_CUSTOM_TEST_INCOMPLETE_TEST_FORM_ERROR'),
        type: 'error',
      });
      setIncompleteQuestion(true);
      return;
    }
    handleNextStep();
  };

  return (
    <div className="d-flex flex-column">
      <span className="font-montserrat font-weight-bold text-base mt-2">
        {t('CREATE_SCREENING_YOUR_QUESTIONS_TITLE')}
      </span>
      <span className="font-montserrat text-muted text-sm mt-3 font-italic">
        {t('CREATE_SCREENING_YOUR_QUESTIONS_SUBTITLE')}
      </span>
      <div className="d-flex justify-content-center my-2">
        <AddQuestions incompleteQuestion={incompleteQuestion} />
      </div>
      <div className="my-2 d-flex align-items-center">
        <div className="my-1 my-sm-0 mr-0 mr-sm-2 pr-md-5 mr-md-5">
          <span className="font-montserrat font-weight-bold text-muted font-italic text-sm">
            {t('CREATE_SCREENING_YOUR_QUESTIONS_ADDED_QUESTIONS')}
          </span>
          <span className="total-number-text font-italic font-weight-bold font-montserrat text-sm">
            {newScreening?.customTestGroup?.length}
          </span>
        </div>
        <div className="my-1 my-sm-0 ml-0 ml-sm-2 ml-md-5 d-flex justify-content-start align-items-center">
          <span className="font-montserrat font-italic text-muted font-weight-bold text-sm">
            {t('CREATE_SCREENING_YOUR_QUESTIONS_TOTAL_TIME')}
          </span>
          <DropdownSelect
            options={hours}
            selected={newScreening?.customScreeningTGDuration}
            selectedPlaceholder
            setState={handleCustomScreeningsTime}
            className="time-picker-button time-picker-button-small ml-3"
            placeholderClassname="ml-4"
          />
        </div>
      </div>
      <div className="mt-2">
        {newScreening.customTestGroup
          .sort((a, b) => a.order - b.order)
          .map((item, index) => (
            <TestGroupsItem
              test={item}
              disableOrderUp={index === 0}
              handleEdit={() => setEditData(item)}
              disableOrderDown={index === newScreening.customTestGroup.length - 1}
              handleOrder={handleOrder}
              handleDelete={() => handleDelete(item.deleteId)}
              isComplementaryQuestion
            />
          ))}
      </div>
      <div className="d-flex justify-content-between">
        <button
          onClick={handlePreviousStep}
          className="mt-4 align-self-end px-3 py-1 rounded-pill font-roboto text-white text-sm font-weight-bold border-0 outline-none bg-maya-blue"
          type="button"
        >
          {t('CREATE_SCREENING_BACK')}
        </button>
        <button
          onClick={goToNextStep}
          disabled={
            !allTests.length ||
            (newScreening.customTestGroup.length && !customScreeningTotalTime) ||
            (!newScreening.testGroups.length &&
              newScreening.customTestGroup.length &&
              !customScreeningTotalTime)
          }
          className={`mt-4 align-self-end px-3 py-1 rounded-pill font-roboto text-white text-sm font-weight-bold border-0 outline-none ${
            !allTests.length ||
            (newScreening.customTestGroup.length && !customScreeningTotalTime) ||
            (!newScreening.testGroups.length &&
              newScreening.customTestGroup.length &&
              !customScreeningTotalTime)
              ? 'disabled-button'
              : 'bg-maya-blue'
          }`}
          type="button"
        >
          {t('CREATE_SCREENING_CONTINUE')}
        </button>
      </div>
    </div>
  );
};

export default CreateScreeningStep3;
